import nl from '../languages/nl'
import en from '../languages/en'
import de from '../languages/de'

export type nlCopy = typeof nl
export type enCopy = typeof en
export type deCopy = typeof de
export type allLanguagesCopy = nlCopy | enCopy | deCopy

export interface AllCopies {
	nl: nlCopy
	en: enCopy
	de: deCopy
}

export enum Languages {
	NL = 'nl',
	EN = 'en',
	DE = 'de',
}
export type LanguagesKey = { [key in Languages]: key }[Languages]
