import { GetterTree } from 'vuex'
import { IState, Namespaced } from '@/store'
import { IDashboardProposal } from '@/interfaces'
import { IProposalsStoreState } from '.'

export type ProposalsGettersFunctions<S = IProposalsStoreState> = {
	visitsAmount: (state: S) => (proposalId: string) => number | undefined
	proposals: (state: S) => IDashboardProposal[]
	clientProposals: (state: S) => (clientId: number) => IDashboardProposal[]
	proposalsReadyForReview: (state: S) => IDashboardProposal[]
}

export type ProposalsGetters = { [K in keyof ProposalsGettersFunctions]: ReturnType<ProposalsGettersFunctions[K]> }

export type NamespacedProposalsGetter = Namespaced<ProposalsGetters, 'proposals'>

const getters: GetterTree<IProposalsStoreState, IState> & ProposalsGettersFunctions = {
	visitsAmount: (state) => (proposalId) => state.visits.find((visit) => visit.proposalId === proposalId)?.amount,
	proposals: (state) => state.proposals,
	clientProposals: (state) => (clientId) =>
		state.proposals.filter((proposal) => !!proposal.client && proposal.client.id === clientId),
	proposalsReadyForReview: (state) => state.proposalsReadyForReview,
}

export default getters
