/*
!!!!!
Be aware that some interfaces are in the interfaces folder!
!!!!!
 */

import { Languages } from '@/interfaces/languageTypes'
import { NullAble } from '@/interfaces/advancedTypes'

export interface IAccountForm {
	id: number
	name: string
	address: string
	postalCode: string
	city: string
	country: string
	language: Languages
	vat: string | null
	phoneNumber: string | null
	invoiceMail: string
	invoiceName: string
	coupon?: string
}

export interface IAccount extends IAccountForm {
	country: string
	users: IBaseUser[]
	workspaces: IBaseWorkspace[]
	subscriptionName: Subscription
	subscriptionStatus: SubscriptionStatus
	subscriptionEnd: string
	hasMandate: boolean
	couponApplied?: boolean
}

export enum Subscription {
	SOLO = 'solo',
	AGENCY = 'agency',
	ENTERPRISE = 'enterprise',
}

export enum SubscriptionStatus {
	TRIAL = 'in_trial',
	TRIAL_PENDING_ACTIVATION = 'trial_pending_activation',
	PAUSED_AFTER_TRIAL = 'paused_after_trial',
	CANCELLED = 'cancelled',
	SUSPENDED = 'suspended',
	ACTIVE = 'active',
	ENDED = 'ended',
	BLOCKED = 'blocked',
}

export enum EPaymentStatus {
	PAID = 'paid',
	OPEN = 'open',
	CANCELLED = 'canceled',
	EXPIRED = 'expired',
	AUTHORIZED = 'authorized',
	PENDING = 'pending',
	FAILED = 'failed',
}

export enum ConfettiType {
	DEFAULT = 'default',
	FIREWORK = 'firework',
	SNOW = 'snow',
	CANNONS = 'cannons',
}

export enum EBlockDirection {
	UP = 'up',
	DOWN = 'down',
}

export interface IPayment {
	id: number
	status: EPaymentStatus
	account: IAccount
}

export interface IUserAccount {
	id: number
	name: string
	owner: boolean
	users: IWorkspaceUser[]
	subscriptionStatus: SubscriptionStatus
	subscriptionName: Subscription
	subscriptionEnd: string | null
	workspaces: IAccountWorkspace[]
	avatar: string | null
	language: Languages
}

export interface IAccountWorkspace {
	id: number
	name: string
	image: string
	users: IWorkspaceUser[]
}

// WORKSPACE
export interface IBaseWorkspace {
	id: number
	name: string
	image: string
	backgroundColor: string
	language: Languages
}

export interface IWorkspace extends IBaseWorkspace {
	users: IWorkspaceUser[]
	account: IWorkspaceAccount
	darkImage: string
	backgroundOpacity: [number, number]
	senderName: string | null
	senderAddress: string | null
	showVat: boolean
	useComments: boolean
	multiLanguage: boolean
	onlyManagerOrOwnerCanSend: boolean
	authorCanSendOwnProposals: boolean
	useConfetti: boolean
	usePurchaseCost: boolean
	dark: boolean
	vatCountry: EVatCountry
	defaultVat: number
	currencies: ECurrencyCode[]
	standardCurrency: ECurrencyCode
	senderSignatureRequired?: boolean
	clientSignatureRequired?: boolean
	fonts: IFont[]
	tags: ITag[]
}

export interface ITag {
	id: number | string | null
	label: {
		nl: string
		en: string
		de: string
	}
}

export enum EContractTypes {
	CONTRACT = 'contract',
	PRICELIST = 'pricelist',
	PROPOSAL = 'proposal',
}

export interface IFont {
	type: EFontType
	name: string
	subset: string
	weight: number
	style: string
	size: string
	align: ETextAlign
	url?: string | null
	workspaceId?: number
}

export interface ICustomFont {
	url: string
	name: string
	id: number
	style: string
}

export enum EFontWeightLabel {
	'Thin' = 100,
	'Extra Light' = 200,
	'Light' = 300,
	'Normal' = 400,
	'Medium' = 500,
	'Semi Bold' = 600,
	'Bold' = 700,
	'Extra Bold' = 800,
	'Black' = 900,
	'Extra Black' = 1000,
}

export enum EBasicFontType {
	'Standard Text' = 'standardText',
	'Small Text' = 'smallText',
	'Navigation' = 'navigation',
	'Large Heading' = 'largeHeading',
	'Medium Heading' = 'mediumHeading',
	'Small Heading' = 'smallHeading',
}

export enum EProposalFontType {
	'Title' = 'title',
	'Page Title' = 'pageTitle',
	'Quote Block' = 'quoteBlock',
	'Image with Extra Large Text' = 'extraLargeImageText',
	'Image with Large Text' = 'largeImageText',
	'Image with Caption' = 'captionImageText',
}

export enum EFontType {
	'Standard Text' = 'standardText',
	'Large Heading' = 'largeHeading',
	'Medium Heading' = 'mediumHeading',
	'Small Text' = 'smallText',
	'Small Heading' = 'smallHeading',
	'Navigation' = 'navigation',

	'Title' = 'title',
	'Page Title' = 'pageTitle',
	'Quote Block' = 'quoteBlock',
	'Image with Extra Large Text' = 'extraLargeImageText',
	'Image with Large Text' = 'largeImageText',
	'Image with Caption' = 'captionImageText',
}

export const FontHeadingLevels = {
	'0': EFontType['Standard Text'],
	'1': EFontType['Large Heading'],
	'2': EFontType['Medium Heading'],
	'3': EFontType['Small Heading'],
	'6': EFontType['Small Text'],
}

export const FontHeadingTypes = {
	p: 'standardText',
	h1: 'largeHeading',
	h2: 'mediumHeading',
	h3: 'smallHeading',
	h6: 'smallText',
}

export enum ETextAlign {
	'Left' = 'left',
	'Center' = 'center',
	'Right' = 'right',
}

export enum EVatCountry {
	ALL = 'all',
	NL = 'nl',
	BE = 'be',
	DE = 'de',
}

export enum ECurrencyCode {
	USD = 'USD',
	AUD = 'AUD',
	GBP = 'GBP',
	CAD = 'CAD',
	CNY = 'CNY',
	EUR = 'EUR',
	INR = 'INR',
	JPY = 'JPY',
	AED = 'AED',
	CHF = 'CHF',
	BTC = 'BTC',
	ETH = 'ETH',
}

export enum UnsupportedCurrencySymbols {
	BTC = '₿',
	ETH = 'Ξ',
	CHF = '₣',
}

export interface IUserWorkspace extends Omit<IWorkspace, 'users'> {
	id: number
	name: string
	role: 'manager' | 'agent' | 'viewer'
	twoFactorRequired: boolean
}

export interface IWorkspaceUser {
	id: number
	firstName: string
	lastName: string
	email: string
	role: 'manager' | 'agent' | 'viewer'
	language: string
	deletedAt: Date | '0'
	avatar: string | null
	twoFactorEnabled: boolean
}

export interface IWorkspaceAccount {
	id: number
	name: string
	users: number[]
	subscriptionEnd: string | null
	subscriptionStatus: SubscriptionStatus
	subscriptionName: Subscription
}

export interface IInvitedUser {
	token: string
	role: 'agent' | 'manager' | 'viewer'
	email: string
	createdAt: Date
	name: string
}

export interface IJwtToken {
	email: string
	firstName: string
	lastName: string
	sub: number
	exp: number
	iat: number
}

export interface IConditions {
	id: number
	fileName: string
	name: string
	url: string
	createdAt: Date
	updatedAt: Date
	language: Languages
}

export interface IClientConditions {
	clientSignedAt: null | string
	conditions: IConditions
	status: EProposalStatus
}

export enum EBlockType {
	TEXT = 'Text',
	QUOTE = 'Quote',
	VIDEO = 'Video',
	SLIDESHOW = 'Slideshow',
	IMAGE = 'Image',
	DOCUMENT = 'Document',
	COMPONENT = 'ComponentBlock',
	PRODUCT = 'ProductBlock',
	NOTE = 'Note',
	REACTION = 'Reaction',
	EMBED = 'Embed',
}

export enum EEmbedType {
	FIGMA = 'Figma',
	FLOURISH = 'Flourish',
	MIRO = 'Miro',
}

export interface IBaseComponent {
	id: number
	name: string
	contentPreview: EBlockType[]
	updatedAt: Date
}

export interface IComponent extends IBaseComponent {
	createdAt: Date
	category: ICategory | IUnsetCategory
	language: Languages
	product: null | IProduct
	blocks: Array<AllComponentBlockTypes>
	workspaceId: number
	availableLanguages?: Languages[]
}

export interface IProductComponent extends IComponent {
	product: IProduct
}

export interface ICategoryBase {
	id: number
	type: 'normal' | 'shared'
	name: string
	components: number
	products: number
	categoryOrder?: number
}
export interface ICategoryNormal extends ICategoryBase {
	type: 'normal'
	sharedWithWorkspaces: {
		id: number
		name: string
	}[]
	shareWithAccount: boolean
}
export interface ICategoryShared extends ICategoryBase {
	type: 'shared'
	sourceId: number
	sourceWorkspaceId: number
	sourceWorkspaceName: string
}

export type ICategory = ICategoryNormal | ICategoryShared

export interface IUnsetCategory {
	id: 0
}

export interface IMedia {
	url: string
	id: number
	title: string
	tags: string | null
	fileName: string
	fileMimetype: string
	fileSize: number
	createdAt: Date
	updatedAt: Date
	deletedAt: Date | null
	height?: number
	width?: number
	mediaId?: number
}

export interface IImportedMedia extends IMedia {
	oldId: number
}

export interface IProduct {
	id: number
	productCode: string | null
	amount: number
	unit: IUnit
	flexibility: EProductFlexibility
	recurring: EProductRecurring
	optional: boolean
	active: boolean
	showPrice: boolean
	currencies: IProductCurrency[]
}

export enum EProductUnit {
	None = 'none',
	Piece = 'piece',
	Percentage = 'percentage',
	Hour = 'hour',
	Day = 'day',
	Month = 'month',
	Quarter = 'quarter',
	Year = 'year',
}

export interface IUnit {
	id?: number
	global?: boolean
	workspaceId?: number
	unitLanguages?: IUnitLanguage[]
	softDeleted?: boolean
}

export enum EGlobalProductUnitIds {
	UNITLESS = 1,
	UNIT = 2,
	PERCENTAGE = 3,
	HOUR = 4,
	DAY = 5,
	MONTH = 6,
	QUARTER = 7,
	YEAR = 8,
}
export interface IActiveCondition {
	language: Languages
	active: boolean
	id: number
	workspaceId?: number
}

export interface IUnitLanguage {
	language: string
	singular: string
	plural: string
}

export interface EUnitForm {
	id?: number
	unitLanguages?: IUnitLanguage[]
}

export enum EProductDiscountUnit {
	Percentage = 'percentage',
	Euro = 'euro',
	Currency = 'currency',
}

export enum EProductFlexibility {
	Fixed = 'fixed',
	Provisional = 'provisional',
	Estimate = 'estimate',
}

export enum EProductRecurring {
	Once = 'once',
	Daily = 'daily',
	Weekly = 'weekly',
	Monthly = 'monthly',
	Yearly = 'yearly',
}

export interface IProductVat {
	id: number
	name: string
	percentage: number
	country: EVatCountry
	including: boolean
}

// DASHBOARD PROPOSALS
export interface IDashboardProposal {
	id: string
	title: string | null
	expiration: string | null
	createdAt: string
	clientSignedAt: string | null
	sendAt: string | null
	status: EProposalStatus
	client?: IProposalClient | null
	user?: IBaseUser
	deletedAt: string | null
	reviewers: IWorkspaceUser[] | null
	latestChange: string
	statusChanged: boolean
	lastSendAt: string | null
	language: Languages
	contractType: EContractTypes
}

// PROPOSAL - BLOCKS
export interface IBlock {
	id: number
	updatedAt: string
	createdAt: string
	pageId?: number | null
	pageOrder?: number | null
	componentId?: number
	componentOrder?: number
	type: EBlockType
}

export type AllBlockTypes =
	| ITextBlock
	| IQuoteBlock
	| IImageBlock
	| ISlideshowBlock
	| IDocumentBlock
	| IVideoBlock
	| IProductBlock
	| IComponentBlock
	| INoteBlock
	| IReactionBlock
	| IEmbedBlock

export type AllComponentBlockTypes = AllBlockTypes | IOverrideProductBlock

export type AllCreateBlockTypes = Partial<
	| ITextBlock
	| IQuoteBlock
	| IImageBlock
	| ISlideshowBlock
	| IDocumentBlock
	| IVideoBlock
	| ICreateProductBlock
	| INoteBlock
	| IEmbedBlock
	| IReactionBlock
> &
	Pick<AllBlockTypes, 'type'>

export interface ITextBlock extends IBlock {
	type: EBlockType.TEXT
	body: string
}

export interface IQuoteBlock extends IBlock {
	type: EBlockType.QUOTE
	body: string
	name: string
}

export interface IImageBlock extends IBlock {
	type: EBlockType.IMAGE
	url: string
	mediaId?: number
	full: boolean
	body: string
	composition: EImageBlockCompositions
	textStyle: ETextStyles
	textColor: string | null
	borderRadius: string | null
	filter: string | null
	effect: EImageEffects
	croppedImageUrl: string | null
	croppedImageCoordinates: IImageCoordinates | null
	reversed: boolean
}

export interface IEmbedBlock extends IBlock {
	type: EBlockType.EMBED
	url: string
	embedType: EEmbedType
	full: boolean
}

export interface IImageCoordinates {
	width: number
	height: number
	left: number
	top: number
}

export enum EImageEffects {
	NONE = 'NONE',
	PARALLAX = 'PARALLAX',
	FIXED = 'FIXED',
}
export enum ETextStyles {
	CUSTOM = 'custom',
	CAPTION = 'caption',
	BIG = 'big',
	EXTRA_BIG = 'extra_big',
}

export enum EImageBlockCompositions {
	FULL_WIDTH = 'fullWidth',
	TEXT_WIDTH = 'textWidth',
	HALF_SCREEN = 'halfScreen',
	QUARTER_SCREEN = 'quarterScreen',
	ICON = 'icon',
}

export enum ImportLayouts {
	NONE = 'none',
	ONLY_TEXT = 'onlyText',
	QUARTER_SCREEN = 'quarterScreen',
	HALF_SCREEN = 'halfScreen',
	FULL_WIDTH = 'fullWidth',
}

export interface IDocumentBlock extends IBlock {
	type: EBlockType.DOCUMENT
	urls: IDocumentUrl[]
}
export interface IDocumentUrl {
	id?: number
	title: string
	fileName: string
	fileSize?: number
	url: string
	mediaId?: number
}

export interface ISlideshowBlock extends IBlock {
	type: EBlockType.SLIDESHOW
	images: ISlideshowImage[]
	full: boolean
	fullHeight: boolean
	collectionType: ECollectionTypes
	columns: number
	collageCompositionNumber: number
}
export interface ICollageItemData {
	width: string
	height: string
	left: string
	top: string
	order: number
}

export interface ICollageItemDataWithImageIndex extends ICollageItemData {
	imageIndex: number
}

export enum ECollectionTypes {
	SLIDESHOW = 'slideshow',
	COLLAGE = 'collage',
	GRID = 'grid',
	MASONRY = 'masonry',
}

export interface ISlideshowImage {
	src: string
	mediaId: number
	height?: number
	width?: number
	text?: string
	textColor?: string | null
	textStyle?: ETextStyles
	borderRadius?: string
	filter?: string
	effect?: EImageEffects
}

export interface ISlideShowOptions {
	loop: boolean
	centeredSlides: boolean
	loopedSlides: number
	slidesToScroll: number
	currentPage: number
	effect?: string
}

export interface IImageData {
	url: string
	mediaId: number
}

export interface IVideoBlock extends IBlock {
	type: EBlockType.VIDEO
	platform: 'youtube' | 'vimeo'
	videoId: string
	full: boolean
	thumbnail: string
	loop: boolean
	muted: boolean
	controls: boolean
	start: number
}

export interface IProductBlock extends IBlock {
	type: EBlockType.PRODUCT
	name: string
	productCode: string | null
	amount: number
	unit: IUnit
	unitJson: IUnit
	flexibility: EProductFlexibility
	recurring: EProductRecurring
	optional: boolean
	show: boolean
	showPrice: boolean
	checked: boolean
	checkedBy: ECheckedBy
	active: boolean
	currencies: IProductCurrency[]
}
export enum ECheckedBy {
	AUTHOR = 'author',
	CLIENT = 'client',
}
export interface IProductCurrency {
	id?: number
	currency: ECurrencyCode
	price: number
	purchaseCost: number | null
	discount: number
	discountUnit: string
	vat: IProductVat
	product?: IProduct
	productBlock?: IProductBlock
	overrides: IProductCurrencyOverride[]
}

export interface IProductCurrencyOverride {
	workspaceId: number
	productCurrencyId?: number
	price?: number
}

export type IPeriods = 'yearly' | 'monthly' | 'weekly' | 'daily' | 'once'

export type IProductPriceBreakdown = {
	/** How many pieces the total consists of */
	amount: number
	/** Total product price excl. VAT */
	total: number
	/** Total product discount amount excl. VAT */
	discount: number
	/** Amount of VAT on total (not percentage) */
	vat: number
	/** Amount of VAT on discount (not percentage) */
	vatDiscount: number
	/** Purchase cost per unit */
	purchaseCost: number
	/** The profit margin of a product*/
	margin: number
}

export type ITotalPeriodicCost = {
	[key in IPeriods]: Pick<
		IProductPriceBreakdown,
		'total' | 'discount' | 'vat' | 'vatDiscount' | 'purchaseCost' | 'margin'
	>
}

export type INullableProductBlock = Omit<NullAble<IProductBlock>, 'type'> & Pick<IProductBlock, 'type'>

export interface IOverrideProductBlock extends IProductBlock {
	product: IProduct
	componentProductBlock?: IComponentBlock
}

export interface IComponentBlock extends IBlock {
	blocks: AllComponentBlockTypes[]
	childComponentId: number
	productDataOverride: IProductBlock[]
	type: EBlockType.COMPONENT
}
export interface ICreateProductBlock extends Omit<IProductBlock, 'unit' | 'vat'> {
	vat: IProductVat | number
	unit: IUnit | number
}

export interface IProposalPage {
	id: number
	title: string
	blocks: AllBlockTypes[]
	proposalId: string
	proposalOrder: number
	budget: boolean
	show: boolean
	locked: boolean
	accept?: boolean
	reject?: boolean
}

export enum EProposalStatus {
	WON = 'won',
	OPEN = 'open',
	EXPIRED = 'expired',
	LOST = 'lost',
	CONCEPT = 'concept',
	REVIEW = 'review',
}

export enum EDashboardViewTypes {
	CARD = 'card',
	TABLE = 'table',
}

export interface IToggle {
	id: number
	title: string
	active: boolean
	icon: string
}

export enum EFooterPrice {
	NONE = 'none',
	ONCE = 'once',
	DAILY = 'daily',
	MONTHLY = 'monthly',
	QUARTERLY = 'quarterly',
	YEARLY = 'yearly',
}

export interface NoteUser {
	id: number
	firstName: string
	lastName: string
	avatar: string
}

export interface INoteBlock extends IBlock {
	type: EBlockType.NOTE
	body: string
	showNote: boolean
	user: NoteUser
}

export interface FontObject {
	[key: string]: {
		currentDropdownItemFonts: DropdownItem
		currentDropdownItemFontSize: DropdownItem
		currentDropdownItemFontStyle: DropdownItem
		currentDropdownItemFontAlign: DropdownItem
	}
}

export interface Fonts {
	[key: string]: IFont
}

export interface IReactionBlock extends IBlock {
	type: EBlockType.REACTION
	title: string
	subtitle: string
	selectedEmojiId?: number
	emojis: IEmoji[]
	deletedEmojiId?: number | null
	previousSelectedEmojiId?: number | null
}

export interface IEmoji {
	id?: number
	name: string
	text?: string | null
}

// PROPOSAL - COMPLETE
export interface IProposal extends IDashboardProposal {
	comittal: boolean
	template: boolean
	workspaceId: number
	workspaceName: string
	showVat: boolean
	darkImage: string
	image: string
	backgroundImage: string
	backgroundColor: string
	backgroundOpacity: [number, number]
	pages: IProposalPage[]
	client: IProposalClient | undefined | null
	contactPerson: IContactPerson | null
	sendAt: string | null
	user: IBaseUser
	footerPrice: EFooterPrice
	dark: boolean
	useConfetti: boolean
	useTermsAndConditions: boolean
	updatedAt: string

	isMarkedWon: boolean
	markedWonFileUrl: string

	signatureUrl: string | null
	signedBy: string | null
	signedPlace: string | null
	signedAt: string | null
	conditions: IProposalConditions | null
	purchaseNumber: string | null

	clientSignatureUrl: string | null
	clientSignedBy: string | null
	clientSignedPlace: string | null
	clientSignedAt: string | null
	clientSignedFor: string | null

	clientSignatureRequired: boolean
	senderSignatureRequired: boolean

	currency: ECurrencyCode

	contractType: EContractTypes

	fonts: Fonts

	fromExampleTemplate: boolean
}

export interface IProposalConditions {
	id: number
	fileName: string
	name: string
	url: string
	createdAt: Date
	updatedAt: Date
}

export interface IProposalSignature {
	url: string
	for?: string
	name: string
	place: string
	date: string
}

export interface IProposalRejection {
	time: string
	date: string
	reasons: IRejectReason[]
}

export interface IRejectReason {
	id: string
}

// Proposal settings
export interface IProposalForm {
	id: string
	title: string | null
	darkImage: string
	image: string
	backgroundImage: string
	backgroundColor: string
	backgroundOpacity: [number, number]
	showVat: boolean
	// useComments: boolean,
	expiration: string | null
	client: any
	contactPerson: any
	user: IBaseUser
	footerPrice: EFooterPrice
	status: EProposalStatus
	statusChanged: boolean
	dark: boolean
	language: Languages | ''
	useConfetti: boolean
	useTermsAndConditions: boolean
	clientSignatureRequired: boolean
	senderSignatureRequired: boolean
	signedPlace: string | null
	signatureUrl: string | null
	markedWonFileUrl: string | null
	signedAt: string | null
	currency: ECurrencyCode | null
	fonts: Fonts | null
	contractType: EContractTypes
	fromExampleTemplate: boolean | null
}

export interface IProposalVisit {
	id: number
	clientId: number | null
	contactPersonId?: number
	proposalId: string
	workspaceId: number
	ip?: string
	date: string
}

export interface IBlockView {
	id: number
	clientId: number
	contactPersonId?: number
	proposalId: string
	blockId?: number | null
	pageId?: number | null
	start: string
	end: string
}

export interface IBaseTemplate {
	id: string
	title: string
	backgroundImage: string | null
	language: Languages
}

export interface IBaseUser {
	id: number
	firstName: string
	lastName: string
	email: string
	avatar: string | null
	language: Languages
	twoFactorEnabled: boolean
	deletedAt: string | null
}

export interface IUser extends IBaseUser {
	workspaces: IUserWorkspace[]
	accounts: IUserAccount[]
}

export interface IProposalClient {
	id: number
	name: string
	email: string | null
}

export interface IBaseClient extends IProposalClient {
	address: string | null
	city: string | null
	private: boolean
	avatar: string | null
	contactPersons: IContactPerson[]
}

export interface IClient extends IBaseClient {
	website?: string
	phone?: string
	customerId?: string
	postalCode?: string
	country?: string
	language?: Languages
	cocNumber?: string
	vatNumber?: string
	private: boolean
	avatar: string | null
	iban?: string
	tags?: IClientTag[]
	currency?: ECurrencyCode
}

export interface IClientTag extends ITag {
	value: string
	labelId: number
}

export interface IContactPerson {
	id: number
	name: string
	email: string
	avatar: string | null
}

export interface IConditions {
	id: number
	name: string
	url: string
}

export type ISavingState = 'saving' | Date | false | number

export enum Page {
	DASHBOARD = 'dashboard',
	TEMPLATES = 'templates',
	CLIENTS = 'clients',
	CATALOG = 'catalog',
	MEDIA = 'media',
	SETTINGS = 'settings',
	PROPOSAL = 'proposal',
	CLIENT = 'client',
	PROFILE = 'profile',
	COMPONENT = 'component',
}

export interface IValidationError {
	/**
	 * Object that was validated.
	 */
	target?: Record<string, any>
	/**
	 * Object's property that hasn't passed validation.
	 */
	property: string
	/**
	 * Value that hasn't passed validation.
	 */
	value?: any
	/**
	 * Constraints that failed validation with error messages.
	 */
	constraints?: {
		[type: string]: string
	}
	/**
	 * Contains all nested validation errors of the property.
	 */
	children?: IValidationError[]
}

export interface IcsvErrorsObject {
	[line: number]: IcsvError[]
}
export interface IcsvError {
	column?: keyof IcsvLine
	message: string
}

export interface IImportOptions {
	update?: boolean
	createCategories?: boolean
	overwriteContent?: boolean
	layout: ImportLayouts
}

export interface IImportProduct {
	productCode?: string
	name?: string
	categoryName?: string
	description?: string
	flexibility?: EProductFlexibility
	recurring?: EProductRecurring
	imageUrl?: string
	imageComposition?: EImageBlockCompositions
	price?: number
	unit?: string
	vatId?: number
	language?: Languages
	active: true
}

export interface IcsvLine {
	sku?: string
	title?: string
	price?: string
	unit?: string
	vat?: string
	flexibility?: string
	recurring?: string
	description?: string
	image?: string
	composition?: string
	category?: string
	language?: string
}

export const csvColumnMapping: { [x in keyof Required<IImportProduct>]: keyof IcsvLine | null } = {
	productCode: 'sku',
	name: 'title',
	price: 'price',
	unit: 'unit',
	vatId: 'vat',
	flexibility: 'flexibility',
	recurring: 'recurring',
	description: 'description',
	imageUrl: 'image',
	imageComposition: 'composition',
	categoryName: 'category',
	language: 'language',
	active: null,
}

export interface importCompleted {
	created: number
	updated: number
}

export interface DropdownItem {
	id: number
	value: string | number | IUnit | { weight: number; style: string } | null
	title?: string
	titleLong?: string
	icon?: string
	type?: string
	amount?: number
	hidden?: boolean
	categoryOrder?: number
	checked?: boolean
	customFont?: boolean
}

export type IModalKind =
	| 'edit'
	| 'remove'
	| 'warning'
	| 'warningBlue'
	| 'componentDelete'
	| 'image'
	| 'document'
	| 'unchain'
	| 'template'
	| null

export const thumbnailSizes = [1200, 600, 300] as const
export type ThumbnailSizes = (typeof thumbnailSizes)[number]
