import { MutationTree } from 'vuex'
import { IDashboardProposal } from '@/interfaces'
import { Namespaced } from '../..'
import { IProposalsStoreState } from '.'

export type ProposalsMutations<S = IProposalsStoreState> = {
	'mutateProposal'(state: S, payload: { proposalId: string; values: Partial<IDashboardProposal> }): void
	'removeProposal'(state: S, payload: string): void
	'mutateProposals'(state: S, payload: IDashboardProposal[]): void
	'mutateProposalsReadyForReview'(state: S, payload: IDashboardProposal[]): void
	'mutateVisits'(state: S, payload: Array<{ proposalId: string; amount: number }>): void
}

export type NamespacedProposalsMutations = Namespaced<ProposalsMutations, 'proposals'>

const mutations: MutationTree<IProposalsStoreState> & ProposalsMutations = {
	mutateVisits(state, payload) {
		state.visits = payload
	},
	mutateProposal(state, { proposalId, values }) {
		const proposalIdx = state.proposals.findIndex((prop) => prop.id === proposalId)
		const proposalsReadyForReviewIndex = state.proposalsReadyForReview.findIndex((prop) => prop.id === proposalId)

		if (proposalIdx > -1) {
			state.proposals.splice(proposalIdx, 1, { ...state.proposals[proposalIdx], ...values })
		}

		if (proposalsReadyForReviewIndex > -1) {
			state.proposalsReadyForReview.splice(proposalsReadyForReviewIndex, 1, {
				...state.proposalsReadyForReview[proposalsReadyForReviewIndex],
				...values,
			})
			state.proposalsReadyForReview.forEach((item, index) =>
				item.deletedAt ? state.proposalsReadyForReview.splice(index, 1) : null,
			)
		}
	},
	removeProposal(state, proposalId) {
		const proposalIdx = state.proposals.findIndex((prop) => prop.id === proposalId)
		const proposalReadyForReviewIndex = state.proposalsReadyForReview.findIndex((prop) => prop.id === proposalId)
		if (proposalIdx > -1) {
			state.proposals.splice(proposalIdx, 1)
			state.proposalsReadyForReview.splice(proposalReadyForReviewIndex, 1)
		}
	},
	mutateProposals(state, payload) {
		state.proposals = payload
	},
	mutateProposalsReadyForReview(state, payload) {
		state.proposalsReadyForReview = payload
	},
}

export default mutations
