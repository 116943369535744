import { Vue } from 'vue-property-decorator'
import {
	ConfettiType,
	DropdownItem,
	EBlockType,
	EEmbedType,
	ECurrencyCode,
	EGlobalProductUnitIds,
	EProductDiscountUnit,
	EProductRecurring,
	EVatCountry,
	IProduct,
	IProductBlock,
	IProductCurrency,
	IProductPriceBreakdown,
	IProposal,
	IProposalPage,
	ITotalPeriodicCost,
	UnsupportedCurrencySymbols,
	IProductCurrencyOverride,
} from '@/interfaces'
import FastAverageColor from 'fast-average-color'
import { isEqual } from 'lodash'
import { getFullProductCurrency, getFullProduct, mergeProductCurrencyWithOverride } from '@/functions/productFunctions'
import confetti from 'canvas-confetti'
import { filterFileName } from '@/functions/stringFilterFunction'
import { ProductDiscountUnit } from './interfaces/product'

export default class GlobalMixins extends Vue {
	isMobile() {
		return window.innerWidth <= 435
	}

	isOSMac() {
		return navigator.appVersion.indexOf('Mac') != -1
	}

	stripPastedMarkup(e: ClipboardEvent) {
		document.execCommand('insertHtml', false, e.clipboardData?.getData('text/plain'))
	}

	toInitials(string: string) {
		let initials: string = ''
		let wordArr = string.split(' ')

		wordArr = wordArr.filter((el: any) => {
			return el != ''
		})

		if (wordArr.length) {
			if (wordArr.length === 1 && wordArr[0][1]) {
				initials += wordArr[0][0]
				initials += wordArr[0][1]
			} else if (wordArr.length === 1 && !wordArr[0][1]) {
				initials += wordArr[0][0]
			} else {
				initials += wordArr[0][0]
				initials += wordArr[wordArr.length - 1][0]
			}
		}

		return initials.toUpperCase()
	}
	toRGB(string: string, light: boolean = false): string {
		let hash = 0
		if (string.length === 0) return ''
		for (let i = 0; i < string.length; i++) {
			hash = string.charCodeAt(i) + ((hash << 5) - hash)
			hash = hash & hash
		}
		const rgb = [0, 0, 0]
		for (let i = 0; i < 3; i++) {
			rgb[i] = (hash >> (i * 8)) & 255
		}
		const code = `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`

		return light
			? this.lightOrDark(code) === 'dark'
				? `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.2)`
				: `rgba(${rgb[0] / 1.2}, ${rgb[1] / 1.2}, ${rgb[2] / 1.2}, 0.2)`
			: this.lightOrDark(code) === 'dark'
			? code
			: `rgb(${rgb[0] / 1.2}, ${rgb[1] / 1.2}, ${rgb[2] / 1.2})`
	}

	lightOrDark(color: string): 'light' | 'dark' {
		// Variables for red, green, blue values
		let r: number = 74,
			g: number = 166,
			b: number = 252,
			hsp: number

		// Check the format of the color, HEX or RGB?
		if (color.match(/^rgb/)) {
			// If HEX --> store the red, green, blue values in separate variables
			const arr = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)
			if (arr) {
				r = Number(arr[1])
				g = Number(arr[2])
				b = Number(arr[3])
			}
		} else {
			// If RGB --> Convert it to HEX: http://gist.github.com/983661
			const _col: number = +('0x' + color.slice(1).replace(<any>(color.length < 5 && /./g), '$&$&'))
			r = _col >> 16
			g = (_col >> 8) & 255
			b = _col & 255
		}

		// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
		hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

		// Using the HSP value, determine whether the color is light or dark
		if (hsp > 127.5) {
			return 'light'
		} else {
			return 'dark'
		}
	}

	isImageLight(src: string, l: (_: boolean) => any): void {
		const img: HTMLImageElement = new Image()
		img.crossOrigin = 'Anonymous'
		img.src = src
		img.addEventListener('load', () => {
			let fac = new FastAverageColor()
			let color = fac.getColor(img)
			l(color.isDark)
		})
	}

	isEmailValid(email: string): boolean {
		const re =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return re.test(email)
	}

	formatProductMarginString(
		priceBreakdown: Pick<IProductPriceBreakdown, 'total' | 'discount' | 'margin'>,
		currency: ECurrencyCode,
	) {
		const sellingPrice = priceBreakdown.total - priceBreakdown.discount
		const formattedMargin = this.formatPrice(priceBreakdown.margin, currency)

		return `${formattedMargin}${
			sellingPrice > 0 ? ` (${Math.round((priceBreakdown.margin / sellingPrice) * 100)}%)` : ''
		}`
	}

	calcPagesTotalCost(pages: IProposalPage[], currency: ECurrencyCode, workspaceId?: number) {
		let amounts: ITotalPeriodicCost = {
			yearly: {
				total: 0,
				discount: 0,
				vat: 0,
				vatDiscount: 0,
				purchaseCost: 0,
				margin: 0,
			},
			monthly: {
				total: 0,
				discount: 0,
				vat: 0,
				vatDiscount: 0,
				purchaseCost: 0,
				margin: 0,
			},
			weekly: {
				total: 0,
				discount: 0,
				vat: 0,
				vatDiscount: 0,
				purchaseCost: 0,
				margin: 0,
			},
			daily: {
				total: 0,
				discount: 0,
				vat: 0,
				vatDiscount: 0,
				purchaseCost: 0,
				margin: 0,
			},
			once: {
				total: 0,
				discount: 0,
				vat: 0,
				vatDiscount: 0,
				purchaseCost: 0,
				margin: 0,
			},
		}

		for (const page of pages) {
			for (const block of page.blocks) {
				if (block.type === EBlockType.PRODUCT && (block.checked || !block.optional)) {
					const productCurrency = block.currencies.find((productCurrency) => productCurrency.currency == currency)
					if (!productCurrency) continue

					const productPriceBreakdown = this.calcProductPriceBreakdown(block, productCurrency)
					const recurring = block.recurring ?? EProductRecurring.Once
					amounts[recurring].total += productPriceBreakdown.total
					amounts[recurring].discount += productPriceBreakdown.discount
					amounts[recurring].vat += productPriceBreakdown.vat
					amounts[recurring].vatDiscount += productPriceBreakdown.vatDiscount
					amounts[recurring].purchaseCost += productPriceBreakdown.purchaseCost
					amounts[recurring].margin += productPriceBreakdown.margin
				} else if (block.type === EBlockType.COMPONENT) {
					for (const componentBlock of block.blocks) {
						if (componentBlock.type === EBlockType.PRODUCT) {
							const fullProduct = getFullProduct(componentBlock)

							const productCurrency = fullProduct.currencies.find(
								(productCurrency) => productCurrency.currency == currency,
							)
							if (!productCurrency) continue
							const fullProductCurrency = getFullProductCurrency(componentBlock, productCurrency, workspaceId)

							const productPriceBreakdown = this.calcProductPriceBreakdown(fullProduct, fullProductCurrency)
							const recurring = fullProduct.recurring ?? EProductRecurring.Once
							amounts[recurring].total += productPriceBreakdown.total
							amounts[recurring].discount += productPriceBreakdown.discount
							amounts[recurring].vat += productPriceBreakdown.vat
							amounts[recurring].vatDiscount += productPriceBreakdown.vatDiscount
							amounts[recurring].purchaseCost += productPriceBreakdown.purchaseCost
							amounts[recurring].margin += productPriceBreakdown.margin
						}
					}
				}
			}
		}
		return amounts
	}

	calcProductPriceBreakdown(
		product: IProductBlock | IProduct,
		originalProductCurrency: IProductCurrency,
		productCurrencyOverride?: IProductCurrencyOverride,
	): IProductPriceBreakdown {
		const productCurrency = mergeProductCurrencyWithOverride(originalProductCurrency, productCurrencyOverride)

		const vatFraction = productCurrency.vat.percentage / 100

		const price = productCurrency.vat.including ? productCurrency.price / (vatFraction + 1) : productCurrency.price

		const amount = product.unit?.id === EGlobalProductUnitIds.PERCENTAGE ? product.amount / 100 : product.amount

		const total = price * amount

		const discount =
			productCurrency.discountUnit === EProductDiscountUnit.Percentage
				? (productCurrency.discount / 100) * total
				: productCurrency.vat.including
				? productCurrency.discount / (vatFraction + 1)
				: productCurrency.discount

		const vat = total * vatFraction
		const vatDiscount = discount * vatFraction

		const purchaseCost = productCurrency.purchaseCost ?? 0
		const totalPurchaseCost = purchaseCost * amount

		const margin = total - discount - totalPurchaseCost

		return {
			amount,
			total,
			discount,
			vat,
			vatDiscount,
			purchaseCost,
			margin,
		}
	}

	calcPriceBreakDownTotal(
		breakdown: Pick<IProductPriceBreakdown, 'total' | 'discount' | 'vat' | 'vatDiscount'>,
		options?: { includeVat?: boolean },
	): number {
		return breakdown.total - breakdown.discount + (options?.includeVat ? breakdown.vat - breakdown.vatDiscount : 0)
	}

	proposalPdfName(proposal: IProposal) {
		return `${filterFileName(proposal.title ? proposal.title : proposal.id)}-(${filterFileName(
			proposal.workspaceName!,
		)}).pdf`
	}

	getProposalPdfUrl(proposal: IProposal) {
		return `${process.env.VUE_APP_API_URL}/proposals/pdf/${proposal.id}/${this.proposalPdfName(proposal)}?c=${
			proposal.client ? proposal.client.id : ''
		}&cp=${proposal.contactPerson ? proposal.contactPerson.id : ''}`
	}

	toBase64(file: File): Promise<string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result as string)
			reader.onerror = (error) => reject(error)
		})
	}

	difference<T extends Object>(
		newValue: T,
		oldValue: T,
		requiredFields?: (keyof T)[],
	): { newValue: Partial<T>; oldValue: Partial<T> } {
		if (isEqual(newValue, oldValue)) {
			return { newValue: {}, oldValue: {} }
		}

		return ([...new Set([...Object.keys(newValue), ...Object.keys(oldValue)])] as (keyof T)[]).reduce(
			(acc, curr) =>
				curr in newValue && curr in oldValue && isEqual(newValue[curr], oldValue[curr])
					? acc
					: {
							newValue: { ...acc.newValue, [curr]: newValue[curr] },
							oldValue: { ...acc.oldValue, [curr]: oldValue[curr] },
					  },
			{
				newValue: requiredFields
					? requiredFields.reduce((_acc, _curr) => ({ ..._acc, [_curr]: newValue[_curr] }), {})
					: {},
				oldValue: requiredFields
					? requiredFields.reduce((_acc, _curr) => ({ ..._acc, [_curr]: oldValue[_curr] }), {})
					: {},
			},
		)
	}

	listItemsForFormDropdown(list: Record<string, string>, type?: string): DropdownItem[] {
		if (!(typeof list === 'object' && list !== null)) {
			return []
		}
		const keys = Object.keys(list)
		return keys.map((key, index) => ({
			value: key,
			title: list[key],
			id: index,
			...(type ? { type } : {}),
		}))
	}

	/**
	 * Mixin for firing confetti.
	 *
	 * Uses canvas-confetti dependency.
	 *
	 * Make sure to check if workspace or proposal setting for confetti is enabled.
	 *
	 * @param {ConfettiType} type - Type of confetti that needs to fire.
	 * @param {ConfettiPayload} confettiPayload - Values for different characteristics of the confetti.
	 * @param {number} duration - The duration of confetti effects that last for a time.
	 * @param {number} intervalTime - The time between firing a interval for a confetti effect.
	 */
	shootConfetti(
		type: ConfettiType,
		confettiPayload?: confetti.Options,
		duration: number = 5000,
		intervalTime: number = 250,
	) {
		switch (type) {
			case 'default':
				confetti({
					...confettiPayload,
				})
				break

			case 'firework':
				let fireworkEnd = Date.now() + duration

				let interval: any = setInterval(() => {
					let timeLeft = fireworkEnd - Date.now()

					if (timeLeft <= 0) {
						return clearInterval(interval)
					}

					let fadeParticleCount
					if (confettiPayload?.particleCount !== undefined) {
						fadeParticleCount = confettiPayload.particleCount * (timeLeft / duration)
					} else {
						fadeParticleCount = 50
					}

					let randomPositionY = Math.random() - 0.2
					let randomPositionX = Math.random()
					let randomOrigin = { x: randomPositionX, y: randomPositionY }

					confetti({ ...confettiPayload, particleCount: fadeParticleCount, origin: randomOrigin })
					confetti({ ...confettiPayload, particleCount: fadeParticleCount, origin: randomOrigin })
				}, intervalTime)
				break

			case 'snow':
				let snowEnd = Date.now() + duration
				let skew = 1

				function randomInRange(min: number, max: number) {
					return Math.random() + (max - min) + min
				}

				;(function frame() {
					let timeLeft = snowEnd - Date.now()
					let ticks = Math.max(200, 500 * (timeLeft / duration))
					skew = Math.max(0.8, skew - 0.001)

					// To create the snow effect I needed to set a lot of the characteristics
					confetti({
						...confettiPayload,
						particleCount: 1,
						startVelocity: 0,
						ticks: ticks,
						origin: {
							x: Math.random(),
							y: Math.random() * skew - 0.2,
						},
						gravity: randomInRange(0.4, 0.6),
						scalar: randomInRange(0.4, 1),
						drift: randomInRange(-0.4, 0.4),
					})

					if (timeLeft > 0) requestAnimationFrame(frame)
				})()
				break

			case 'cannons':
				let cannonsEnd = Date.now() + duration

				;(function frame() {
					confetti({
						...confettiPayload,
						angle: 60,
						origin: { x: 0 },
					})
					confetti({
						...confettiPayload,
						angle: 120,
						origin: { x: 1 },
					})

					if (Date.now() < cannonsEnd) requestAnimationFrame(frame)
				})()
				break

			default:
				confetti({
					particleCount: 100,
					spread: 90,
					origin: { y: 1 },
				})
				break
		}
	}

	viewModeTooltipText() {
		return this.$typedStore.getters.activeCopy.general.viewMode
	}

	/**
	 * This function is for checking the url of the embed
	 * @param {string} embedUrl - Url of the embed media
	 * @param {string} embedType - The embed provider
	 * @returns if url is valid
	 */
	checkUrlForEmbed(embedUrl: string, embedType: string) {
		let url

		try {
			url = new URL(embedUrl)
		} catch {
			return false
		}

		let valid
		let regex

		switch (this.capitalizeFirstLetter(embedType)) {
			case EEmbedType.FIGMA:
				regex = /https:\/\/([\w\.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/
				valid = regex.test(embedUrl)
				break
			case EEmbedType.MIRO:
				regex = /https:\/\/([\w\.-]+\.)?miro.com\/(app)\/(board)\/([a-zA-Z0-9]{6,128}=)(?:\/.*)?$/
				valid = regex.test(embedUrl)
				break
			case EEmbedType.FLOURISH:
				regex = /https:\/\/([\w\.-]+\.)?public\.flourish.studio\/visualisation\/([0-9]{6,128})(?:\/.*)?$/
				valid = regex.test(embedUrl)
				break
			default:
				valid = false
				break
		}
		return valid
	}

	capitalizeFirstLetter(string: string) {
		return string.charAt(0).toUpperCase() + string.slice(1)
	}

	formatPrice(price: number | string, currency: ECurrencyCode = ECurrencyCode.EUR) {
		const result = new Intl.NumberFormat(this.getCurrencyLocale(currency), {
			style: 'currency',
			currency: currency,
		}).format(Number(price))

		//unsupported currency symbols
		switch (currency) {
			case 'BTC':
				return result.replace('BTC', UnsupportedCurrencySymbols.BTC)
			case 'ETH':
				return result.replace('ETH', UnsupportedCurrencySymbols.ETH)
			case 'CHF':
				return result.replace('CHF', UnsupportedCurrencySymbols.CHF)
		}

		return result
	}

	getCurrencySymbol(currencyCode: ECurrencyCode = ECurrencyCode.EUR): string {
		switch (currencyCode) {
			case 'BTC':
				return UnsupportedCurrencySymbols.BTC
			case 'ETH':
				return UnsupportedCurrencySymbols.ETH
			case 'CHF':
				return UnsupportedCurrencySymbols.CHF
		}

		const locale = this.getCurrencyLocale(currencyCode)

		const formatter = new Intl.NumberFormat(locale, {
			style: 'currency',
			currency: currencyCode,
		})

		const parts = formatter.formatToParts ? formatter.formatToParts(123) : null

		if (parts) {
			const currencyPart = parts.find((part) => part.type === 'currency')
			return currencyPart ? currencyPart.value : ''
		} else {
			// Fallback for environments that don't support formatToParts
			const formatted = formatter.format(123)
			const withoutDigits = formatted.replace(/\d|\.|,/g, '').trim()
			return withoutDigits
		}
	}

	getCurrencyLocale(currencyCode: ECurrencyCode = ECurrencyCode.EUR) {
		const currencyLocaleMap: { [key in ECurrencyCode]: string } = {
			[ECurrencyCode.EUR]: 'nl-NL',
			[ECurrencyCode.USD]: 'en-US',
			[ECurrencyCode.GBP]: 'en-UK',
			[ECurrencyCode.AUD]: 'en-AU',
			[ECurrencyCode.CAD]: 'en-CA',
			[ECurrencyCode.CNY]: 'zh-CN',
			[ECurrencyCode.INR]: 'en-IN',
			[ECurrencyCode.JPY]: 'ja-JP',
			[ECurrencyCode.CHF]: 'de-CH',
			[ECurrencyCode.AED]: 'ar-AE',
			[ECurrencyCode.BTC]: 'en-US',
			[ECurrencyCode.ETH]: 'en-US',
		}

		return currencyLocaleMap[currencyCode]
	}

	addMissingProductCurrencies(productCurrencies: IProductCurrency[], currencies: ECurrencyCode[]): IProductCurrency[] {
		if (productCurrencies.length == currencies.length) return productCurrencies

		const presentCurrencies = productCurrencies.map((productCurrencies) => productCurrencies.currency)
		let missingProductCurrencies: IProductCurrency[] = []

		currencies.forEach((currency) => {
			if (!presentCurrencies.includes(currency)) {
				missingProductCurrencies.push(this.getMissingProductCurrency(currency))
			}
		})

		return [...productCurrencies, ...missingProductCurrencies] as IProductCurrency[]
	}

	getMissingProductCurrency(currency: ECurrencyCode, empty: boolean = false) {
		return {
			id: undefined,
			currency,
			price: empty ? null : 0.0,
			purchaseCost: empty ? null : 0.0,
			discount: empty ? null : 0,
			discountUnit: ProductDiscountUnit.currency,
			vat: empty
				? null
				: {
						id: 5,
						name: 'excl_high',
						country: EVatCountry.NL,
						percentage: 21,
						including: false,
				  },
		} as IProductCurrency
	}
}
