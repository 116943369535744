import { BaseStoreCommand } from '../BaseStoreCommand'
import { ProposalActionContext } from '@/store/modules/proposal/actions'
import { ProposalPagesManager } from '@/commands/PageManager/ProposalPagesManager'

export class RemovePageCommand extends BaseStoreCommand<ProposalActionContext> {
	constructor(
		store: ProposalActionContext,
		private pageId: number,
	) {
		super(store)
		this.undoable = false
	}

	async execute(): Promise<void> {
		await new ProposalPagesManager(this.store).deletePage(this.pageId)
	}

	async undo(): Promise<void> {}
}
