import { ComponentActionContext } from '@/store/modules/component/actions'
import { IBlock, IComponent } from '@/interfaces'
import { BlockContainerManager } from '@/commands/Block/Manager/BlockContainerManager'

export class ComponentManager extends BlockContainerManager {
	private component: IComponent

	constructor(public store: ComponentActionContext) {
		super()
		const component = this.store.state.component
		if (component) {
			this.component = component
		} else {
			throw new Error('Unable to create componentManager, component is undefined in state')
		}
	}

	reloadData() {
		const component = this.store.state.component
		if (component) {
			this.component = component
		} else {
			throw new Error('Unable to create componentManager, component is undefined in state')
		}
	}

	async updateStore() {
		await this.store.commit('mutateComponent', this.component)
	}

	get blocks() {
		return this.component.blocks
	}

	get workspaceId() {
		return this.component.workspaceId
	}

	get isActiveProduct() {
		return !!this.component?.product && this.component?.product.active == true
	}

	get baseUrl() {
		return `workspaces/${this.component.workspaceId}/catalog/${this.isActiveProduct ? 'products' : 'components'}/${
			this.component.id
		}/content`
	}

	async reloadBlockContainer() {
		await this.store.dispatch('loadComponent', { componentId: this.component.id })
	}

	handleError(e: Error) {
		console.error(e)
	}

	setOrders() {
		const amount = this.component.blocks ? this.component.blocks.length : 0
		for (let i = 0; i < amount; i++) {
			this.component.blocks[i].componentOrder = i
		}
	}

	get orderProperty(): keyof IBlock {
		return 'componentOrder'
	}
}
