import { BaseCommand } from '@/commands/BaseCommand'
import { AllBlockTypes, EBlockDirection, IBlock, IProposalPage } from '@/interfaces'
import { BlockContainerManager } from '../Manager/BlockContainerManager'

export class ChangeBlockPageCommand extends BaseCommand {
	constructor(
		private blockContainerManager: BlockContainerManager,
		private newBlockPage: IProposalPage,
		private block: AllBlockTypes,
		private oldBlockOrder: number,
		private direction: EBlockDirection,
		private referenceObject: { [key: string]: Vue | Vue[] | Element | HTMLElement },
	) {
		super()
	}

	async execute(): Promise<void> {
		this.blockContainerManager.reloadData()
		await this.blockContainerManager.changeBlockPage(
			{
				newBlockPage: this.newBlockPage,
				block: this.block,
				oldBlockOrder: this.oldBlockOrder,
				direction: this.direction,
			},
			this.referenceObject,
		)
	}

	async undo(): Promise<any> {}
}
