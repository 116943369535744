import { ActionTree } from 'vuex'
import { IBaseClient, IClient, IConditions, IContactPerson } from '@/interfaces'
import { AugmentedActionContext, IState, Namespaced } from '@/store'
import { IClientsStoreState } from '.'
import { ClientMutations } from './mutations'
import { ClientGetters } from './getters'
import { CreateClientCommand, CreateClientCommandPayload } from '../../../commands/ClientCommands/CreateClientCommand'
import { DeleteClientCommand } from '../../../commands/ClientCommands/DeleteClientCommand'
import { OffriHTTP } from '@/axiosRequestFunctions'
import { UpdateClientCommand } from '../../../commands/ClientCommands/UpdateClientCommand'
import { DeleteContactPersonCommand } from '../../../commands/ClientCommands/DeleteContactPersonCommand'
import { UpdateContactPersonCommand } from '../../../commands/ClientCommands/UpdateContactPersonCommand'
import { CreateContactPersonCommand } from '../../../commands/ClientCommands/CreateContactPersonCommand'

export type ClientActionContext = AugmentedActionContext<
	IClientsStoreState,
	ClientMutations,
	ClientGetters,
	ClientActions
>

export interface ClientActions {
	loadClients(context: ClientActionContext): Promise<void>
	loadClient(context: ClientActionContext, payload: number): Promise<void>
	createClient(
		context: ClientActionContext,
		payload: { client: CreateClientCommandPayload; navigateWhenCreated: boolean },
	): void
	updateClient(
		context: ClientActionContext,
		payload: { id: number; newValue: Partial<IClient>; oldValue: Partial<IClient> },
	): void
	deleteClient(context: ClientActionContext, payload: number): void
	createContactPerson(context: ClientActionContext, payload: { clientId: number; body: IContactPerson }): void
	updateContactPerson(
		context: ClientActionContext,
		payload: {
			clientId: number
			contactPersonId: number
			newValue: Partial<IContactPerson>
			oldValue: Partial<IContactPerson>
		},
	): void
	deleteContactPerson(context: ClientActionContext, payload: { clientId: number; contactPersonId: number }): void
	search(context: ClientActionContext, payload: string): void
	loadClientConditions(context: ClientActionContext, payload: number): void
}

export type NamespacedClientActions = Namespaced<ClientActions, 'clients'>

const actions: ActionTree<IClientsStoreState, IState> & ClientActions = {
	async loadClients({ commit, state, rootState, dispatch }) {
		if (rootState.loading && state.loadedAt) {
			rootState.loading = false
		}
		if (rootState.workspace) {
			await OffriHTTP.get<IBaseClient[]>(`/workspaces/${rootState.workspace.id}/clients`).then((res) => {
				commit('mutateClients', res.data)
				rootState.loading = false
			})
			commit('mutateLoadedAt')
		}
	},
	async loadClient({ commit, rootState, dispatch }, payload) {
		if (rootState.workspace) {
			await OffriHTTP.get<IClient>(`/workspaces/${rootState.workspace.id}/clients/${payload}`).then((res) => {
				commit('removeClient', res.data.id)
				commit('addClient', res.data)
				dispatch('contentLoaded', undefined, { root: true })
			})
		}
	},
	async updateClient(injectee, payload) {
		await injectee.dispatch(
			'execute',
			new UpdateClientCommand(injectee, payload.id, payload.newValue, payload.oldValue),
			{ root: true },
		)
	},
	async deleteClient(injectee, clientId) {
		await injectee.dispatch('execute', new DeleteClientCommand(injectee, clientId), { root: true })
	},
	async createClient(injectee, { client, navigateWhenCreated = false }) {
		await injectee.dispatch('execute', new CreateClientCommand(injectee, client, navigateWhenCreated), { root: true })
	},
	async createContactPerson(injectee, { clientId, body }) {
		return await injectee.dispatch('execute', new CreateContactPersonCommand(injectee, clientId, body), { root: true })
	},
	async updateContactPerson(injectee, { clientId, contactPersonId, newValue, oldValue }) {
		await injectee.dispatch(
			'execute',
			new UpdateContactPersonCommand(injectee, clientId, contactPersonId, newValue, oldValue),
			{ root: true },
		)
	},
	async deleteContactPerson(injectee, { clientId, contactPersonId }) {
		await injectee.dispatch('execute', new DeleteContactPersonCommand(injectee, clientId, contactPersonId), {
			root: true,
		})
	},
	search({ commit }, payload) {
		commit('mutateSearchString', payload.toLowerCase())
	},
	async loadClientConditions({ commit, rootState }, clientId) {
		if (rootState.workspace) {
			await OffriHTTP.get<IConditions[]>(`/workspaces/${rootState.workspace.id}/clients/${clientId}/conditions`).then(
				(res) => {
					commit('mutateClientConditions', { clientId, conditions: res.data })
				},
			)
		}
	},
}

export default actions
