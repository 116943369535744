import { IComponentStoreState } from '@/store/modules/component/index'
import { IState, Namespaced } from '@/store'
import { GetterTree } from 'vuex'

export type ComponentGettersFunctions<S = IComponentStoreState> = {}

export type ComponentGetters = { [K in keyof ComponentGettersFunctions]: ReturnType<ComponentGettersFunctions[K]> }

export type NamespacedComponentGetter = Namespaced<ComponentGetters, 'component'>

const getters: GetterTree<IComponentStoreState, IState> & ComponentGettersFunctions = {}
export default getters
