import { render, staticRenderFns } from "./WorkspaceImage.vue?vue&type=template&id=4edf8d40&scoped=true&"
import script from "./WorkspaceImage.vue?vue&type=script&lang=ts&"
export * from "./WorkspaceImage.vue?vue&type=script&lang=ts&"
import style0 from "./WorkspaceImage.vue?vue&type=style&index=0&id=4edf8d40&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4edf8d40",
  null
  
)

export default component.exports