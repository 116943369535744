import { BaseStoreCommand } from '@/commands/BaseStoreCommand'
import { ProposalsActionContext } from '@/store/modules/proposals/actions'
import { OffriHTTP } from '@/axiosRequestFunctions'
import { IBaseUser, IProposal } from '@/interfaces'
import { Languages } from '@/interfaces/languageTypes'
import { mpTrackEvent } from '@/functions/tracking'
import { ProposalTemplate } from '@/interfaces/mixpanel'

export interface IDuplicateProposalCommand {
	root: 'proposals' | 'templates'
	proposalId: string
	language: Languages
	workspaceId?: number
	title?: string | null
	user?: IBaseUser
	clientId?: number
	contactPersonId?: number
}

export class DuplicateProposalCommand extends BaseStoreCommand<ProposalsActionContext> {
	constructor(
		protected readonly store: ProposalsActionContext,
		private readonly payload: IDuplicateProposalCommand,
	) {
		super(store)
	}

	async execute() {
		const data = await import(`@/languages/${this.payload.language}`)
		const labels = data.default.proposal
		const res = await OffriHTTP.post<IProposal>(
			`workspaces/${this.payload.workspaceId || this.store.rootState.workspace?.id}/${this.payload.root}`,
			{
				title: this.payload.title,
				client: this.payload.clientId,
				language: this.payload.language,
				labels: {
					firstPageName: labels.firstPageName,
					budgetPageName: labels.budgetPageName,
				},
				contactPerson: this.payload.contactPersonId,
				proposalId: this.payload.proposalId,
			},
			{ timeout: 20000 },
		)

		mpTrackEvent('Proposal Created', {
			proposal_id: this.payload.proposalId,
			template_type: ProposalTemplate.DUPLICATED,
		})

		this.store.dispatch('loadProposals', undefined)

		return res
	}

	async undo() {}
}
