import { MutationTree } from 'vuex'
import { IProductVat, ISavingState, IUser, IUserWorkspace } from '@/interfaces'
import { IState } from '@/store/index'
import { BaseCommand } from '@/commands/BaseCommand'

export type BaseMutations<S = IState> = {
	mutateSubscriptions(state: S, payload: any): void
	mutateErrors(state: S, payload: any): void
	mutateActiveLanguage(state: S, payload: any): void
	mutateAllCopies(state: S, payload: any): void
	mutateUser(state: S, payload: IUser | null): void
	pushCommandToRedoHistory(state: S, payload: BaseCommand): void
	resetRedoHistory(state: S, payload?: undefined): void
	mutateSavingState(state: S, payload: ISavingState): void
	mutateError(state: S, payload: any): void
	mutateWorkspace(state: S, payload: IUserWorkspace | null): void
	mutateNotificationShade(state: S, payload: boolean): void
	mutateVatOptions(state: S, payload: IProductVat[]): void
	mutateNotifications(state: S, payload: any): void
	pushCommandToHistory(state: S, payload: BaseCommand): void
	mutateNotificationsAmount(state: S, payload: number | null): void
	mutateUnits(state: S, payload: []): void
	mutateFonts(state: S, payload: []): void
	mutateActiveFullscreenModal(state: S, payload: string | null): void
}
const mutations: MutationTree<IState> & BaseMutations = {
	mutateActiveLanguage(state, payload) {
		state.activeLanguage = payload
	},
	mutateAllCopies(state, payload) {
		state.allCopies = payload
	},
	mutateSubscriptions(state, payload) {
		state.subscriptions = payload
	},
	mutateErrors(state, payload) {
		state.errors = payload
	},
	mutateSavingState(state, payload: ISavingState) {
		state.savingState = payload
	},
	mutateError(state, payload) {
		state.error = payload
	},
	mutateUser(state, payload) {
		state.user = payload
	},
	mutateVatOptions(state, payload) {
		state.vatOptions = payload
	},
	mutateWorkspace(state, payload) {
		state.workspace = payload
	},
	mutateNotificationShade(state, payload) {
		state.notificationShade = payload
	},
	mutateNotifications(state, payload) {
		state.notifications = payload
	},
	mutateNotificationsAmount(state, payload) {
		state.notificationsAmount = payload
	},
	pushCommandToHistory(state, payload) {
		state.historyCommands.push(payload)
	},
	pushCommandToRedoHistory(state, payload) {
		state.redoHistoryCommands.push(payload)
	},
	resetRedoHistory(state, payload) {
		state.redoHistoryCommands = []
	},
	mutateUnits(state, payload) {
		state.units = payload
	},
	mutateFonts(state, payload) {
		state.fonts = payload
	},
	mutateActiveFullscreenModal(state, payload) {
		state.activeFullscreenModal = payload
	},
}

export default mutations
