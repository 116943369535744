import { BaseStoreCommand } from '../BaseStoreCommand'
import { ProposalActionContext } from '@/store/modules/proposal/actions'
import { ProposalPagesManager } from '@/commands/PageManager/ProposalPagesManager'
import { IProposalPage } from '@/interfaces'

export class AddPageCommand extends BaseStoreCommand<ProposalActionContext> {
	constructor(
		store: ProposalActionContext,
		private order?: number | undefined,
		private properties?: Partial<Omit<IProposalPage, 'id' | 'blocks' | 'proposalId' | 'accept'>>,
	) {
		super(store)
		this.undoable = true
	}

	async execute(): Promise<any> {
		return await new ProposalPagesManager(this.store).addPage(this.order, this.properties)
	}

	async undo(): Promise<void> {}
}
