import { BaseStoreCommand } from '../BaseStoreCommand'
import { ClientActionContext } from '../../store/modules/clients/actions'
import { OffriHTTP } from '@/axiosRequestFunctions'

export class DeleteClientCommand extends BaseStoreCommand<ClientActionContext> {
	public undoable = false

	constructor(
		protected readonly store: ClientActionContext,
		private readonly clientId: number,
	) {
		super(store)
	}

	async execute() {
		if (this.store.rootState.workspace) {
			await OffriHTTP.delete(`workspaces/${this.store.rootState.workspace.id}/clients/${this.clientId}`).then(() => {
				this.store.commit('removeClient', this.clientId)
			})
		}
	}

	async undo() {}
}
