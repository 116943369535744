import { BaseStoreCommand } from '../../BaseStoreCommand'
import { ProposalActionContext } from '@/store/modules/proposal/actions'
import { IBlock, IProposalPage } from '@/interfaces'
import { PageManager } from '@/commands/PageManager/PageManager'
import ComponentBlock from '@/components/editor/ComponentBlock.vue'
import { BlockContainerManager } from '@/commands/Block/Manager/BlockContainerManager'
import { BaseCommand } from '@/commands/BaseCommand'

export class DecoupleComponentBlockCommand extends BaseCommand {
	private componentBlock: (IBlock & ComponentBlock) | undefined

	constructor(
		private blockContainerManager: BlockContainerManager,
		private blockIndex: number,
	) {
		super()
		this.undoable = false
	}

	async execute(): Promise<void> {
		this.blockContainerManager.reloadData()
		this.componentBlock = await this.blockContainerManager.decoupleComponentBlock(this.blockIndex)
	}

	async undo(): Promise<void> {}
}
