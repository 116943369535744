import mixpanel, { Callback, Dict, RequestOptions } from 'mixpanel-browser'

export function mpTrackEvent(
	event_name: string,
	properties?: Dict,
	optionsOrCallback?: RequestOptions | Callback,
	callback?: Callback,
) {
	try {
		mixpanel.track(event_name, properties, optionsOrCallback, callback)
	} catch (e) {
		console.error(`Mixpanel couldn't track the ${event_name} event`)
	}
}

export function mpIdentify(id: string, newUser: boolean = false) {
	try {
		mixpanel.identify(id)

		if (newUser) mixpanel.track('Registered')
	} catch (e) {
		console.error(`Mixpanel couldn't identify the user`)
	}
}

export function mpReset() {
	try {
		mixpanel.reset()
	} catch (e) {
		console.error(`Mixpanel couldn't reset the user`)
	}
}
