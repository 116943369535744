import { IComponentBlock, IOverrideProductBlock, IProductBlock } from '@/interfaces'

export enum ProductUnit {
	piece = 'piece',
	percentage = 'percentage',
	hour = 'hour',
	day = 'day',
	month = 'month',
	quarter = 'quarter',
	year = 'year',
}

export enum ProductDiscountUnit {
	percentage = 'percentage',
	currency = 'currency',
}

export enum ProductFlexibility {
	fixed = 'fixed',
	provisional = 'provisional',
	estimate = 'estimate',
}

export enum ProductRecurring {
	once = 'once',
	daily = 'daily',
	weekly = 'weekly',
	monthly = 'monthly',
	yearly = 'yearly',
}

export enum Productvat {
	free = '1',
	low = '2',
	high = '3',
}

export interface IBudgetProductData {
	type: EBudgetProductTypes
	block: IProductBlock | IOverrideProductBlock
	componentBlock?: IComponentBlock
}

export enum EBudgetProductTypes {
	NORMAL,
	OVERRIDEPRODUCT,
	INCOMPONENT,
}
