import { IOverrideProductBlock, IProductBlock, IProductCurrency, IProductCurrencyOverride } from '@/interfaces'

export function isOverrideProductBlockCheck(
	value: IProductBlock | IOverrideProductBlock,
): value is IOverrideProductBlock {
	return !!(value as IOverrideProductBlock).product
}
export function getFullProduct(productBlock: IProductBlock | IOverrideProductBlock) {
	if (isOverrideProductBlockCheck(productBlock)) {
		const cleanProductBlock = Object.fromEntries<IOverrideProductBlock>(
			Object.entries(productBlock).filter(([key, value]) => value !== null),
		)
		return { ...productBlock, ...productBlock.product, ...cleanProductBlock }
	}
	return productBlock
}

export function getFullProductCurrency(
	productBlock: IProductBlock | IOverrideProductBlock,
	productCurrency: IProductCurrency,
	workspaceId?: number,
) {
	if (isOverrideProductBlockCheck(productBlock)) {
		const cleanProductCurrency = Object.fromEntries<IProductCurrency>(
			Object.entries(productCurrency).filter(([key, value]) => value !== null),
		)
		const originalProductCurrency = productBlock.product.currencies.find(
			(value) => value.currency == productCurrency.currency,
		)

		if (originalProductCurrency !== undefined) {
			const originalOverride = getProductCurrencyOverride(originalProductCurrency, workspaceId)
			const productCurrencyMergedWithOverride = mergeProductCurrencyWithOverride(
				originalProductCurrency,
				originalOverride,
			)
			return { ...productCurrencyMergedWithOverride, ...cleanProductCurrency }
		} else if (!originalProductCurrency) {
			const missingCurrency = {
				id: undefined,
				currency: productCurrency.currency,
				price: 0.0,
				purchaseCost: 0.0,
				discount: 0,
				discountUnit: 'currency',
				vat: {
					id: 5,
					name: 'excl_high',
					country: 'nl',
					percentage: 21,
					including: false,
				},
			} as IProductCurrency

			return { ...missingCurrency, ...cleanProductCurrency }
		}

		return productCurrency
	}
	return productCurrency
}

export function mergeProductCurrencyWithOverride(
	productCurrency: IProductCurrency,
	override: IProductCurrencyOverride | undefined,
) {
	const overrideEntries = ['price']
	const productCurrencyOverride = override
		? Object.fromEntries<IProductCurrencyOverride>(
				Object.entries(override).filter(([key, value]) => value !== null && overrideEntries.includes(key)),
		  )
		: {}
	return { ...productCurrency, ...productCurrencyOverride }
}

export function getProductCurrencyOverride(
	productCurrency: IProductCurrency,
	workspaceId?: number,
): IProductCurrencyOverride | undefined {
	if (workspaceId == undefined) return
	return productCurrency?.overrides?.find((override) => override.workspaceId == workspaceId)
}
