import { UpdatePropertiesCommandTemplate } from '@/commands/abstractCommands/UpdatePropertiesCommandTemplate'
import { IComponent } from '@/interfaces'
import { AxiosResponse } from 'axios'
import { ComponentActionContext } from '@/store/modules/component/actions'

type ComponentServerUpdate = Omit<IComponent, 'category'> & { categoryId: number }

export class UpdateComponentProperties extends UpdatePropertiesCommandTemplate<IComponent, any, ComponentServerUpdate> {
	constructor(
		private store: ComponentActionContext,
		totalObject: IComponent,
		newProperties: Partial<IComponent>,
		oldProperties?: Partial<IComponent>,
	) {
		super(totalObject, newProperties, oldProperties)
	}

	changeStore(properties: Partial<IComponent>): void {
		this.store.commit('mutateComponent', { ...this.totalObject, ...properties })
	}

	get putUrl(): string {
		return `/workspaces/${this.store.rootGetters.workspace?.id}/catalog/${
			this.totalObject?.product ? 'products' : 'components'
		}/${this.totalObject.id}`
	}

	transformPropertiesForServer(componentUpdate: Partial<IComponent>) {
		const category = componentUpdate.category
		if (category?.id !== undefined) {
			return {
				...componentUpdate,
				category: undefined,
				categoryId: category.id,
			}
		} else {
			return componentUpdate
		}
	}

	serverRequestFailedCallback() {}

	serverRequestSucceed(
		response: AxiosResponse<any>,
		newProperties: Partial<IComponent>,
		currentProperties: Partial<IComponent>,
	): void {}

	get storeObject() {
		return this.store
	}
}
