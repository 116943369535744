import { GetterTree } from 'vuex'
import { IState, Namespaced } from '@/store'
import { IImportOptions, IImportProduct, IcsvErrorsObject, importCompleted } from '@/interfaces'
import { IImportStoreState } from '.'

export type ImportGettersFunctions<S = IImportStoreState> = {
	options: (state: S) => IImportOptions
	parsedCsv: (state: S) => IImportProduct[]
	parsedCsvName: (state: S) => string | undefined
	csvAlreadyValidated: (state: S) => boolean
	errors: (state: S) => IcsvErrorsObject | undefined
	completed: (state: S) => importCompleted | undefined
}

export type ImportGetters = { [K in keyof ImportGettersFunctions]: ReturnType<ImportGettersFunctions[K]> }

export type NamespacedImportGetter = Namespaced<ImportGetters, 'productImport'>

const getters: GetterTree<IImportStoreState, IState> & ImportGettersFunctions = {
	options: (state) => state.options,
	parsedCsv: (state) => state.parsedCsv,
	parsedCsvName: (state) => state.parsedCsvName,
	csvAlreadyValidated: (state) => state.csvAlreadyValidated,
	errors: (state) => state.errors,
	completed: (state) => state.completed,
}

export default getters
