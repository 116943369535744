import { BaseStoreCommand } from '../../BaseStoreCommand'
import { ActionContext } from 'vuex'
import { IProposalStoreState } from '@/store/modules/proposal'
import { IState } from '@/store'
import { AllBlockTypes, IBlock, IProposalPage } from '@/interfaces'
import { PageManager } from '@/commands/PageManager/PageManager'
import { ProposalActionContext } from '@/store/modules/proposal/actions'
import { BlockContainerManager } from '@/commands/Block/Manager/BlockContainerManager'
import { BaseCommand } from '@/commands/BaseCommand'

export class RemoveBlockCommand extends BaseCommand {
	private blockData: IBlock | undefined

	constructor(
		private blockContainerManager: BlockContainerManager,
		private blockIndex: number,
	) {
		super()
	}

	async execute() {
		this.blockContainerManager.reloadData()
		this.blockData = await this.blockContainerManager.deleteBlock(this.blockIndex)
	}

	async undo() {
		if (this.blockData) {
			this.blockContainerManager.reloadData()
			await this.blockContainerManager.createBlock([{ ...this.blockData, order: this.blockIndex }], this.blockIndex)
		}
	}
}
