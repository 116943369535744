import { IServerTag, ITag } from '@/interfaces/tags'
import { Languages } from '@/interfaces/languageTypes'
import { OffriHTTP } from '@/axiosRequestFunctions'

export function transformIServerTagToITag(serverTag: IServerTag[], language: Languages): ITag[] {
	return serverTag.map((value) => ({ ...value, label: value.label[language] }))
}

export function getTagsFromServer(workspaceId: number, proposalId: string) {
	return OffriHTTP.get<IServerTag[]>(`workspaces/${workspaceId}/proposals/${proposalId}/tags`)
}

export function getClientTagsFromServer(proposalId: string) {
	return OffriHTTP.get<IServerTag[]>(`proposals/${proposalId}/tags`)
}

export function getEmptyTags() {
	return OffriHTTP.get<IServerTag[]>('tags')
}
