import { BaseStoreCommand } from '../BaseStoreCommand'
import { IContactPerson } from '../../interfaces'
import { ClientActionContext } from '../../store/modules/clients/actions'
import { OffriHTTP } from '@/axiosRequestFunctions'

export class CreateContactPersonCommand extends BaseStoreCommand<ClientActionContext> {
	public undoable = false
	private contactPersonId?: number

	constructor(
		protected readonly store: ClientActionContext,
		private readonly clientId: number,
		private readonly body: IContactPerson,
	) {
		super(store)
	}

	async execute() {
		if (this.store.rootState.workspace) {
			this.store.dispatch('setSavingState', 'saving', { root: true })
			await OffriHTTP.post<IContactPerson>(
				`workspaces/${this.store.rootState.workspace.id}/clients/${this.clientId}/contact-persons`,
				this.body,
			).then((res) => {
				this.contactPersonId = res.data.id
				this.store.commit('addContactPerson', { clientId: this.clientId, contactPerson: res.data })
				this.store.dispatch('setSavingState', new Date(), { root: true })
			})
		}
	}

	async undo() {}
}
