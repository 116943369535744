import { ActionTree } from 'vuex'
import { IDashboardProposal, IUserWorkspace } from '@/interfaces'
import { AugmentedActionContext, IState, Namespaced } from '@/store'
import { IProposalsStoreState } from '.'
import { OffriHTTP } from '@/axiosRequestFunctions'
import { UnarchiveProposalCommand } from '../../../commands/ProposalCommands/UnarchiveProposalCommand'
import { ArchiveProposalCommand } from '../../../commands/ProposalCommands/ArchiveProposalCommand'
import { DeleteProposalCommand } from '../../../commands/ProposalCommands/DeleteProposalCommand'
import {
	CreateProposalCommand,
	ICreateProposalCommandPayload,
} from '../../../commands/ProposalCommands/CreateProposalCommand'
import { ProposalsGetters } from './getters'
import { ProposalsMutations } from './mutations'
import {
	DuplicateProposalCommand,
	IDuplicateProposalCommand,
} from '@/commands/ProposalCommands/DuplicateProposalCommand'
import { AxiosResponse } from 'axios'

export type ProposalsActionContext = AugmentedActionContext<
	IProposalsStoreState,
	ProposalsMutations,
	ProposalsGetters,
	ProposalsActions
>

export interface ProposalsActions {
	loadProposals(context: ProposalsActionContext): Promise<void>
	loadProposalsReadyForReview(context: ProposalsActionContext): Promise<void>
	loadVisits(context: ProposalsActionContext): void
	createProposal(
		injectee: ProposalsActionContext,
		payload: ICreateProposalCommandPayload & { navigateWhenCreated?: boolean },
	): Promise<void>
	duplicateProposal(injectee: ProposalsActionContext, payload: IDuplicateProposalCommand): Promise<AxiosResponse>
	archiveProposal(injectee: ProposalsActionContext, payload: string): void
	unarchiveProposal(injectee: ProposalsActionContext, payload: string): void
	deleteProposal(injectee: ProposalsActionContext, payload: string): void
	loadClientProposals(injectee: ProposalsActionContext, payload: number): void
}

export type NamespacedProposalsActions = Namespaced<ProposalsActions, 'proposals'>

const actions: ActionTree<IProposalsStoreState, IState> & ProposalsActions = {
	async duplicateProposal(injectee: ProposalsActionContext, payload) {
		return await injectee.dispatch('execute', new DuplicateProposalCommand(injectee, payload), { root: true })
	},
	async createProposal(injectee, payload) {
		const { navigateWhenCreated, ...proposal } = payload
		await injectee.dispatch('execute', new CreateProposalCommand(injectee, proposal, navigateWhenCreated), {
			root: true,
		})
	},
	async deleteProposal(injectee, proposalId) {
		injectee.dispatch('execute', new DeleteProposalCommand(injectee, proposalId), { root: true })
	},
	async archiveProposal(injectee, proposalId) {
		injectee.dispatch('execute', new ArchiveProposalCommand(injectee, proposalId), { root: true })
	},
	async unarchiveProposal(injectee, proposalId) {
		injectee.dispatch('execute', new UnarchiveProposalCommand(injectee, proposalId), { root: true })
	},
	async loadProposals({ rootState, commit, state }) {
		await OffriHTTP.get(`/workspaces/${(rootState.workspace as IUserWorkspace).id}/proposals`).then((res) => {
			commit('mutateProposals', res.data)
		})
	},
	async loadProposalsReadyForReview({ rootState, commit }) {
		await OffriHTTP.get(`/workspaces/${(rootState.workspace as IUserWorkspace).id}/proposals/reviews`).then((res) => {
			commit('mutateProposalsReadyForReview', res.data)
		})
	},
	async loadVisits({ rootState, commit }) {
		OffriHTTP.get<Array<{ proposalId: string; amount: number }>>(
			`workspaces/${(rootState.workspace as IUserWorkspace).id}/proposals/visits`,
		).then((res) => {
			commit('mutateVisits', res.data)
		})
	},
	async loadClientProposals({ rootState, commit, state }, clientId: number) {
		OffriHTTP.get<IDashboardProposal[]>(
			`/workspaces/${(rootState.workspace as IUserWorkspace).id}/clients/${clientId}/proposals`,
		).then((res) => {
			const proposalIds = res.data.map((prop) => prop.id)
			commit('mutateProposals', [...state.proposals.filter((prop) => !proposalIds.includes(prop.id)), ...res.data])
		})
	},
}

export default actions
