import axios from 'axios'
import { OffriVideo } from '@/interfaces/infoMenu'

export async function getAllFilteredVideos(
	receivedVideos: any,
	pagePath: string,
	blankStateVideo: boolean = false,
	activeFullscreenModal: string | null,
) {
	try {
		const filteredVideos: OffriVideo[] = []

		for (let video of receivedVideos) {
			if (video.acf.blank_state_video == blankStateVideo) {
				let videoData: OffriVideo = {
					id: video.id,
					title: video.title.rendered,
					header: {
						video_vimeo_id: video.acf.header.video_vimeo_id,
						image: video.acf.header.image,
					},
					pages: video.acf.pages,
					order: video.menu_order,
				}

				if (blankStateVideo) {
					if (video.acf.page == pagePath) {
						filteredVideos.push(videoData)
					}
				} else {
					if (activeFullscreenModal) {
						if (activeFullscreenModal && video.acf.pages.includes(activeFullscreenModal)) {
							filteredVideos.push(videoData)
						}
					} else if (
						video.acf.pages.includes(pagePath) ||
						(pagePath.startsWith('/proposal/') && video.acf.pages.includes('/proposal')) ||
						(pagePath.startsWith('/account/') && video.acf.pages.includes('/account'))
					) {
						filteredVideos.push(videoData)
					}
				}
			}
		}

		return filteredVideos.sort((a, b) => a.order - b.order)
	} catch (error) {
		console.error(error)
		return []
	}
}

export async function getAllVideos() {
	try {
		const allVideos = []

		const response = await axios.get(
			`${process.env.VUE_APP_OFFRI_WORDPRESS_URL}/wp-json/wp/v2/videos?per_page=100&order=asc`,
		)
		const videos = response.data
		const pages = parseInt(response.headers['x-wp-totalpages'])

		allVideos.push(...videos)

		if (pages > 1) {
			for (let i = 2; i <= pages; i++) {
				const response = await axios.get(
					`${process.env.VUE_APP_OFFRI_WORDPRESS_URL}/wp-json/wp/v2/videos?per_page=100&order=asc&page=${i}`,
				)
				const items = response.data
				allVideos.push(...items)
			}
		}

		return allVideos
	} catch (error) {
		console.error(error)
		return []
	}
}
