import { BaseStoreCommand } from '../BaseStoreCommand'
import { IClient } from '../../interfaces'
import router from '../../router'
import { ClientActionContext } from '../../store/modules/clients/actions'
import { OffriHTTP } from '@/axiosRequestFunctions'

export type CreateClientCommandPayload = Partial<IClient> | null

export class CreateClientCommand extends BaseStoreCommand<ClientActionContext> {
	public undoable = false
	private clientId?: number

	constructor(
		protected readonly store: ClientActionContext,
		private readonly payload: CreateClientCommandPayload,
		private readonly navigateWhenCreated: boolean,
	) {
		super(store)
	}

	async execute() {
		if (this.store.rootState.workspace) {
			await OffriHTTP.post<IClient>(`workspaces/${this.store.rootState.workspace.id}/clients`, this.payload).then(
				(res) => {
					if (this.navigateWhenCreated) router.push({ name: 'client', params: { id: String(res.data.id) } })
					this.clientId = res.data.id
					this.store.commit('addClient', { ...res.data, contactPersons: [] })
				},
			)
		}
	}

	async undo() {}
}
