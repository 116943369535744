import { ActionTree } from 'vuex'
import {
	AllBlockTypes,
	EBlockDirection,
	IBlock,
	IBlockView,
	IComponentBlock,
	IProposal,
	IProposalForm,
	IProposalPage,
	IProposalVisit,
} from '@/interfaces'
import { IProposalStoreState } from '@/store/modules/proposal'
import { AugmentedActionContext, IState, Namespaced } from '@/store'
import { UpdateProposalCommand } from '../../../commands/ProposalCommands/UpdateProposalCommand'
import { CreateBlockCommand } from '../../../commands/Block/Commands/CreateBlockCommand'
import { RemoveBlockCommand } from '../../../commands/Block/Commands/RemoveBlockCommand'
import { ProposalMutations } from '@/store/modules/proposal/mutations'
import { ProposalGetters } from '@/store/modules/proposal/getters'
import { OffriHTTP } from '@/axiosRequestFunctions'
import { ChangeBlockPositionCommand } from '@/commands/Block/Commands/ChangeBlockPositionCommand'
import { Vue } from 'vue-property-decorator'
import { DuplicateBlockCommand } from '@/commands/Block/Commands/DuplicateBlockCommand'
import { DecoupleComponentBlockCommand } from '@/commands/Block/Commands/DecoupleComponentBlockCommand'
import { ChangePagePropertiesCommand } from '@/commands/PageCommands/ChangePagePropertiesCommand'
import { Exact } from '@/interfaces/exact'
import { AddPageCommand } from '@/commands/PageCommands/AddPageCommand'
import { RemovePageCommand } from '@/commands/PageCommands/RemovePageCommand'
import { MovePageCommand } from '@/commands/PageCommands/MovePageCommand'
import { ChangePageBlockPropertiesCommand } from '@/commands/PageCommands/ChangePageBlockPropertiesCommand'
import { PageManager } from '@/commands/PageManager/PageManager'
import { Languages } from '@/interfaces/languageTypes'
import { getClientTagsFromServer, getTagsFromServer, transformIServerTagToITag } from '@/functions/tagFunctions'
import { ChangeBlockPageCommand } from '@/commands/Block/Commands/ChangeBlockPageCommand'
import { AxiosResponse } from 'axios'

export type ProposalActionContext = AugmentedActionContext<
	IProposalStoreState,
	ProposalMutations,
	ProposalGetters,
	ProposalActions
>

type C = ProposalActionContext

export interface ProposalActions {
	addPage(
		injectee: C,
		payload: {
			order?: number
			properties?: Partial<Omit<IProposalPage, 'id' | 'blocks' | 'proposalId' | 'accept'>>
		},
	): Promise<IProposalPage>
	removePage(injectee: C, payload: { pageId: number }): Promise<void>
	movePage(injectee: C, payload: { pageId: number; oldIndex: number; newIndex: number }): Promise<void>
	loadVisits(injectee: C): Promise<void>
	loadViews(injectee: C): Promise<void>
	updateProposal(
		injectee: C,
		payload: { newValue: Partial<IProposalForm>; oldValue: Partial<IProposalForm>; onlyLocal?: boolean },
	): Promise<AxiosResponse>
	createBlock(
		injectee: C,
		payload: { proposalPageId: number; blocks: Array<{ [key: string]: any }>; order?: number },
	): Promise<AllBlockTypes[]>
	removeBlock(injectee: C, payload: { proposalPageId: number; order: number }): Promise<void>
	changeBlockPosition(
		injectee: C,
		payload: {
			currentBlockIndex: number
			newBlockIndex: number
			proposalPageId: number
			refs: { [key: string]: Vue | Vue[] | Element | HTMLElement }
		},
	): Promise<void>
	changeBlockPage(
		injectee: C,
		payload: {
			newBlockPage: IProposalPage
			block: AllBlockTypes
			oldBlockOrder: number
			direction: EBlockDirection
			refs: { [key: string]: Vue | Vue[] | Element | HTMLElement }
		},
	): Promise<void>
	changBlockProperties<NV extends Partial<AllBlockTypes>, OV extends Exact<AllBlockTypes, NV>>(
		injectee: C,
		payload: { block: IBlock; newProperties: NV; oldProperties?: OV; undoAble?: boolean; componentBlock?: IBlock },
	): Promise<void>
	duplicateBlock(injectee: C, payload: { proposalPageId: number; blockIndex: number }): Promise<void>
	decoupleComponentBlock(injectee: C, payload: { proposalPageId: number; blockIndex: number }): Promise<void>
	changePageProperties<NV extends Partial<Omit<IProposalPage, 'blocks'>>, OV extends Exact<IProposalPage, NV>>(
		injectee: C,
		payload: { proposalPageId: number; newProperties: NV; oldProperties: OV },
	): Promise<void>
	setProposal(injectee: C, payload: IProposal): void
	setViewMode(injectee: C, payload: boolean): void
	reset(injectee: C): void
	loadTags(injectee: C, payload: { workspaceId?: number; proposalId: string; clientPage: boolean }): void
}

export type NamespacedProposalActions = Namespaced<ProposalActions, 'proposal'>

const actions: ActionTree<IProposalStoreState, IState> & ProposalActions = {
	async addPage(injectee, payload) {
		const command = new AddPageCommand(injectee, payload.order, payload.properties)
		return await injectee.dispatch('execute', command, { root: true })
	},
	async removePage(injectee, payload) {
		const command = new RemovePageCommand(injectee, payload.pageId)
		await injectee.dispatch('execute', command, { root: true })
	},
	async movePage(injectee, payload): Promise<void> {
		const command = new MovePageCommand(injectee, payload.pageId, payload.newIndex, payload.oldIndex)
		await injectee.dispatch('execute', command, { root: true })
	},
	async changePageProperties(injectee, payload): Promise<void> {
		const command = new ChangePagePropertiesCommand(
			injectee,
			payload.proposalPageId,
			payload.newProperties,
			payload.oldProperties,
		)
		await injectee.dispatch('execute', command, { root: true })
	},
	async changeBlockPosition(injectee, payload): Promise<void> {
		const manager = new PageManager(injectee, payload.proposalPageId)
		const command = new ChangeBlockPositionCommand(
			manager,
			payload.currentBlockIndex,
			payload.newBlockIndex,
			payload.refs,
		)
		await injectee.dispatch('execute', command, { root: true })
	},
	async changeBlockPage(injectee, payload): Promise<void> {
		const manager = new PageManager(injectee, payload.block.pageId ? payload.block.pageId : -1)
		const newPageManager = new PageManager(injectee, payload.newBlockPage.id)
		const command = new ChangeBlockPageCommand(
			manager,
			payload.newBlockPage,
			payload.block,
			payload.oldBlockOrder,
			payload.direction,
			payload.refs,
		)
		await injectee.dispatch('execute', command, { root: true })
		await manager.reloadBlockContainer()
		await newPageManager.reloadBlockContainer()
	},
	async changBlockProperties(injectee, payload): Promise<void> {
		const command = new ChangePageBlockPropertiesCommand(
			injectee,
			payload.block,
			payload.newProperties,
			payload.undoAble,
			payload.oldProperties,
			payload.componentBlock,
		)
		injectee.dispatch('execute', command, { root: true })
	},
	async duplicateBlock(injectee, payload): Promise<void> {
		const pageManager = new PageManager(injectee, payload.proposalPageId)
		const command = new DuplicateBlockCommand(pageManager, payload.blockIndex)
		await injectee.dispatch('execute', command, { root: true })
	},
	async decoupleComponentBlock(injectee, payload): Promise<void> {
		const pageManager = new PageManager(injectee, payload.proposalPageId)
		const command = new DecoupleComponentBlockCommand(pageManager, payload.blockIndex)
		await injectee.dispatch('execute', command, { root: true })
	},
	async loadVisits({ state, commit, rootState }) {
		if (rootState.workspace && state.proposal) {
			OffriHTTP.get<IProposalVisit[]>(
				`/workspaces/${rootState.workspace.id}/proposals/${state.proposal.id}/visits`,
			).then((res) => {
				commit('mutateVisits', res.data)
			})
		}
	},
	async loadViews({ state, commit, rootState }) {
		if (rootState.workspace && state.proposal) {
			OffriHTTP.get<IBlockView[]>(`/workspaces/${rootState.workspace.id}/proposals/${state.proposal.id}/views`).then(
				(res) => {
					commit('mutateViews', res.data)
				},
			)
		}
	},
	async updateProposal(injectee, payload) {
		return injectee.dispatch(
			'execute',
			new UpdateProposalCommand(injectee, payload.newValue, payload.oldValue, payload.onlyLocal),
			{
				root: true,
			},
		)
	},
	async createBlock(injectee, payload) {
		const pageManager = new PageManager(injectee, payload.proposalPageId)
		const command = new CreateBlockCommand(pageManager, payload.blocks, payload.order)
		return await injectee.dispatch('execute', command, { root: true })
	},

	async removeBlock(injectee, payload) {
		const pageManager = new PageManager(injectee, payload.proposalPageId)
		const command = new RemoveBlockCommand(pageManager, payload.order)
		await injectee.dispatch('execute', command, { root: true })
	},
	setProposal({ state, commit, rootState }, payload: IProposal) {
		commit('mutateProposal', payload)
	},
	setViewMode({ commit }, payload: boolean) {
		commit('mutateViewMode', payload)
	},
	reset({ commit }) {
		commit('reset')
	},
	async loadTags(injectee, payload) {
		let language: Languages = Languages.EN
		let serverRequest = getClientTagsFromServer(payload.proposalId)
		if (!payload.clientPage && payload.workspaceId) {
			language = injectee.rootGetters.user != null ? injectee.rootGetters.user.language : Languages.EN
			serverRequest = getTagsFromServer(payload.workspaceId, payload.proposalId)
		}
		injectee.commit('mutateTags', await serverRequest.then((res) => transformIServerTagToITag(res.data, language)))
	},
}

export default actions
