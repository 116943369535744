import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store/'
import './registerServiceWorker'

import PortalVue from 'portal-vue'
import VueObserveVisibility from 'vue-observe-visibility'
import VueLazyload from 'vue-lazyload'
import Vuelidate from 'vuelidate'
import VueVimeoPlayer from 'vue-vimeo-player'
import mixpanel from 'mixpanel-browser'
import { fontDirective, unbindfontDirective } from './directives/font-directive'
import { webpDirective } from './directives/webp-directive'

Vue.use(PortalVue)

Vue.use(VueObserveVisibility)

Vue.use(VueLazyload, {
	attempt: 1, // Since we use errors to not load a missing webp, multiple attempts are not needed
})

Vue.directive('font', {
	bind: fontDirective,
	update: fontDirective,
	unbind: unbindfontDirective,
})
Vue.directive('webp', webpDirective)

Vue.use(Vuelidate)

Vue.use(VueVimeoPlayer)

Vue.config.productionTip = false

export const bus = new Vue()

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')
const mixPanelApiToken = process.env.VUE_APP_MIXPANEL_PROJECT_TOKEN
const inDebug = process.env.NODE_ENV === 'development' || process.env.VUE_APP_STAGING === 'true'
if (mixPanelApiToken) {
	mixpanel.init(mixPanelApiToken, { debug: inDebug, ignore_dnt: true, api_host: 'https://mp.offri.app/' })
} else {
	console.error("Mixpanel's api token is undefined")
}
try {
	window.addEventListener('beforeunload', (ev) => {
		mixpanel.track('App Closed')
	})
} catch (e) {}
