import mutations from './mutations'
import getters from './getters'
import actions from './actions'
import { IBaseClient, IClient, IConditions } from '../../../interfaces'

export interface IClientsStoreState {
	clients: (IClient | IBaseClient)[]
	loadedAt?: Date
	searchString: string
	conditions: { [n: number]: IConditions[] }
}

const state: IClientsStoreState = {
	clients: [],
	loadedAt: undefined,
	searchString: '',
	conditions: {},
}

export const clientState = state

export default {
	namespaced: true,
	state,
	actions,
	getters,
	mutations,
}
