import { ICategory, IComponent } from '@/interfaces'
import getters from '@/store/modules/component/getters'
import mutations from '@/store/modules/component/mutations'
import actions from '@/store/modules/component/actions'
import { ITag } from '@/interfaces/tags'

export interface IComponentStoreState {
	component?: IComponent
	languageVersions?: IComponent[]
	categories?: ICategory[]
	canBeProduct?: { containsProduct: boolean; isInProduct: boolean; canBeProduct: boolean }
	tags: ITag[]
}

const state: IComponentStoreState = {
	component: undefined,
	languageVersions: undefined,
	categories: undefined,
	canBeProduct: undefined,
	tags: [],
}
export const componentState = state

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
