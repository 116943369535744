import { BaseStoreCommand } from '../../BaseStoreCommand'
import { ComponentActionContext } from '@/store/modules/component/actions'
import { OffriHTTP } from '@/axiosRequestFunctions'

export class LoadComponentCommand extends BaseStoreCommand<ComponentActionContext> {
	constructor(
		store: ComponentActionContext,
		private componentId: number,
	) {
		super(store)
		this.undoable = false
	}

	async execute(): Promise<any> {
		await OffriHTTP.get(
			`/workspaces/${this.store.rootGetters.workspace?.id}/catalog/components/${this.componentId}`,
		).then(async (res) => {
			const { name, product, category, ...rest } = res.data
			this.store.commit('mutateComponent', res.data)
		})
	}

	async undo(): Promise<any> {}
}
