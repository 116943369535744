import axios from 'axios'
import { KnowledgeBaseArticle } from '@/interfaces/knowledgeBaseArticle'
import { Route } from 'vue-router'

export async function getAllFilteredArticles(articles: any[], route: Route, activeFullscreenModal: string | null) {
	try {
		const filteredArticles: KnowledgeBaseArticle[] = []

		for (let article of articles) {
			if (activeFullscreenModal) {
				if (activeFullscreenModal && article.acf.tag.includes(activeFullscreenModal)) {
					filteredArticles.push({
						id: article.id,
						title: article.title.rendered,
						description: article.acf.knowledgebank_description,
						link: article.link,
						tags: article.acf.tag,
						order: article.menu_order,
						icon: await getIconUrl(article.acf.knowledgebank_icon),
					})
				}
			} else if (
				article.acf.tag.includes(route.path) ||
				(route.path.startsWith('/proposal/') && article.acf.tag.includes('/proposal')) ||
				(route.path.startsWith('/account/') && article.acf.tag.includes('/account'))
			) {
				filteredArticles.push({
					id: article.id,
					title: article.title.rendered,
					description: article.acf.knowledgebank_description,
					link: article.link,
					tags: article.acf.tag,
					order: article.menu_order,
					icon: await getIconUrl(article.acf.knowledgebank_icon),
				})
			}
		}

		return filteredArticles.sort((a, b) => a.order - b.order)
	} catch (error) {
		console.error(error)
		return []
	}
}

async function getIconUrl(iconId: number) {
	if (iconId) {
		const response = await axios.get(`${process.env.VUE_APP_OFFRI_WORDPRESS_URL}/wp-json/wp/v2/media/${iconId}`)
		if (response.status === 200) {
			return response.data.source_url
		}
	}

	return null
}

export async function getAllArticles() {
	try {
		const allArticles = []

		const response = await axios.get(
			`${process.env.VUE_APP_OFFRI_WORDPRESS_URL}/wp-json/wp/v2/support?per_page=100&order=asc`,
		)
		const items = response.data
		const pages = parseInt(response.headers['x-wp-totalpages'])

		allArticles.push(...items)

		if (pages > 1) {
			for (let i = 2; i <= pages; i++) {
				const response = await axios.get(
					`${process.env.VUE_APP_OFFRI_WORDPRESS_URL}/wp-json/wp/v2/support?per_page=100&order=asc&page=${i}`,
				)
				const items = response.data
				allArticles.push(...items)
			}
		}

		return allArticles
	} catch (error) {
		console.error(error)
		return []
	}
}
