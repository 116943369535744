import { ActionTree } from 'vuex'
import { IImportStoreState } from '@/store/modules/import'
import { AugmentedActionContext, IState, Namespaced } from '@/store'
import { ImportMutations } from '@/store/modules/import/mutations'
import { ImportGetters } from '@/store/modules/import/getters'
import { EImageBlockCompositions, ImportLayouts } from '@/interfaces'
import { OffriHTTP } from '@/axiosRequestFunctions'

export type ImportActionContext = AugmentedActionContext<
	IImportStoreState,
	ImportMutations,
	ImportGetters,
	ImportActions
>
type C = ImportActionContext
export interface ImportActions {
	runImport(context: C): Promise<void>
	applyLayoutOption(context: C): Promise<void>
}

export type NamespacedImportActions = Namespaced<ImportActions, 'productImport'>

const actions: ActionTree<IImportStoreState, IState> & ImportActions = {
	async runImport({ getters, dispatch, rootGetters, commit }) {
		const { parsedCsv, errors, options } = getters
		if (parsedCsv.length > 0 && (!errors || (errors && Object.keys(errors).length === 0))) {
			await dispatch('applyLayoutOption', undefined)
			const importResponse = await OffriHTTP.post(
				`/workspaces/${rootGetters.workspace?.id}/catalog/products/import`,
				{
					update: options.update,
					overwriteContent: options.overwriteContent,
					createCategories: options.createCategories,
					dryRun: false,
					products: getters.parsedCsv,
				},
				{
					timeout: 60000,
				},
			)
			commit('mutateCompleted', importResponse.data)
		}
	},

	async applyLayoutOption({ getters, commit }) {
		const { parsedCsv, options } = getters
		switch (options.layout) {
			case ImportLayouts.NONE:
				commit(
					'mutateParsedCsv',
					parsedCsv.map((product) => ({
						...product,
						description: undefined,
						imageUrl: undefined,
						imageComposition: undefined,
					})),
				)
				break

			case ImportLayouts.ONLY_TEXT:
				commit(
					'mutateParsedCsv',
					parsedCsv.map((product) => ({
						...product,
						imageUrl: undefined,
						imageComposition: undefined,
					})),
				)
				break

			case ImportLayouts.QUARTER_SCREEN:
			case ImportLayouts.HALF_SCREEN:
			case ImportLayouts.FULL_WIDTH:
				commit(
					'mutateParsedCsv',
					parsedCsv.map((product) => ({
						...product,
						imageComposition: product.imageComposition || (options.layout as unknown as EImageBlockCompositions),
					})),
				)
				break

			default:
				break
		}
	},
}

export default actions
