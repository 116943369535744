import { DirectiveFunction } from 'vue'
import { memoize } from 'lodash'
import { IFont } from '@/interfaces'
import { BunnyFontHTTP } from '@/axiosRequestFunctions'

const _getFontUrls = (font: IFont, weights: string[]): { weight: string; url: string }[] => {
	const { name, subset, style, url } = font
	return url
		? [
				{
					weight: '400',
					url: `url(${font.url})`,
				},
		  ]
		: weights.map((weight: string) => {
				return {
					weight: weight,
					url: `url(${process.env.VUE_APP_BUNNY_FONT_API_URL}/${name}/files/${name}-${subset}-${weight}-${style}.woff2)`,
				}
		  })
}

export const getFontUrls = memoize(_getFontUrls)

const _loadFont = async (url: { weight: string; url: string }, font: IFont) => {
	try {
		const fontName = sanitizeFontName(`${font.name}-${font.style}-${font.type}`)
		const fontFace = new FontFace(fontName, url.url, {
			weight: url.weight,
			style: font.style,
		})
		await fontFace.load()
		document.fonts.add(fontFace)
	} catch (error) {
		console.error("Font couldn't be loaded:", error)
	}
}

const _loadAllFonts = async (font: IFont) => {
	try {
		const weights = await loadWeights(font)
		const fontUrls = getFontUrls(font, weights)
		await Promise.all(fontUrls.map((url) => _loadFont(url, font)))
	} catch (error) {
		console.error("Fonts couldn't be loaded:", error)
	}
}

// Function which makes sure no error is thrown @ fontFace.load for special characters
export const sanitizeFontName = (fontName: string): string => fontName.replace(/[^\w\s-]/g, '').replace(/\s/g, '-')

export const loadAllFonts = memoize(_loadAllFonts)
export const loadFont = memoize(_loadFont)

const _loadWeights = async (font: IFont): Promise<string[]> => {
	try {
		const res = await BunnyFontHTTP.get('/list')
		return res.data[font.name]?.weights || []
	} catch (error) {
		console.error("Weights couldn't be loaded:", error)
		return []
	}
}

export const loadWeights = memoize(_loadWeights)

const applyStyles = (element: HTMLElement, font: IFont, fontOnly: boolean = false, multiplier: number = 1) => {
	const size: string = font.size ? (Number(font.size) * multiplier).toString() : font.size
	if (!fontOnly) {
		element.style.fontWeight = font.weight?.toString()
		element.style.fontSize = size + 'px'
		element.style.textAlign = font?.align
	}
	element.style.fontFamily = sanitizeFontName(`${font.name}-${font.style}-${font.type}`)
}

export interface FontDirectiveValue {
	font?: IFont
	fontOnly?: boolean
	multiplier?: number
}

export const fontDirective: DirectiveFunction = async (htmlElement, binding) => {
	const { oldValue, value } = binding
	const { font, fontOnly, multiplier } = binding.value as FontDirectiveValue

	if (JSON.stringify(oldValue) === JSON.stringify(value)) return

	if (oldValue != value) {
		if (oldValue?.font && !font) {
			htmlElement.style.fontWeight = ''
			htmlElement.style.fontSize = ''
			htmlElement.style.textAlign = ''
			htmlElement.style.fontFamily = ''
		}
	}

	if (font) {
		if (font.url && font.type) {
			await loadFont({ weight: '400', url: `url(${font.url})` }, font)
			applyStyles(htmlElement, font, fontOnly, multiplier)
		} else if (font.name && font.subset && font.weight != null && font.style) {
			await loadAllFonts(font)
			applyStyles(htmlElement, font, fontOnly, multiplier)
		}
	}
}

export const unbindfontDirective: DirectiveFunction = (htmlElement) => {
	htmlElement.style.fontWeight = ''
	htmlElement.style.fontSize = ''
	htmlElement.style.textAlign = ''
	htmlElement.style.fontFamily = ''
}
