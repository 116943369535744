import { BaseCommand } from '@/commands/BaseCommand'
import { AllBlockTypes } from '@/interfaces'
import { OffriHTTP } from '@/axiosRequestFunctions'
import { AxiosError, AxiosResponse } from 'axios'

export abstract class UpdatePropertiesCommandTemplate<
	TotalObjectType,
	serverResponse = any,
	ServerAcceptedObjectType = TotalObjectType,
> extends BaseCommand {
	/*
	private pageId:number
	private blockId:number
	private pageOrder:number

 */
	private oldProperties: Partial<TotalObjectType>

	constructor(
		protected readonly totalObject: TotalObjectType,
		private newProperties: Partial<TotalObjectType>,
		oldProperties?: Partial<TotalObjectType>,
		public undoable = true,
	) {
		super()
		//Check if there are defined old properties
		if (oldProperties !== undefined && undoable) {
			this.oldProperties = oldProperties
		} else {
			//If there are no defined properties take the old properties from the total object to create the oldProperties
			this.oldProperties = (Object.keys(this.newProperties) as (keyof Partial<TotalObjectType>)[]).reduce(
				(previousValue, currentValue) => {
					previousValue[currentValue] = totalObject[currentValue]
					return previousValue
				},
				{} as Partial<TotalObjectType>,
			)
		}
	}

	async execute(): Promise<any> {
		await this.changeProperties(this.newProperties, this.oldProperties)
	}

	async undo(): Promise<any> {
		await this.changeProperties(this.oldProperties, this.newProperties)
	}

	abstract changeStore(properties: Partial<TotalObjectType>): void

	abstract get storeObject(): {
		dispatch: (a: 'setSavingState', b: 'saving' | number, c: { root: true }) => Promise<void>
	}

	abstract get putUrl(): string

	abstract serverRequestSucceed(
		response: AxiosResponse<serverResponse>,
		newProperties: Partial<TotalObjectType>,
		currentProperties: Partial<TotalObjectType>,
	): void

	abstract serverRequestFailedCallback(
		err: AxiosError,
		newProperties: Partial<TotalObjectType>,
		currentProperties: Partial<TotalObjectType>,
	): void

	serverRequestFailed(
		err: AxiosError,
		newProperties: Partial<TotalObjectType>,
		currentProperties: Partial<TotalObjectType>,
	) {
		this.changeStore(currentProperties)
		this.serverRequestFailedCallback(err, newProperties, currentProperties)
	}

	transformPropertiesForServer(properties: Partial<TotalObjectType>): Partial<ServerAcceptedObjectType> {
		return properties as ServerAcceptedObjectType
	}

	async saveToServer(
		newProperties: Partial<TotalObjectType>,
		currentProperties: Partial<TotalObjectType>,
	): Promise<void> {
		await OffriHTTP.put<serverResponse>(this.putUrl, this.transformPropertiesForServer(newProperties))
			.then((res) => {
				this.serverRequestSucceed(res, newProperties, currentProperties)
			})
			.catch((e) => {
				this.serverRequestFailed(e, newProperties, currentProperties)
			})
	}

	async changeProperties(newProperties: Partial<TotalObjectType>, currentProperties: Partial<TotalObjectType>) {
		await this.storeObject.dispatch('setSavingState', 'saving', { root: true })
		this.changeStore(newProperties)
		await this.saveToServer(newProperties, currentProperties)
		await this.storeObject.dispatch('setSavingState', Date.now(), { root: true })
	}
}
