import { ProposalActionContext } from '@/store/modules/proposal/actions'
import { IProposalPage } from '@/interfaces'
import { cloneDeep } from 'lodash'
import { OffriHTTP } from '@/axiosRequestFunctions'
import { Vue } from 'vue-property-decorator'

export class ProposalPagesManager {
	private store: ProposalActionContext
	private pages: IProposalPage[]

	constructor(store: ProposalActionContext) {
		this.store = store
		if (this.store.state.proposal) {
			this.pages = cloneDeep(this.store.state.proposal?.pages)
		} else {
			throw new Error('Proposal is not defined')
		}
	}

	saveToStore() {
		this.store.commit('mutatePages', this.pages)
	}

	async addPage(order = 0, properties: Partial<Omit<IProposalPage, 'id' | 'blocks' | 'proposalId' | 'accept'>> = {}) {
		try {
			const res = await OffriHTTP.post<IProposalPage>(
				`/workspaces/${this.store.state.proposal?.workspaceId}/${
					this.store.state.proposal?.template ? 'templates' : 'proposals'
				}/${this.store.state.proposal?.id}/pages`,
				{ order, ...properties },
			)
			this.pages.splice(order, 0, res.data)
			this.setOrders()
			this.saveToStore()
			return res.data
		} catch (e) {
			console.log(e)
		}
	}

	async deletePage(pageId: number) {
		const pageIndex = this.pages.findIndex((pg) => {
			return pg.id === pageId
		})
		if (pageIndex > -1) {
			await OffriHTTP.delete(
				`/workspaces/${this.store.state.proposal?.workspaceId}/${
					this.store.state.proposal?.template ? 'templates' : 'proposals'
				}/${this.store.state.proposal?.id}/pages/${pageId}`,
			).then(() => {
				this.pages.splice(pageIndex, 1)
				this.setOrders()
				this.saveToStore()
			})
		}
	}

	async movePage(pageId: number, newIndex: number, oldIndex: number) {
		const newOrder = newIndex > oldIndex ? newIndex + 1 : newIndex
		let cutOut = this.pages.splice(oldIndex, 1)[0] // cut the element at index 'from'
		this.pages.splice(newIndex, 0, cutOut)
		this.setOrders()
		this.saveToStore()
		await OffriHTTP.patch(
			`/workspaces/${this.store.state.proposal?.workspaceId}/${
				this.store.state.proposal?.template ? 'templates' : 'proposals'
			}/${this.store.state.proposal?.id}/pages/${pageId}`,
			{ newOrder },
		).catch((reason) => {
			console.error(reason)
			let cutOut = this.pages.splice(newIndex, 1)[0] // cut the element at index 'from'
			this.pages.splice(oldIndex, 0, cutOut)
			this.setOrders()
			this.saveToStore()
		})
	}

	setOrders() {
		const amount = this.pages.length
		for (let i = 0; i < amount; i++) {
			this.pages[i].proposalOrder = i
		}
	}
}
