var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.pausedAfterTrial)?_c('nav',{staticClass:"nav",class:{ hidden: _vm.isFullscreen }},[_c('div',{on:{"mouseover":function($event){_vm.showHover = true},"mouseleave":function($event){_vm.showHover = false}}},[_c('router-link',{staticClass:"nav__logo",attrs:{"to":{ name: _vm.template ? 'templates' : 'dashboard' }}}),_c('AppHoverBar',{attrs:{"show":_vm.showHover,"isFullscreen":_vm.isFullscreen}})],1),_c('div',{staticClass:"nav__top",class:{ hidden: _vm.isFullscreen }},[_c('div',{staticClass:"nav__left"},[_c('portal-target',{staticClass:"nav__left",attrs:{"name":"navigation-left","transition":{
					functional: true,
					render: function render(h, context) {
						return h('transition', { props: { name: 'fade-fast', mode: 'out-in' } }, context.children)
					},
				}}})],1),_c('div',{staticClass:"nav__divider"}),(!_vm.isMobile())?_c('div',{staticClass:"nav__actions"},[_c('transition',{attrs:{"name":"fade"}},[_c('navigation-saving-state')],1),_c('portal-target',{attrs:{"name":"navigation-right","transition":{
					functional: true,
					render: function render(h, context) {
						return h('transition', { props: { name: 'fade-fast', mode: 'out-in' } }, context.children)
					},
				}}}),_c('div',{staticClass:"notifications-button",class:{ 'notifications-button--active': _vm.notificationAmount > 0 },attrs:{"tooltip":_vm.notificationTitleCopy,"tooltip-bottom":""},on:{"click":function($event){return _vm.$store.dispatch('setNotificationShade', !_vm.$store.getters.notificationShade)}}},[(_vm.notificationAmount > 0)?_c('span',{staticClass:"notifications-button__indicator"},[_vm._v(" "+_vm._s(_vm.notificationAmount >= 1000 ? '999+' : _vm.notificationAmount)+" ")]):_vm._e()]),(_vm.showTrialMessage)?_c('div',{staticClass:"nav__trial-message"},[_c('p',[_vm._v(" "+_vm._s(_vm.trialMessage)+" ")]),_c('router-link',{attrs:{"to":{ name: 'account.payment', params: { accountId: _vm.account.id } }}},[_c('button',{staticClass:"upgrade",class:{ 'upgrade--disabled': !_vm.isOwner },attrs:{"disabled":!_vm.isOwner,"tooltip":!_vm.isOwner && _vm.disabledUpgradeTooltip,"tooltip-left":""}},[_vm._v(" "+_vm._s(_vm.copy.trialMessage.upgrade)+" ")])])],1):_vm._e()],1):_vm._e(),(_vm.isMobile())?[_c('router-link',{staticClass:"sidebar__profile",attrs:{"to":{ name: 'profile' },"tooltip-right":""}},[_c('UserIcon',{attrs:{"user":_vm.user}})],1),_c('WorkspaceSelector',{staticClass:"sidebar__workspaces"})]:_vm._e()],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }