import { BaseStoreCommand } from '../BaseStoreCommand'
import { ProposalActionContext } from '@/store/modules/proposal/actions'
import { ProposalPagesManager } from '@/commands/PageManager/ProposalPagesManager'

export class MovePageCommand extends BaseStoreCommand<ProposalActionContext> {
	constructor(
		store: ProposalActionContext,
		private pageId: number,
		private newIndex: number,
		private oldIndex: number,
	) {
		super(store)
	}

	async execute(): Promise<void> {
		await new ProposalPagesManager(this.store).movePage(this.pageId, this.newIndex, this.oldIndex)
	}

	async undo(): Promise<void> {
		await new ProposalPagesManager(this.store).movePage(this.pageId, this.oldIndex, this.newIndex)
	}
}
