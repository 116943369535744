import { BaseStoreCommand } from '../../BaseStoreCommand'
import { ProposalActionContext } from '@/store/modules/proposal/actions'
import { PageManager } from '@/commands/PageManager/PageManager'
import { IProposalPage } from '@/interfaces'
import { BaseCommand } from '@/commands/BaseCommand'
import { BlockContainerManager } from '@/commands/Block/Manager/BlockContainerManager'

export class ChangeBlockPositionCommand extends BaseCommand {
	constructor(
		private blockContainerManager: BlockContainerManager,
		private currentBlockIndex: number,
		private newBlockIndex: number,
		private referenceObject: { [key: string]: Vue | Vue[] | Element | HTMLElement },
	) {
		super()
	}

	async execute(): Promise<void> {
		this.blockContainerManager.reloadData()
		await this.blockContainerManager.updateOrder(
			{ current: this.currentBlockIndex, new: this.newBlockIndex },
			this.referenceObject,
		)
	}

	async undo(): Promise<void> {
		this.blockContainerManager.reloadData()
		await this.blockContainerManager.updateOrder(
			{ current: this.newBlockIndex, new: this.currentBlockIndex },
			this.referenceObject,
		)
	}
}
