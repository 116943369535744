import mutations from './mutations'
import getters from './getters'
import actions from './actions'
import { IBlockView, IDashboardProposal, IProposal, IProposalVisit } from '@/interfaces'
import { ITag } from '@/interfaces/tags'

export interface IProposalStoreState {
	views: IBlockView[]
	visits: IProposalVisit[]
	proposals: IDashboardProposal[]
	proposal?: IProposal
	viewMode: boolean
	tags: ITag[]
	isClient: boolean
}

const state: IProposalStoreState = {
	views: [],
	visits: [],
	proposals: [],
	proposal: undefined,
	viewMode: false,
	tags: [],
	isClient: false,
}
export const proposalState = state
export default {
	namespaced: true,
	state,
	actions,
	getters,
	mutations,
}
