import { GetterTree } from 'vuex'
import { IState } from '@/store/index'
import { IActiveCondition, IProductVat, ISavingState, IUnit, IUser, IUserWorkspace, IWorkspace } from '../interfaces'
import { AllCopies, allLanguagesCopy } from '@/interfaces/languageTypes'

export type BaseGettersFunctions<S = IState> = {
	activeCopy: (state: S) => allLanguagesCopy
	allCopies: (state: S) => AllCopies
	subscriptions: (state: S) => any
	hideNav: (state: S) => boolean
	errors: (state: S) => any
	user: (state: S) => IUser | null
	vatOptions: (state: S) => IProductVat[]
	fullscreen: (state: S) => boolean
	loading: (state: S) => boolean
	savingState: (state: S) => ISavingState
	workspace: (state: S) => IUserWorkspace | null
	workspaces: (state: S) => IUserWorkspace[]
	notificationShade: (state: S) => boolean
	notifications: (state: S) => any
	notificationsAmount: (state: S) => number | null
	twoFactorRequiredError: (state: S) => boolean | null
	twoFactorRequiredByWorkspace: (state: S) => boolean | null
	fonts: (state: S) => IUnit[]
	units: (state: S) => IUnit[]
	isViewer: (state: S) => boolean
	activeFullscreenModal: (state: S) => string | null
}

export type BaseGetters = { [K in keyof BaseGettersFunctions]: ReturnType<BaseGettersFunctions[K]> }

const getters: GetterTree<IState, IState> & BaseGettersFunctions = {
	activeCopy: (state) => state.allCopies[state.activeLanguage],
	allCopies: (state) => state.allCopies,
	subscriptions: (state) => state.subscriptions,
	hideNav: (state) => state.hideNav,
	errors: (state) => state.errors,
	user: (state) => state.user,
	vatOptions: (state) => state.vatOptions,
	fullscreen: (state) => state.fullscreen,
	loading: (state) => state.loading,
	savingState: (state) => state.savingState,
	workspace: (state) => state.workspace,
	workspaces: (state) => (state.user ? state.user.workspaces : []),
	notificationShade: (state) => state.notificationShade,
	notifications: (state) => state.notifications,
	notificationsAmount: (state) => state.notificationsAmount,
	twoFactorRequiredError: (state) =>
		state.user && twoFactorRequired(state.user.workspaces) && !state.user.twoFactorEnabled,
	twoFactorRequiredByWorkspace: (state) => state.user && twoFactorRequired(state.user.workspaces),
	lastCommandInHistory: (state) => state.historyCommands[state.historyCommands.length - 1] || undefined,
	lastCommandInRedoHistory: (state) => state.redoHistoryCommands[state.redoHistoryCommands.length - 1] || undefined,
	fonts: (state) => state.fonts,
	units: (state) => state.units,
	isViewer: (state) => state.workspace?.role === 'viewer',
	activeFullscreenModal: (state) => state.activeFullscreenModal,
}

const twoFactorRequired = (workspaces: IUserWorkspace[]) => {
	return workspaces.reduce((acc, curr) => (acc ? acc : curr.twoFactorRequired), false)
}

export default getters
