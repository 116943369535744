import { GetterTree } from 'vuex'
import { IState, Namespaced } from '@/store'
import { IClientsStoreState } from '.'
import { IBaseClient, IClient, IConditions, IContactPerson } from '../../../interfaces'

export type ClientGettersFunctions<S = IClientsStoreState> = {
	clients: (state: S) => (IClient | IBaseClient)[]
	filteredClients: (state: S) => (IClient | IBaseClient)[]
	hasFilteredClients: (state: S) => boolean
	client: (state: S) => (id: number) => IClient | IBaseClient | undefined
	clientContactPersons: (state: S) => (clientId: number) => IContactPerson[]
	loadedAt: (state: S) => Date | undefined
	hasClients: (state: S) => boolean
	searchString: (state: S) => string
	clientConditions: (state: S) => (id: number) => IConditions[]
}

export type ClientGetters = { [K in keyof ClientGettersFunctions]: ReturnType<ClientGettersFunctions[K]> }

export type NamespacedClientGetter = Namespaced<ClientGetters, 'clients'>

const getters: GetterTree<IClientsStoreState, IState> & ClientGettersFunctions = {
	clients: (state) => state.clients,
	filteredClients: (state) => state.clients.filter(isFiltered(state)),
	hasFilteredClients: (state) => !!state.clients.filter(isFiltered(state)).length,
	hasClients: (state) => !!state.loadedAt && state.clients.length > 0,
	client: (state) => (id) => state.clients.find((client) => client.id === id),
	clientContactPersons: (state) => (clientId) =>
		state.clients.find((client) => client.id === clientId)?.contactPersons || [],
	loadedAt: (state) => state.loadedAt,
	searchString: (state) => state.searchString,
	clientConditions: (state) => (id) => state.conditions[id] || [],
}

const isFiltered =
	(state: IClientsStoreState) =>
	(client: IClient | IBaseClient): boolean =>
		!!client.name.toLowerCase().includes(state.searchString) ||
		!!(client.address && client.address.toLowerCase().includes(state.searchString)) ||
		!!(client.city && client.city.toLowerCase().includes(state.searchString)) ||
		(client.contactPersons.length > 0 &&
			client.contactPersons.reduce(
				(acc: boolean, curr) => (acc ? acc : !!curr.name && curr.name.toLowerCase().includes(state.searchString)),
				false,
			))

export default getters
