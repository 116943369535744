import Router, { RouteConfig } from 'vue-router'
import Vue from 'vue'

const Register = () => import('./components/auth/Register.vue')
const Login = () => import('./components/auth/Login.vue')
const RequestResetPasswordLink = () => import('./components/auth/RequestResetPasswordLink.vue')
const ResetPassword = () => import('./components/auth/ResetPassword.vue')

Vue.use(Router)

const guestRoutes: RouteConfig[] = [
	{
		path: '/aanmelden',
		name: 'register.nl',
		component: Register,
		meta: {
			name: 'register',
			requireAuth: false,
			fullScreen: true,
			hideNav: true,
			language: 'nl',
		},
	},
	{
		path: '/signup',
		name: 'register.en',
		component: Register,
		meta: {
			name: 'register',
			requireAuth: false,
			fullScreen: true,
			hideNav: true,
			language: 'en',
		},
	},
	{
		path: '/inloggen',
		name: 'login.nl',
		component: Login,
		props: (route) => ({
			oauthChallenge: route.query.login_challenge,
			sessionExpired: route.query.sessionExpired === 'true',
		}),
		meta: {
			name: 'login',
			requireAuth: false,
			fullScreen: true,
			hideNav: true,
			loadsContent: true,
			language: 'nl',
		},
	},
	{
		path: '/login',
		name: 'login.en',
		component: Login,
		props: (route) => ({ oauthChallenge: route.query.login_challenge }),
		meta: {
			name: 'login',
			requireAuth: false,
			fullScreen: true,
			hideNav: true,
			loadsContent: true,
			language: 'en',
		},
	},
	{
		path: '/wachtwoord-vergeten',
		name: 'forgotPassword.nl',
		component: RequestResetPasswordLink,
		meta: {
			name: 'forgotPassword',
			requireAuth: false,
			fullScreen: true,
			hideNav: true,
			language: 'nl',
		},
	},
	{
		path: '/forgot-password',
		name: 'forgotPassword.en',
		component: RequestResetPasswordLink,
		meta: {
			name: 'forgotPassword',
			requireAuth: false,
			hideNav: true,
			fullScreen: true,
			language: 'en',
		},
	},
	{
		path: '/passwort-zurucksetzen',
		name: 'forgotPassword.de',
		component: RequestResetPasswordLink,
		meta: {
			name: 'forgotPassword',
			requireAuth: false,
			hideNav: true,
			fullScreen: true,
			language: 'de',
		},
	},
	{
		path: '/wachtwoord-resetten/:token',
		name: 'resetPassword.nl',
		component: ResetPassword,
		props: (route: { params: { token: any } }) => ({ token: route.params.token }),
		meta: {
			name: 'resetPassword',
			requireAuth: false,
			fullScreen: true,
			hideNav: true,
			language: 'nl',
		},
	},
	{
		path: '/reset-password/:token',
		name: 'resetPassword.en',
		component: ResetPassword,
		props: (route: { params: { token: any } }) => ({ token: route.params.token }),
		meta: {
			name: 'resetPassword',
			requireAuth: false,
			fullScreen: true,
			hideNav: true,
			language: 'en',
		},
	},
	{
		path: '/passwort-zurucksetzen/:token',
		name: 'resetPassword.de',
		component: ResetPassword,
		props: (route: { params: { token: any } }) => ({ token: route.params.token }),
		meta: {
			name: 'resetPassword',
			requireAuth: false,
			fullScreen: true,
			hideNav: true,
			language: 'de',
		},
	},
]

export default guestRoutes
