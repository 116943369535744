import { ActionContext, ActionTree } from 'vuex'
import {
	IWorkspace,
	IUser,
	IUserWorkspace,
	ISavingState,
	IProductVat,
	Page,
	IUnit,
	IActiveCondition,
} from '@/interfaces'
import store, { IState } from '.'
import { BaseMutations } from '@/store/mutations'
import { OffriHTTP } from '@/axiosRequestFunctions'
import { BaseCommand } from '@/commands/BaseCommand'
import { AllCopies, allLanguagesCopy, Languages } from '@/interfaces/languageTypes'
import { mpReset } from '@/functions/tracking'

type BaseActionContext = {
	commit<K extends keyof BaseMutations>(key: K, payload: Parameters<BaseMutations[K]>[1]): ReturnType<BaseMutations[K]>
} & Omit<ActionContext<IState, IState>, 'commit'>

type C = BaseActionContext

export interface BaseActions {
	setSubscriptions({ commit }: BaseActionContext): Promise<void>
	setActiveCopy({ commit }: BaseActionContext, payload: Languages): void
	setAllCopies({ commit }: BaseActionContext, payload: any): void
	setUser(context: BaseActionContext, payload: IUser): void
	loadVatOptions(context: C): Promise<void>
	setVatOptions(context: BaseActionContext, payload: IProductVat[]): void
	setSavingState({ state, commit }: BaseActionContext, payload?: ISavingState): void
	setWorkspace({ state, commit }: BaseActionContext, payload: null | IUserWorkspace): void
	logout({ state, commit }: BaseActionContext): void
	setError({ state, commit }: BaseActionContext, payload: any): void
	loadingContent({ state, commit }: BaseActionContext): void
	contentLoaded({ state, commit }: BaseActionContext): void
	notifications({ state, commit }: BaseActionContext, payload: number): Promise<void>
	readAllNotifications({ state, commit }: BaseActionContext, payload: number): Promise<void>
	createPageVisit({ state }: BaseActionContext, payload: { page: Page; sub?: string | number }): Promise<void>
	execute({ commit }: BaseActionContext, command: BaseCommand): Promise<any>
	undo({ commit, rootState }: BaseActionContext): Promise<void>
	redo({ commit, rootState }: BaseActionContext): Promise<void>
	loadUnits(context: C): Promise<void>
	setUnits(context: BaseActionContext, payload: IUnit[]): void
	setFonts(context: BaseActionContext, payload: []): void
}

const actions: ActionTree<IState, IState> & BaseActions = {
	async setSubscriptions({ commit }) {
		await OffriHTTP.get('subscriptions').then((res) => commit('mutateSubscriptions', res.data))
	},
	setActiveCopy({ commit }, payload) {
		commit('mutateActiveLanguage', payload)
		import(`@/errors/${payload}`).then((data) => {
			commit('mutateErrors', data.default)
		})
	},
	async setAllCopies({ commit }) {
		const allCopies: AllCopies = {
			nl: await (await import(`@/languages/nl`)).default,
			en: await (await import(`@/languages/en`)).default,
			de: await (await import(`@/languages/de`)).default,
		}
		commit('mutateAllCopies', allCopies)
	},
	setUser({ state, commit, dispatch }, payload: IUser) {
		commit('mutateUser', payload)

		if (state.user?.language) {
			dispatch('setActiveCopy', state.user.language)
		} else {
			dispatch('setActiveCopy', 'nl')
		}

		const savedWs = state.workspace ? state.workspace.id : localStorage.getItem('workspace')
		let workspace: IUserWorkspace = payload.workspaces[0]
		if (savedWs) {
			const wsIndex = payload.workspaces.findIndex((ws: IUserWorkspace) => {
				return Number(savedWs) === ws.id
			})

			if (wsIndex !== -1) {
				workspace = payload.workspaces[wsIndex]
			}
		}
		dispatch('setWorkspace', workspace)
		if (workspace) localStorage.setItem('workspace', workspace.id.toString())
	},
	async loadVatOptions(context): Promise<void> {
		const res = await OffriHTTP.get(`/vat/${store.getters.workspace.vatCountry}`)
		await context.dispatch('setVatOptions', res.data)
	},
	setVatOptions({ state, commit }, payload: IProductVat[]) {
		commit('mutateVatOptions', payload)
	},
	setNotificationShade({ state, commit }, payload: boolean) {
		commit('mutateNotificationShade', payload)
	},
	setSavingState({ state, commit }, payload: ISavingState = false) {
		commit('mutateSavingState', payload)
	},
	setWorkspace({ state, commit }, payload: null | IUserWorkspace) {
		commit('mutateWorkspace', payload)
		if (payload) localStorage.setItem('workspace', payload.id.toString())
	},
	logout({ state, commit }) {
		localStorage.removeItem('token')
		commit('mutateUser', null)
		commit('mutateWorkspace', null)

		// Track user logged out
		mpReset()
	},
	setError({ state, commit }, payload: any): void {
		commit('mutateError', payload)
	},
	loadingContent({ state, commit }): void {
		state.loading = true
	},
	contentLoaded({ state, commit }): void {
		state.loading = false
	},
	async notifications({ state, commit }, payload: number) {
		let isError = false
		let notifications = await OffriHTTP.get('notifications')
			.then((response) => {
				return response.data
			})
			.catch((error) => {
				isError = true
			})
		let notificationsAmount = null

		if (!isError) {
			notificationsAmount = notifications.reduce((accumulator: number, notification: any) => {
				if (!notification.seen) accumulator++

				return accumulator
			}, 0)
		}

		commit('mutateNotifications', isError ? [] : notifications)
		commit('mutateNotificationsAmount', isError ? 0 : notificationsAmount)
	},
	async readAllNotifications({ state, commit }, payload: number) {
		let isError = false
		let someFailedToUpdate = false
		let notifications = await OffriHTTP.post('notifications')
			.then((response) => {
				if (!response.data.succes && response.data.failed_notifications && response.data.failed_notifications.length) {
					someFailedToUpdate = response.data.failed_notifications
				} else if (!response.data.succes) {
					isError = true
				}

				return response.data.notifications ? response.data.notifications : store.getters.notifications
			})
			.catch((error) => {
				isError = true
			})
		let notificationsAmount = null

		if (!isError) {
			notificationsAmount = notifications.reduce((accumulator: number, notification: any) => {
				if (!notification.seen) accumulator++

				return accumulator
			}, 0)
		}

		if (someFailedToUpdate) {
			console.error('Some notifications could not be updated')
			console.error(someFailedToUpdate)
		}

		commit('mutateNotifications', notifications)
		commit('mutateNotificationsAmount', isError ? 0 : notificationsAmount)
	},
	async createPageVisit({ state }, payload: { page: Page; sub?: string | number }) {
		if (state.workspace) {
			await OffriHTTP.post(`workspaces/${state.workspace.id}/statistics/page-visits`, payload)
		}
	},
	async execute({ commit }, command) {
		commit('resetRedoHistory', undefined)
		const result = await command.execute()
		commit('pushCommandToHistory', command)
		return result
	},
	async undo({ commit, rootState }) {
		const command = rootState.historyCommands.pop()
		if (command?.undoable) await command.undo()
		if (command) {
			commit('pushCommandToRedoHistory', command)
		}
	},
	async redo({ commit, rootState }) {
		const command = rootState.redoHistoryCommands.pop()
		await command?.execute()
		if (command) {
			commit('pushCommandToHistory', command)
		}
	},
	async loadUnits(context): Promise<void> {
		const res = await OffriHTTP.get(`/workspaces/${store.getters.workspace.id}/catalog/units`)
		await context.dispatch('setUnits', res.data)
	},
	setUnits({ state, commit }, payload: []) {
		commit('mutateUnits', payload)
	},
	setFonts({ state, commit }, payload: []) {
		commit('mutateFonts', payload)
	},
}

export default actions
