export interface OffriVideo {
	id: number
	title: string
	header: {
		video_vimeo_id: string
		image: string
	}
	pages: string[]
	order: number
}

export interface infoMenuNavItem {
	title: string
	icon: string
	component: any
	hasContent?: boolean
}

export interface UpdateItem {
	content: string
	header_image: number | string
	header_video_id: string
	header_video_description: string
	support_article: number
	call_to_action: {
		text: string
		cta_internal: number | null
		cta_external: string
	}
}

export enum UpdateMediaType {
	IMAGE = 'image',
	VIDEO = 'video',
}

export interface UpdateMedia {
	media: string
	type: UpdateMediaType
}
