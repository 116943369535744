import { UpdatePropertiesCommandTemplate } from '@/commands/abstractCommands/UpdatePropertiesCommandTemplate'
import { EBlockType, IBlock, IComponentBlock } from '@/interfaces'
import { ComponentActionContext } from '@/store/modules/component/actions'
import { AxiosResponse } from 'axios'

export class UpdateComponentsBlockPropertiesCommand extends UpdatePropertiesCommandTemplate<IBlock> {
	private componentBlock?: IComponentBlock

	constructor(
		private store: ComponentActionContext,
		totalObject: IBlock,
		newProperties: Partial<IBlock>,
		oldProperties?: Partial<IBlock>,
		componentBlock?: IComponentBlock,
	) {
		super(totalObject, newProperties, oldProperties)
		// If this block is a block inside a componentBlock then set the componentBlock to the newest version
		if (componentBlock != null && componentBlock.componentOrder != null) {
			const tempComponentBlock = this.store.state.component?.blocks[componentBlock.componentOrder]
			if (tempComponentBlock?.type == EBlockType.COMPONENT) {
				this.componentBlock = tempComponentBlock
			}
		}
	}

	changeStore(properties: Partial<IBlock>): void {
		if (this.totalObject.componentOrder !== undefined) {
			if (this.componentBlock && this.componentBlock.componentOrder != null) {
				const blockIndex = this.componentBlock.blocks.findIndex((value) => value.id === this.totalObject.id)
				this.store.commit('mutateComponentBlockProperties', {
					blockOrder: this.componentBlock.componentOrder,
					properties,
					blockIndex,
				})
			} else {
				this.store.commit('mutateBlockProperties', { blockOrder: this.totalObject.componentOrder, properties })
			}
		}
	}

	get putUrl(): string {
		return `workspaces/${this.store.rootGetters.workspace?.id}/blocks/${this.totalObject.id}`
	}

	serverRequestFailedCallback() {}

	serverRequestSucceed(
		response: AxiosResponse<any>,
		newProperties: Partial<IBlock>,
		currentProperties: Partial<IBlock>,
	): void {}

	get storeObject(): { dispatch: (a: 'setSavingState', b: 'saving' | number, c: { root: true }) => Promise<void> } {
		return this.store
	}
}
