import { BaseStoreCommand } from '../../BaseStoreCommand'
import { ProposalActionContext } from '@/store/modules/proposal/actions'
import { IProposalPage } from '@/interfaces'
import { PageManager } from '@/commands/PageManager/PageManager'
import { BlockContainerManager } from '@/commands/Block/Manager/BlockContainerManager'
import { BaseCommand } from '@/commands/BaseCommand'

export class DuplicateBlockCommand extends BaseCommand {
	private createdBlockIndex: number | null | undefined

	constructor(
		private blockContainerManager: BlockContainerManager,
		private blockIndex: number,
	) {
		super()
	}

	async execute(): Promise<void> {
		this.blockContainerManager.reloadData()
		this.createdBlockIndex = await this.blockContainerManager.duplicate(this.blockIndex)
	}

	async undo(): Promise<void> {
		this.blockContainerManager.reloadData()
		if (this.createdBlockIndex) {
			await this.blockContainerManager.deleteBlock(this.createdBlockIndex)
		}
	}
}
