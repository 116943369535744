import { MutationTree } from 'vuex'
import {
	AllBlockTypes,
	EBlockType,
	IBlockView,
	IDashboardProposal,
	IProposal,
	IProposalPage,
	IProposalVisit,
} from '@/interfaces'
import { IProposalStoreState } from '@/store/modules/proposal'
import Vue from 'vue'
import { Namespaced } from '../..'
import { ITag } from '@/interfaces/tags'

export type ProposalMutations<S = IProposalStoreState> = {
	'mutateVisits'(state: S, payload: IProposalVisit[]): void
	'mutateViews'(state: S, payload: IBlockView[]): void
	'mutateProposal'(state: S, payload: IProposal): void
	'mutateViewMode'(state: S, payload: boolean): void
	'reset'(state: S, payload?: undefined): void
	'mutatePage'(state: S, payload: { page: IProposalPage; index: number }): void
	'mutatePages'(state: S, payload: IProposalPage[]): void
	'mutateBlockProperties'(
		state: S,
		payload: { pageIndex: number; blockPageOrder: number; properties: Partial<AllBlockTypes> },
	): void
	'mutateComponentBlockBlockProperties'(
		state: S,
		payload: {
			pageIndex: number
			blockPageOrder: number
			blockIndex: number
			properties: Partial<AllBlockTypes>
		},
	): void
	'mutatePageProperties'(
		state: S,
		payload: { pageIndex: number; properties: Partial<Omit<IProposalPage, 'blocks'>> },
	): void
	'mutateTags'(state: S, payload: ITag[]): void
	'mutateIsClient'(state: S, payload: boolean): void
}

export type NamespacedProposalMutations = Namespaced<ProposalMutations, 'proposal'>

const mutations: MutationTree<IProposalStoreState> & ProposalMutations = {
	mutateVisits(state, payload: IProposalVisit[]) {
		state.visits = payload
	},
	mutateViews(state, payload: IBlockView[]) {
		state.views = payload
	},
	mutateProposal(state, payload: IProposal) {
		state.proposal = { ...state.proposal, ...payload }
	},
	mutateViewMode(state, payload: boolean) {
		state.viewMode = payload
	},
	reset(state) {
		state.proposal = undefined
		state.viewMode = false
		state.visits = []
		state.views = []
		state.tags = []
	},
	mutatePage(state, payload: { page: IProposalPage; index: number }) {
		if (state.proposal) {
			Vue.set(state.proposal.pages, payload.index, payload.page)
		}
	},
	mutatePages(state, payload: IProposalPage[]) {
		if (state.proposal) {
			Vue.set(state.proposal, 'pages', payload)
		}
	},
	mutateBlockProperties(state, payload) {
		if (state.proposal) {
			Vue.set(state.proposal.pages[payload.pageIndex].blocks, payload.blockPageOrder, {
				...state.proposal.pages[payload.pageIndex].blocks[payload.blockPageOrder],
				updatedAt: new Date().toISOString(),
				...payload.properties,
			})
		}
	},
	mutateComponentBlockBlockProperties(state, payload): void {
		if (state.proposal) {
			const componentBlock = state.proposal.pages[payload.pageIndex].blocks[payload.blockPageOrder]
			if (componentBlock.type == EBlockType.COMPONENT) {
				const changedBlock = componentBlock.blocks[payload.blockIndex]
				Vue.set(componentBlock.blocks, payload.blockIndex, { ...changedBlock, ...payload.properties })
			}
		}
	},
	mutatePageProperties(state, payload) {
		if (state.proposal) {
			Vue.set(state.proposal.pages, payload.pageIndex, {
				...state.proposal.pages[payload.pageIndex],
				...payload.properties,
			})
		}
	},
	mutateTags(state, payload) {
		state.tags = payload
	},
	mutateIsClient(state, payload) {
		state.isClient = payload
	},
}

export default mutations
