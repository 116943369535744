export enum ProposalTemplate {
	EXAMPLE = 'example',
	CUSTOM = 'custom',
	DUPLICATED = 'duplicated',
	NONE = 'none',
}

export enum ProposalViewModes {
	EDIT = 'edit',
	VIEW = 'view',
}

export enum ImageInputActions {
	ADDED = 'added',
	REMOVED = 'removed',
}

export enum VideoPlayer {
	YOUTUBE = 'youtube',
	VIMEO = 'vimeo',
}
