import { MutationTree } from 'vuex'
import { initialStoreState, IImportStoreState } from '@/store/modules/import'
import { Namespaced } from '../..'
import { IcsvError, IImportProduct, IImportOptions, importCompleted } from '@/interfaces'

export type ImportMutations<S = IImportStoreState> = {
	mutateOptions(state: S, payload: Partial<IImportOptions>): void
	mutateParsedCsv(state: S, payload: IImportProduct[]): void
	mutateParsedCsvName(state: S, payload: string): void
	mutateCsvAlreadyValidated(state: S, payload: boolean): void
	mutateCompleted(state: S, payload: importCompleted): void
	addError(state: S, payload: IcsvError & { line: number }): void
	clearErrors(state: S, payload?: undefined): void
	reset(state: S, payload?: undefined): void
	resetCompleted(state: S, payload?: undefined): void
}

export type NamespacedImportMutations = Namespaced<ImportMutations, 'productImport'>

const mutations: MutationTree<IImportStoreState> & ImportMutations = {
	mutateOptions(state, payload) {
		state.options = { ...state.options, ...payload }
	},
	mutateParsedCsv(state, payload) {
		state.parsedCsv = payload
	},
	mutateParsedCsvName(state, payload) {
		state.parsedCsvName = payload
	},
	mutateCsvAlreadyValidated(state, payload) {
		state.csvAlreadyValidated = payload
	},
	mutateCompleted(state, payload) {
		state.completed = { ...state.completed, ...payload }
	},
	addError(state, payload) {
		const { line, ...error } = payload
		if (!state.errors) {
			state.errors = {}
		}
		if (!Array.isArray(state.errors[line])) {
			state.errors[line] = []
		}
		state.errors[line].push(error)
	},
	clearErrors(state, payload) {
		state.errors = {}
	},
	reset(state, payload) {
		const { completed, ...initialStoreStateWithoutCompleted } = initialStoreState
		Object.assign(state, initialStoreStateWithoutCompleted)
	},
	resetCompleted(state, payload) {
		state.completed = undefined
	},
}

export default mutations
