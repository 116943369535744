import { UpdatePropertiesCommandTemplate } from '@/commands/abstractCommands/UpdatePropertiesCommandTemplate'
import { IComponent, IProduct } from '@/interfaces'
import { AxiosResponse, AxiosError } from 'axios'
import { ComponentActionContext } from '@/store/modules/component/actions'

export class UpdateProductProperties extends UpdatePropertiesCommandTemplate<IProduct> {
	constructor(
		private store: ComponentActionContext,
		private component: IComponent,
		totalObject: IProduct,
		newProperties: Partial<IProduct>,
		oldProperties?: Partial<IProduct>,
		undoAble?: boolean,
	) {
		super(totalObject, newProperties, oldProperties, undoAble)
	}

	serverRequestFailedCallback(err: AxiosError, newProperties: Partial<IProduct>, currentProperties: Partial<IProduct>) {
		throw err
	}

	changeStore(properties: Partial<IProduct>): void {
		this.store.commit('mutateProduct', { ...this.totalObject, ...properties })
	}

	get putUrl(): string {
		return `/workspaces/${this.store.rootGetters.workspace?.id}/catalog/products/${this.component.id}`
	}

	serverRequestSucceed(
		response: AxiosResponse<any>,
		newProperties: Partial<IProduct>,
		currentProperties: Partial<IProduct>,
	): void {}

	get storeObject() {
		return this.store
	}
}
