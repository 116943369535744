import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'
import jwtDecode from 'jwt-decode'
import store from './store/'
import { IJwtToken, IUser, SubscriptionStatus } from './interfaces'
import { OffriHTTP } from '@/axiosRequestFunctions'
import { isNumber } from 'lodash'

// Different language routes
import guestRoutes from './routes-guest'
import { Languages } from './interfaces/languageTypes'
import { mpIdentify } from './functions/tracking'

// General
const Profile = () => import('./components/profile/Profile.vue')
const Dashboard = () => import('./components/dashboard/Overview.vue')
const Templates = () => import('./components/templates/Templates.vue')
const Statistics = () => import('./components/statistics/Statistics.vue')
const NoWorkspaces = () => import('./components/auth/NoWorkspaces.vue')
const InviteRegister = () => import('./components/auth/InviteRegister.vue')
const PageNotFound = () => import('./components/utilities/PageNotFound.vue')
const Consent = () => import('./components/auth/Consent.vue')

const Proposal = () => import('./components/proposals/Proposal.vue')
const ProposalClientView = () => import('./components/proposals/ClientView.vue')
const ProposalPDF = () => import('./components/proposals/pdf/ProposalPDF.vue')

// Clients
const Clients = () => import('./components/clients/Clients.vue')
const Client = () => import('./components/clients/Client.vue')
const CreateClient = () => import('./components/clients/Create.vue')

// Account
const Accounts = () => import('./components/account/Accounts.vue')
const AccountOverview = () => import('./components/account/Overview.vue')
const AccountPayment = () => import('./components/account/Payment.vue')
const PaymentResult = () => import('@/components/account/PaymentResult.vue')
const AccountSubscribe = () => import('./components/account/Subscribe.vue')
const AccountLocked = () => import('./components/account/AccountLocked.vue')

// Workspaces
const WorkspaceOverview = () => import('./components/workspace/Overview.vue')
const WorkspaceUsers = () => import('./components/workspace/Users.vue')
const WorkspaceProposals = () => import('./components/workspace/Proposals.vue')
const WorkspaceLayout = () => import('./components/workspace/Layout.vue')
const WorkspaceConditions = () => import('./components/workspace/Conditions.vue')

// Catalog
const CatalogOverview = () => import('./components/catalog/Overview.vue')
const CatalogComponents = () => import('./components/catalog/Components.vue')
const CatalogComponent = () => import('./components/catalog/Component.vue')
const CatalogMedia = () => import('./components/catalog/Media.vue')

// Product import
const ImportOverview = () => import('./components/catalog/import/ImportOverview.vue')
const ImportLoadData = () => import('./components/catalog/import/ImportLoadData.vue')
const ImportValidate = () => import('./components/catalog/import/ImportValidate.vue')
const ImportOptions = () => import('./components/catalog/import/ImportOptions.vue')

Vue.use(Router)

export function isAuthenticated(): false | string {
	let token = localStorage.getItem('token')

	if (!token) {
		return false
	}
	let decodedToken: IJwtToken
	try {
		decodedToken = jwtDecode(token)
	} catch (error) {
		localStorage.clear()
		return false
	}
	if (Date.now() / 1000 > decodedToken.exp) {
		localStorage.clear()
		return false
	} else if (decodedToken.iat > Date.now() + 86400) {
		OffriHTTP.get<{ access_token: string; user: IUser }>('auth/renew')
			.then((res) => {
				localStorage.setItem('token', res.data.access_token)
				return jwtDecode(res.data.access_token)
			})
			.catch((e) => {
				return false
			})
	}
	return token
}

const routes: RouteConfig[] = [
	{
		path: '/',
		alias: ['/dashboard', '/offertes', '/proposals'],
		name: 'dashboard',
		component: Dashboard,
		meta: {
			name: 'dashboard',
			requireAuth: true,
		},
	},
	{
		path: '/cd/:workspaceId',
		meta: {
			requireAuth: true,
			name: 'changeWorkspace',
			accessibleWithoutSubscription: true,
		},
	},
	{
		path: '/proposal/:id',
		alias: '/offerte/:id',
		name: 'proposal',
		component: Proposal,
		props: (route) => ({ id: route.params.id, template: false }),
		meta: {
			name: 'proposal',
			requireAuth: true,
			fullScreen: true,
			loadsContent: true,
		},
	},
	{
		path: '/clients',
		alias: '/contacten',
		name: 'clients',
		component: Clients,
		meta: {
			name: 'clients',
			requireAuth: true,
		},
	},
	{
		path: '/clients/new',
		alias: '/contacten/toevoegen',
		name: 'createClient',
		component: CreateClient,
		meta: {
			name: 'createClient',
			requireAuth: true,
			loadsContent: true,
		},
	},
	{
		path: '/clients/:id',
		alias: '/contacten/:id',
		name: 'client',
		component: Client,
		props: (route) => ({ id: Number(route.params.id) }),
		beforeEnter(to, from, next) {
			if (to.params.id === null || !isNumber(Number(to.params.id))) {
				next({ name: 'clients' })
			}
			next()
		},
		meta: {
			name: 'client',
			requireAuth: true,
			loadsContent: true,
		},
	},
	{
		path: '/profile',
		alias: '/profiel',
		name: 'profile',
		component: Profile,
		meta: {
			name: 'profile',
			requireAuth: true,
			hasSavingState: true,
			accessibleWithoutSubscription: true,
		},
	},
	{
		path: '/accounts',
		component: Accounts,
		name: 'accounts',
		meta: {
			requireAuth: true,
			name: 'accounts',
			loadsContent: true,
			accessibleWithoutSubscription: true,
		},
	},
	{
		path: '/account/:accountId',
		component: AccountOverview,
		props: (route) => ({ accountId: Number(route.params.accountId) }),
		meta: {
			name: 'account',
		},
		children: [
			{
				path: '/account/:accountId/payment',
				alias: '/account/:accountId/betaalgegevens',
				name: 'account.payment',
				component: AccountPayment,
				meta: {
					name: 'account.payment',
					requireAuth: true,
					loadsContent: true,
					hasSavingState: true,
					subtitle: 'paymentDetails',
					accessibleWithoutSubscription: true,
				},
			},
		],
	},
	{
		path: '/account/:accountId/subscription',
		name: 'account.payment.subscribe',
		component: AccountSubscribe,
		props: (route) => ({ accountId: Number(route.params.accountId), subscription: route.query.subscription }),
		meta: {
			name: 'account.payment.subscribe',
			requireAuth: true,
			loadsContent: true,
			hasSavingState: true,
			subtitle: 'closeAccount',
			accessibleWithoutSubscription: true,
		},
	},
	{
		path: '/account/:accountId/blocked',
		name: 'account.payment.paused',
		component: AccountLocked,
		props: (route) => ({ accountId: Number(route.params.accountId) }),
		meta: {
			name: 'account.payment.paused',
			requireAuth: true,
			hideNav: true,
			subtitle: 'accountPaused',
			accessibleWithoutSubscription: true,
		},
	},
	{
		path: '/payment/:paymentHash',
		alias: '/betaling/:paymentHash',
		name: 'account.payment.result',
		component: PaymentResult,
		props: (route) => ({ paymentHash: route.params.paymentHash }),
		meta: {
			name: 'account.payment.result',
			requireAuth: true,
			loadsContent: true,
			fullScreen: true,
			subtitle: 'payment',
			accessibleWithoutSubscription: true,
		},
	},
	{
		path: '/workspace',
		meta: {
			name: 'workspace',
		},
		component: WorkspaceOverview,
		children: [
			{
				path: '/workspace/users',
				alias: '/workspace/gebruikers',
				name: 'workspace.users',
				component: WorkspaceUsers,
				meta: {
					name: 'workspace.users',
					requireAuth: true,
					loadsContent: true,
					hasSavingState: true,
					subtitle: 'user',
				},
			},
			{
				path: '/workspace/proposals',
				alias: '/workspace/offertes',
				name: 'workspace.proposals',
				component: WorkspaceProposals,
				meta: {
					name: 'workspace.proposals',
					requireAuth: true,
					loadsContent: true,
					hasSavingState: true,
					subtitle: 'proposals',
				},
			},
			{
				path: '/workspace/layout',
				alias: '/workspace/layout',
				name: 'workspace.layout',
				component: WorkspaceLayout,
				meta: {
					name: 'workspace.layout',
					requireAuth: true,
					loadsContent: true,
					hasSavingState: true,
					subtitle: 'Layout',
				},
			},
			{
				path: '/workspace/terms',
				alias: '/workspace/voorwaarden',
				name: 'workspace.conditions',
				component: WorkspaceConditions,
				meta: {
					name: 'workspace.conditions',
					requireAuth: true,
					hasSavingState: true,
					subtitle: 'conditions',
				},
			},
		],
	},
	{
		path: '/catalog',
		component: CatalogOverview,
		meta: {
			name: 'catalog',
		},
		children: [
			{
				path: '/catalog',
				name: 'catalog.components',
				component: CatalogComponents,
				meta: {
					name: 'catalog.components',
					requireAuth: true,
					hasSavingState: false,
				},
			},
			{
				path: '/media',
				name: 'media',
				component: CatalogMedia,
				meta: {
					name: 'media',
					requireAuth: true,
				},
			},
			{
				path: 'import',
				name: 'catalog.import',
				component: ImportOverview,
				redirect: 'import/load-data',
				children: [
					{
						path: 'load-data',
						name: 'catalog.import.load-data',
						component: ImportLoadData,
						meta: {
							name: 'catalog.import.load-data',
							requireAuth: true,
							loadsContent: false,
							subtitle: 'import',
						},
					},
					{
						path: 'validate',
						name: 'catalog.import.validate',
						component: ImportValidate,
						meta: {
							name: 'catalog.import.validate',
							requireAuth: true,
							loadsContent: true,
							subtitle: 'import',
						},
					},
					{
						path: 'options',
						name: 'catalog.import.options',
						component: ImportOptions,
						meta: {
							name: 'catalog.import.options',
							requireAuth: true,
							loadsContent: false,
							subtitle: 'import',
						},
					},
				],
			},
		],
	},
	{
		path: '/catalog/components/:id',
		name: 'catalog.component',
		component: CatalogComponent,
		props: (route) => ({ id: route.params.id, product: true }),
		meta: {
			name: 'catalog.component',
			requireAuth: true,
			loadsContent: true,
			hasSavingState: true,
		},
	},
	{
		path: '/stats',
		alias: '/statistieken',
		name: 'statistics',
		component: Statistics,
		props: (route) => ({
			year: route.query.year,
			_currency: route.query.currency,
			_contractType: route.query.contractType,
		}),
		meta: {
			name: 'statistics',
			requireAuth: true,
		},
	},
	{
		path: '/templates',
		alias: '/sjablonen',
		name: 'templates',
		component: Templates,
		meta: {
			name: 'templates',
			requireAuth: true,
		},
	},
	{
		path: '/templates/:id',
		alias: '/sjablonen/:id',
		name: 'template',
		component: Proposal,
		props: (route) => ({ id: route.params.id, template: true }),
		meta: {
			name: 'template',
			requireAuth: true,
			fullScreen: true,
			loadsContent: true,
		},
	},
	{
		path: '/no-workspaces',
		name: 'noWorkspaces',
		component: NoWorkspaces,
		meta: {
			name: 'noWorkspaces',
			requireAuth: true,
			fullScreen: true,
			hideNav: true,
		},
		beforeEnter(to, from, next) {
			if (!store.getters.user?.workspaces?.length) {
				next()
			} else {
				next({ name: 'dashboard' })
			}
		},
	},

	{
		path: '/i/:token',
		name: 'invitationSignUp',
		component: InviteRegister,
		props: (route) => ({ token: route.params.token }),
		meta: {
			name: 'invitationSignUp',
			requireAuth: false,
			fullScreen: true,
			hideNav: true,
		},
	},
	{
		path: '/o/:id',
		name: 'proposalClientView',
		component: ProposalClientView,
		props: (route) => ({ id: route.params.id, clientId: route.query.c, contactId: route.query.cp }),
		meta: {
			name: 'proposalClientView',
			requireAuth: false,
			fullScreen: true,
			loadsContent: true,
			hideNav: true,
		},
	},
	{
		path: '/pdf/:proposalId',
		name: 'proposalPDF',
		component: ProposalPDF,
		props: (route) => ({
			proposalId: route.params.proposalId,
			clientId: route.query.c,
			contactPersonId: route.query.cp,
		}),
		meta: {
			name: 'proposalPDF',
			requireAuth: false,
			fullScreen: true,
			loadsContent: true,
			hideNav: true,
		},
	},
	{
		path: '/consent',
		name: 'consent',
		component: Consent,
		props: (route) => ({ oauthChallenge: route.query.consent_challenge }),
		meta: {
			name: 'consent',
			requireAuth: true,
			fullScreen: true,
			hideNav: true,
			loadsContent: true,
		},
	},
	{
		path: '*',
		name: 'notFound',
		component: PageNotFound,
		props: () => ({ auth: store.state.user ? true : false }),
		meta: {
			name: 'notFound',
			requireAuth: false,
			tryAuth: true,
			fullScreen: true,
			hideNav: true,
			loadsContent: true,
		},
	},
]

routes.push.apply(routes, guestRoutes)

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) return savedPosition
		else return { x: 0, y: 0 }
	},
	routes,
})

router.beforeEach(async (to, from, next) => {
	if (to.meta?.hasSavingState) {
		await store.dispatch('setSavingState', Date.now())
	} else {
		await store.dispatch('setSavingState', false)
	}

	if (to.meta?.name === 'login' && !to.query.login_challenge && isAuthenticated()) {
		next({ name: from.name ? from.name : 'dashboard' })
	} else {
		store.state.fullscreen = !!to.meta?.fullScreen
		store.state.loading = !!to.meta?.loadsContent
		store.state.hideNav = !!to.meta?.hideNav

		if (to.meta?.requireAuth || to.meta?.tryAuth) {
			// Authenticate
			const token = isAuthenticated()
			if (!token) {
				// // Check language of page the user tried to visit
				// if(to.name?.charAt(to.name?.length - 3) == '.' && languageCodes.indexOf(to.name?.slice(-2)) > -1) {
				// 	// Send user to login page in the same language as the page they tried to visit
				// 	console.log(to.name.slice(-2))
				// 	console.log(to.name.charAt(to.name.length -3))
				// 	next( { name: 'login.' + to.name.slice(-2), query: {nextUrl: to.fullPath }})
				// } else {
				if (to.meta.requireAuth) next({ name: 'login.' + 'nl', query: { nextUrl: to.fullPath } })
				// }
			} else {
				if (!store.state.user || !store.state.workspace) {
					await OffriHTTP.get<IUser>('auth/me')
						.then((res) => {
							if (!res.data.workspaces.length && to.meta?.name !== 'noWorkspaces') {
								next({ name: 'noWorkspaces' })
								return
							}
							store.dispatch('setUser', res.data)

							//Track a user login
							mpIdentify(res.data.id?.toString())

							if (to.meta?.name === 'noWorkspace') {
								next({ name: 'dashboard' })
							} else {
								next()
							}
						})
						.catch(() => {
							localStorage.removeItem('token')

							Vue.nextTick(() => {
								const nextUrl = ['', '/', ' '].includes(to.fullPath) ? '' : to.fullPath
								next({ name: 'login.nl', query: { nextUrl } })
							})
						})
				} else {
					// logged in
					if (store.state.user.language) {
						await store.dispatch('setActiveCopy', store.state.user.language)
					} else {
						await store.dispatch('setActiveCopy', Languages.NL)
					}
					next()
				}
			}

			if (store.state.user && to.meta?.name !== 'profile' && store.getters.twoFactorRequiredError) {
				next({ name: 'profile' })
				store.state.loading = false
			} else if (to.meta?.name === 'changeWorkspace' && store.state.user) {
				const workspaceId = to.params.workspaceId
				const workspaceIndex = store.state.user.workspaces.findIndex((ws) => ws.id === Number(workspaceId))

				if (workspaceIndex > -1) await store.dispatch('setWorkspace', store.state.user.workspaces[workspaceIndex])
				next({ name: 'dashboard' })
			} else if (
				store.state.workspace &&
				![
					SubscriptionStatus.ACTIVE,
					SubscriptionStatus.TRIAL,
					SubscriptionStatus.CANCELLED,
					SubscriptionStatus.TRIAL_PENDING_ACTIVATION,
				].includes(store.state.workspace.account.subscriptionStatus) &&
				!to.meta?.accessibleWithoutSubscription
			) {
				const isOwner = store.state.user?.accounts?.some((account) => account.id == store.state.workspace?.account.id)
				if (isOwner && store.state.workspace.account.subscriptionStatus == SubscriptionStatus.PAUSED_AFTER_TRIAL) {
					next({ name: 'account.payment', params: { accountId: String(store.state.workspace.account.id) } })
				} else {
					next({ name: 'account.payment.paused', params: { accountId: String(store.state.workspace.account.id) } })
				}
			} else {
				next()
			}
		} else {
			next()
		}
	}
})

router.afterEach((to, from) => {
	store.dispatch('setNotificationShade', false)
})
export default router
