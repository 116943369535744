import mutations from './mutations'
import getters from './getters'
import actions from './actions'
import { IDashboardProposal } from '@/interfaces'

export interface IProposalsStoreState {
	proposalsReadyForReview: IDashboardProposal[]
	visits: Array<{ proposalId: string; amount: number }>
	proposals: IDashboardProposal[]
}

const state: IProposalsStoreState = {
	visits: [],
	proposals: [],
	proposalsReadyForReview: [],
}
export const proposalState = state
export default {
	namespaced: true,
	state,
	actions,
	getters,
	mutations,
}
