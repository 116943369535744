import { IComponentStoreState } from '@/store/modules/component/index'
import { Namespaced } from '@/store'
import { MutationTree } from 'vuex'
import { AllBlockTypes, EBlockType, IBlock, ICategory, IComponent, IProduct } from '@/interfaces'
import Vue from 'vue'
import { ITag } from '@/interfaces/tags'

export type ComponentMutations<S = IComponentStoreState> = {
	'mutateComponent'(state: S, payload: IComponent): void
	'mutateComponentProperty'(state: S, payload: Partial<IComponent>): void
	'mutateComponentBlockProperties'(
		state: S,
		payload: { blockIndex: number; blockOrder: number; properties: Partial<AllBlockTypes> },
	): void
	'mutateBlockProperties'(state: S, payload: { blockOrder: number; properties: Partial<IBlock> }): void
	'mutateComponentCategory'(state: S, payload: ICategory): void
	'mutateCategories'(state: S, payload: ICategory[]): void
	'mutateComponentLanguageVersions'(state: S, payload: IComponent[]): void
	'mutateProduct'(state: S, payload: IProduct): void
	'mutateCanBeProduct'(
		state: S,
		payload: { containsProduct: boolean; isInProduct: boolean; canBeProduct: boolean },
	): void
	'mutateTags'(state: S, payload: ITag[]): void
}

export type NamespacedComponentMutations = Namespaced<ComponentMutations, 'component'>

const mutations: MutationTree<IComponentStoreState> & ComponentMutations = {
	mutateComponent(state, payload) {
		state.component = payload
	},
	mutateComponentProperty(state, payload) {
		if (state.component !== undefined) {
			state.component = { ...state.component, ...payload }
		}
	},
	mutateComponentBlockProperties(state, payload): void {
		if (state.component) {
			const componentBlock = state.component.blocks[payload.blockOrder]
			if (componentBlock.type == EBlockType.COMPONENT) {
				const changedBlock = componentBlock.blocks[payload.blockIndex]
				Vue.set(componentBlock.blocks, payload.blockIndex, { ...changedBlock, ...payload.properties })
			}
		}
	},
	mutateBlockProperties(state, payload) {
		if (state.component !== undefined) {
			Vue.set(state.component.blocks, payload.blockOrder, {
				...state.component.blocks[payload.blockOrder],
				updatedAt: new Date().toISOString(),
				...payload.properties,
			})
		}
	},
	mutateComponentCategory(state, payload) {
		if (state.component !== undefined) {
			state.component.category = payload
		} else {
			console.error('Try to change category while state.component was undefined')
		}
	},
	mutateCategories(state, payload) {
		state.categories = payload
	},
	mutateComponentLanguageVersions(state, payload) {
		state.languageVersions = payload
	},
	mutateProduct(state, payload) {
		if (state.component !== undefined) {
			Vue.set(state.component, 'product', payload)
		}
	},
	mutateCanBeProduct(state, payload) {
		state.canBeProduct = payload
	},
	mutateTags(state, payload) {
		state.tags = payload
	},
}
export default mutations
