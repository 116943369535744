import { BlockContainerManager } from '@/commands/Block/Manager/BlockContainerManager'
import { ProposalActionContext } from '@/store/modules/proposal/actions'
import { IBlock, IProposalPage } from '@/interfaces'
import { cloneDeep } from 'lodash'
import { OffriHTTP } from '@/axiosRequestFunctions'

export class PageManager extends BlockContainerManager {
	pageData: IProposalPage

	constructor(
		private _store: ProposalActionContext,
		private proposalPageId: number,
	) {
		super()
		const newPageData = cloneDeep(this.store.getters.proposal?.pages[this.store.getters.indexOfPages[proposalPageId]])
		if (newPageData) {
			this.pageData = newPageData
		} else {
			throw new Error('PageId is not defined')
		}
	}

	reloadData() {
		const newPageData = cloneDeep(
			this.store.getters.proposal?.pages[this.store.getters.indexOfPages[this.proposalPageId]],
		)
		if (newPageData) {
			this.pageData = newPageData
		} else {
			throw new Error('PageId is not defined')
		}
	}

	get baseUrl() {
		return `workspaces/${this.store.state.proposal?.workspaceId}/${
			this.store.state.proposal?.template ? 'templates' : 'proposals'
		}/${this.store.state.proposal?.id}/pages/${this.pageData.id}/blocks`
	}

	get blocks() {
		return this.pageData.blocks
	}

	get orderProperty(): keyof IBlock {
		return 'pageOrder'
	}

	async reloadBlockContainer() {
		await OffriHTTP.get<IProposalPage>(
			`/workspaces/${this.store.state.proposal?.workspaceId}/${
				this.store.state.proposal?.template ? 'templates' : 'proposals'
			}/${this.store.state.proposal?.id}/pages/${this.pageData.id}`,
		)
			.then(async (res) => {
				this.pageData = res.data
				await this.updateStore()
			})
			.catch(async (e) => {
				console.error(e)
			})
	}

	async updateStore() {
		this.store.commit('mutatePage', { page: this.pageData, index: this.store.getters.indexOfPages[this.pageData.id] })
	}

	setOrders() {
		const amount = this.pageData?.blocks.length
		for (let i = 0; i < amount; i++) {
			this.pageData.blocks[i].pageOrder = i
		}
	}

	get store() {
		return this._store
	}

	get workspaceId() {
		return this._store.rootGetters.workspace!.id
	}

	async updatePageDataFromServer() {
		await OffriHTTP.get<IProposalPage>(
			`/workspaces/${this.store.state.proposal?.workspaceId}/${
				this.store.state.proposal?.template ? 'templates' : 'proposals'
			}/${this.store.state.proposal?.id}/pages/${this.pageData.id}`,
		)
			.then(async (res) => {
				this.pageData = res.data
				await this.updateStore()
			})
			.catch(async (e) => {
				console.error(e)
			})
	}

	async updatePage(newProperties: Partial<Omit<IProposalPage, 'blocks'>>) {
		await this.store.dispatch('setSavingState', 'saving', { root: true })
		if (newProperties) {
			let backUpPageData = cloneDeep(this.pageData)
			this.pageData = { ...this.pageData, ...newProperties }
			this.updateStore()
			await OffriHTTP.put(
				`/workspaces/${this.store.state.proposal?.workspaceId}/${
					this.store.state.proposal?.template ? 'templates' : 'proposals'
				}/${this.store.state.proposal?.id}/pages/${this.pageData.id}`,
				newProperties,
			)
				.then(() => {
					this.store.dispatch('setSavingState', Date.now(), { root: true })
				})
				.catch((error) => {
					this.pageData = backUpPageData
					this.updateStore()
					console.error(error)
				})
		}
	}
}
