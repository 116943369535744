import { BaseStoreCommand } from '../BaseStoreCommand'
import { OffriHTTP } from '@/axiosRequestFunctions'
import { ProposalsActionContext } from '../../store/modules/proposals/actions'

export class UnarchiveProposalCommand extends BaseStoreCommand<ProposalsActionContext> {
	constructor(
		protected readonly store: ProposalsActionContext,
		private readonly payload: string,
	) {
		super(store)
	}

	async execute() {
		if (this.store.rootState.workspace) {
			this.store.dispatch('setSavingState', 'saving', { root: true })
			await OffriHTTP.patch(
				`/workspaces/${this.store.rootGetters.workspace?.id}/proposals/expose/${this.payload}`,
			).then(() => {
				this.store.commit('mutateProposal', { proposalId: this.payload, values: { deletedAt: null } })
				this.store.dispatch('setSavingState', new Date(), { root: true })
			})
		}
	}

	async undo() {}
}
