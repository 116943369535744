import { BaseStoreCommand } from '../../BaseStoreCommand'
import { IProposalStoreState } from '@/store/modules/proposal'
import { ActionContext } from 'vuex'
import { IState } from '@/store'
import { AllBlockTypes, EBlockType, IProposalPage } from '@/interfaces'
import { PageManager } from '@/commands/PageManager/PageManager'
import { ProposalActionContext } from '@/store/modules/proposal/actions'
import { BlockContainerManager } from '@/commands/Block/Manager/BlockContainerManager'
import { BaseCommand } from '@/commands/BaseCommand'

export class CreateBlockCommand extends BaseCommand {
	blockOrder: (number | null | undefined)[] | undefined

	constructor(
		private blockContainerManger: BlockContainerManager,
		private blocks: Array<{ [p: string]: any }>,
		private order?: number,
	) {
		super()
	}

	async execute(): Promise<AllBlockTypes[]> {
		this.blockContainerManger.reloadData()
		let newBlocks = await this.blockContainerManger.createBlock(this.blocks, this.order)
		this.blockOrder = newBlocks.map((value) => value.pageOrder)
		return newBlocks
	}

	async undo(): Promise<void> {
		this.blockContainerManger.reloadData()
		if (this.blockOrder) {
			for (let order of this.blockOrder) {
				if (order || order === 0) {
					await this.blockContainerManger.deleteBlock(order)
				}
			}
		}
	}
}
