import Vue from 'vue'
import Vuex, { ActionContext, CommitOptions, DispatchOptions, Store as VuexStore } from 'vuex'
import mutations, { BaseMutations } from './mutations'
import getters, { BaseGetters } from './getters'
import actions, { BaseActions } from './actions'
import { IWorkspace, IUser, ISavingState, IProductVat, IUserWorkspace, IUnit } from '@/interfaces'
import proposal, { IProposalStoreState } from './modules/proposal'
import { NamespacedProposalGetter } from './modules/proposal/getters'
import { NamespacedProposalMutations } from './modules/proposal/mutations'
import { NamespacedProposalActions } from './modules/proposal/actions'
import clients, { IClientsStoreState } from './modules/clients'
import { NamespacedClientGetter } from './modules/clients/getters'
import { NamespacedClientMutations } from './modules/clients/mutations'
import { NamespacedClientActions } from './modules/clients/actions'
import proposals, { IProposalsStoreState } from './modules/proposals'
import { NamespacedProposalsGetter } from './modules/proposals/getters'
import { NamespacedProposalsMutations } from './modules/proposals/mutations'
import { NamespacedProposalsActions } from './modules/proposals/actions'
import component, { IComponentStoreState } from './modules/component'
import { NamespacedComponentMutations } from '@/store/modules/component/mutations'
import { NamespacedComponentActions } from '@/store/modules/component/actions'
import { NamespacedComponentGetter } from '@/store/modules/component/getters'
import { BaseCommand } from '@/commands/BaseCommand'
import { AllCopies, allLanguagesCopy } from '@/interfaces/languageTypes'
import { NamespacedImportGetter } from './modules/import/getters'
import { NamespacedImportActions } from './modules/import/actions'
import { NamespacedImportMutations } from './modules/import/mutations'
import productImport, { IImportStoreState } from './modules/import'
import { Languages } from '@/interfaces/languageTypes'

Vue.use(Vuex)

export interface IState {
	loading: boolean
	notificationShade: boolean
	hideNav: boolean
	workspace: null | IUserWorkspace
	user: null | IUser
	error: any
	activeLanguage: Languages
	allCopies: AllCopies
	errors: {}
	fullscreen: boolean
	savingState: ISavingState
	vatOptions: IProductVat[]
	notifications: any
	notificationsAmount: number | null
	subscriptions: []
	historyCommands: BaseCommand[]
	redoHistoryCommands: BaseCommand[]
	units: IUnit[]
	fonts: []
	activeFullscreenModal: string | null
}

const state: IState = {
	loading: false,
	notificationShade: false,
	hideNav: false,
	workspace: null,
	user: null,
	error: null,
	activeLanguage: Languages.NL,
	allCopies: {} as AllCopies,
	errors: {},
	fullscreen: true,
	savingState: false,
	vatOptions: [],
	notifications: [],
	notificationsAmount: null,
	subscriptions: [],
	historyCommands: [],
	redoHistoryCommands: [],
	units: [],
	fonts: [],
	activeFullscreenModal: null,
}
export const baseState = state
export default new Vuex.Store({
	state,
	mutations,
	getters,
	actions,
	modules: {
		proposals,
		proposal,
		clients,
		component,
		productImport,
	},
})

export function getNewStore() {
	return new Vuex.Store({
		state,
		mutations,
		getters,
		actions,
		modules: {
			proposals,
			proposal,
			clients,
			component,
			productImport,
		},
	}) as unknown as Store
}

Object.defineProperty(Vue.prototype, '$typedStore', {
	get: function (this: Vue) {
		return this.$store
	},
})

export type Namespaced<T, N extends string> = {
	[P in keyof T & string as `${N}/${P}`]: T[P]
}

export type AllMutations = BaseMutations &
	NamespacedClientMutations &
	NamespacedProposalMutations &
	NamespacedProposalsMutations &
	NamespacedComponentMutations &
	NamespacedImportMutations

export type AllActions = BaseActions &
	NamespacedProposalActions &
	NamespacedClientActions &
	NamespacedProposalsActions &
	NamespacedComponentActions &
	NamespacedImportActions

export type AllGetters = BaseGetters &
	NamespacedClientGetter &
	NamespacedProposalGetter &
	NamespacedProposalsGetter &
	NamespacedComponentGetter &
	NamespacedImportGetter

export type AugmentedActionContext<
	State,
	Mutations extends { [key: string]: (...args: any) => any },
	Getters extends { [key: string]: any },
	Actions,
> = {
	commit<
		T extends AllMutations,
		K extends keyof (T | O),
		O extends AllMutations & { [key: string]: (...args: any) => any },
	>(
		key: K,
		payload: Parameters<O[K]>[1],
		options: { root: true },
	): ReturnType<O[K]>
	commit<K extends keyof Mutations>(
		key: K,
		payload?: Parameters<Mutations[K]>[1],
		options?: { root: false | undefined },
	): ReturnType<Mutations[K]>
} & {
	getters: Getters
} & {
	dispatch<
		T extends AllActions,
		K extends keyof (T | O),
		O extends AllActions & { [key: string]: (...args: any) => any },
	>(
		key: K,
		payload: Parameters<O[K]>[1],
		options: { root: true },
	): ReturnType<O[K]>
	dispatch<T extends Actions, K extends keyof (T | O), O extends Actions & { [key: string]: (...args: any) => any }>(
		key: K,
		payload: Parameters<O[K]>[1],
		options?: { root: false | undefined },
	): ReturnType<O[K]>
} & {
	rootGetters: AllGetters
} & Omit<ActionContext<State, IState>, 'commit' | 'getters' | 'dispatch' | 'rootGetters'>

export type Store = Omit<VuexStore<IState>, 'getters' | 'commit' | 'dispatch' | 'state'> & {
	state: IState & { proposal: IProposalStoreState } & { clients: IClientsStoreState } & {
		proposals: IProposalsStoreState
	} & { component: IComponentStoreState } & { import: IImportStoreState }
} & {
	commit<
		T extends AllMutations,
		K extends keyof (T | O),
		O extends AllMutations & { [key: string]: (...args: any) => any },
	>(
		key: K,
		payload: Parameters<O[K]>[1],
		options?: CommitOptions,
	): ReturnType<O[K]>
} & {
	dispatch<
		T extends AllActions,
		K extends keyof (T | O),
		O extends AllActions & { [key: string]: (...args: any) => any },
	>(
		key: K,
		payload: Parameters<O[K]>[1],
		options?: DispatchOptions,
	): ReturnType<O[K]>
} & {
	getters: AllGetters
}
