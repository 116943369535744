import { BaseStoreCommand } from '@/commands/BaseStoreCommand'
import { ComponentActionContext } from '@/store/modules/component/actions'
import { OffriHTTP } from '@/axiosRequestFunctions'

export class DuplicateComponentCommand extends BaseStoreCommand<ComponentActionContext> {
	public undoable = false

	constructor(
		store: ComponentActionContext,
		private id: number,
		private componentType: 'components' | 'products',
	) {
		super(store)
	}

	async execute() {
		return OffriHTTP.post(
			`/workspaces/${this.store.rootGetters.workspace?.id}/catalog/${this.componentType}/${this.id}/duplicate`,
		)
	}

	async undo() {
		return Promise.resolve(undefined)
	}
}
