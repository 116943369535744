import { MutationTree } from 'vuex'
import { IBaseClient, IClient, IConditions, IContactPerson } from '@/interfaces'
import { IClientsStoreState } from '.'
import { Namespaced } from '@/store'
import Vue from 'vue'

export type ClientMutations<S = IClientsStoreState> = {
	'mutateLoadedAt'(state: S): void
	'mutateClients'(state: S, payloads: IBaseClient[]): void
	'addClient'(state: S, payload: IClient): void
	'mutateClient'(state: S, payloads: Partial<IClient>): void
	'removeClient'(state: S, payload: number): void
	'addContactPerson'(state: S, payload: { clientId: number; contactPerson: IContactPerson }): void
	'mutateContactPerson'(
		state: S,
		payload: { clientId: number; contactPersonId: number; values: Partial<IContactPerson> },
	): void
	'removeContactPerson'(state: S, payload: { clientId: number; contactPersonId: number }): void
	'mutateSearchString'(state: S, payload: string): void
	'mutateClientConditions'(state: S, payload: { clientId: number; conditions: IConditions[] }): void
}

export type NamespacedClientMutations = Namespaced<ClientMutations, 'clients'>

const mutations: MutationTree<IClientsStoreState> & ClientMutations = {
	mutateLoadedAt(state) {
		state.loadedAt = new Date()
	},
	mutateClients(state, clients: IBaseClient[]) {
		state.clients = clients
	},
	mutateClient(state, client) {
		const idx = state.clients.findIndex((c) => c.id === client.id)
		if (idx > -1) {
			state.clients.splice(idx, 1, { ...state.clients[idx], ...client })
		}
	},
	addClient(state, client) {
		state.clients = [...state.clients, client]
	},
	removeClient(state, clientId) {
		const idx = state.clients.findIndex((cl) => cl.id === clientId)
		if (idx > -1) state.clients.splice(idx, 1)
	},
	addContactPerson(state, { clientId, contactPerson }) {
		const clientIdx = state.clients.findIndex((cl) => cl.id === clientId)
		if (clientIdx > -1) {
			state.clients.splice(clientIdx, 1, {
				...state.clients[clientIdx],
				contactPersons: [...state.clients[clientIdx].contactPersons, contactPerson],
			})
		}
	},
	mutateContactPerson(state, { clientId, contactPersonId, values }) {
		const clientIdx = state.clients.findIndex((cl) => cl.id === clientId)
		if (clientIdx > -1) {
			const contactPersonIdx = state.clients[clientIdx].contactPersons.findIndex((cp) => cp.id === contactPersonId)
			if (contactPersonIdx > -1) {
				state.clients[clientIdx].contactPersons.splice(contactPersonIdx, 1, {
					...state.clients[clientIdx].contactPersons[contactPersonIdx],
					...values,
				})
			}
		}
	},
	removeContactPerson(state, { clientId, contactPersonId }) {
		const clientIdx = state.clients.findIndex((cl) => cl.id === clientId)
		if (clientIdx > -1) {
			const contactPersonIdx = state.clients[clientIdx].contactPersons.findIndex((cp) => cp.id === contactPersonId)
			if (contactPersonIdx > -1) {
				const contactPersons = state.clients[clientIdx].contactPersons
				contactPersons.splice(contactPersonIdx, 1)
				state.clients.splice(clientIdx, 1, { ...state.clients[clientIdx], contactPersons })
			}
		}
	},
	mutateSearchString(state, searchString) {
		state.searchString = searchString
	},
	mutateClientConditions(state, payload) {
		Vue.set(state.conditions, payload.clientId, payload.conditions)
	},
}

export default mutations
