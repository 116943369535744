import { BaseStoreCommand } from '../BaseStoreCommand'
import { IProposal } from '../../interfaces'
import router from '../../router'
import { OffriHTTP } from '@/axiosRequestFunctions'
import { ProposalsActionContext } from '../../store/modules/proposals/actions'

export interface ICreateProposalCommandPayload {
	title?: string
	client?: number
	contactPerson?: number
	proposalId?: string
}

export class CreateProposalCommand extends BaseStoreCommand<ProposalsActionContext> {
	public undoable = false
	private proposalId?: string

	constructor(
		protected readonly store: ProposalsActionContext,
		private readonly payload: ICreateProposalCommandPayload,
		private readonly navigateWhenCreated: boolean = true,
	) {
		super(store)
	}

	async execute() {
		if (this.store.rootState.workspace) {
			await OffriHTTP.post<IProposal>(`workspaces/${this.store.rootState.workspace.id}/proposals`, this.payload, {
				timeout: undefined,
			}).then((res) => {
				this.proposalId = res.data.id
				if (this.navigateWhenCreated) router.push({ name: 'proposal', params: { id: res.data.id } })
			})
		}
	}

	async undo() {}
}
