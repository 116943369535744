import { AugmentedActionContext } from '@/store'
import { BaseCommand } from '@/commands/BaseCommand'

export abstract class BaseStoreCommand<
	ActionContext extends AugmentedActionContext<any, any, any, any>,
> extends BaseCommand {
	constructor(protected readonly store: ActionContext) {
		super()
	}
}
