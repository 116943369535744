import { GetterTree } from 'vuex'
import { IState, Namespaced } from '@/store'
import { IProposalStoreState } from '@/store/modules/proposal'
import { EProposalStatus, IBlockView, IProposal, IProposalVisit } from '@/interfaces'

export type ProposalGettersFunctions<S = IProposalStoreState> = {
	visits: (state: S) => IProposalVisit[]
	views: (state: S) => IBlockView[]
	proposal: (state: S) => IProposal | undefined
	viewMode: (state: S) => boolean
	showViewingTime: (state: S) => boolean
	indexOfPages: (state: S) => { [key: string]: number }
}

export type ProposalGetters = { [K in keyof ProposalGettersFunctions]: ReturnType<ProposalGettersFunctions[K]> }

export type NamespacedProposalGetter = Namespaced<ProposalGetters, 'proposal'>

const getters: GetterTree<IProposalStoreState, IState> & ProposalGettersFunctions = {
	visits: (state) => state.visits,
	views: (state) => state.views,
	proposal: (state) => state.proposal,
	viewMode: (state) => state.viewMode,
	showViewingTime: (state) =>
		!!state.proposal &&
		!state.isClient &&
		!state.proposal.template &&
		state.proposal.status !== EProposalStatus.CONCEPT &&
		!(state.viewMode && ![EProposalStatus.WON, EProposalStatus.LOST].includes(state.proposal.status)),
	indexOfPages: (state) =>
		state.proposal && state.proposal.pages
			? state.proposal.pages.reduce((acc, curr, idx) => ({ ...acc, [curr.id]: idx }), {})
			: {},
}

export default getters
