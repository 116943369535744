import { BaseStoreCommand } from '../BaseStoreCommand'
import { IClient, IContactPerson, IProposal, IProposalForm } from '@/interfaces'
import { Exact } from '../../interfaces/exact'
import { ProposalActionContext } from '@/store/modules/proposal/actions'
import { ClientActionContext } from '../../store/modules/clients/actions'
import { OffriHTTP } from '../../axiosRequestFunctions'

export class UpdateContactPersonCommand<
	NV extends Partial<IContactPerson>,
	OV extends Exact<IContactPerson, NV>,
> extends BaseStoreCommand<ClientActionContext> {
	constructor(
		store: ClientActionContext,
		private readonly clientId: number,
		private readonly contactPersonId: number,
		private readonly newValue: NV,
		private readonly oldValue: OV,
	) {
		super(store)
	}

	async execute() {
		if (this.store.rootState.workspace) {
			this.store.dispatch('setSavingState', 'saving', { root: true })
			await OffriHTTP.put(
				`/workspaces/${this.store.rootState.workspace.id}/clients/${this.clientId}/contact-persons/${this.contactPersonId}`,
				this.newValue,
			)
				.then(() => {
					this.store.commit('mutateContactPerson', {
						clientId: this.clientId,
						contactPersonId: this.contactPersonId,
						values: this.newValue,
					})
					this.store.dispatch('setSavingState', new Date(), { root: true })
				})
				.catch(console.error)
		}
	}

	async undo() {
		if (this.store.rootState.workspace) {
			await OffriHTTP.put(
				`/workspaces/${this.store.rootState.workspace.id}/clients/${this.clientId}/contact-persons/${this.contactPersonId}`,
				this.oldValue,
			)
				.then(() =>
					this.store.commit('mutateContactPerson', {
						clientId: this.clientId,
						contactPersonId: this.contactPersonId,
						values: this.oldValue,
					}),
				)
				.catch(console.error)
		}
	}
}
