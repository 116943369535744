import { ComponentActionContext } from '@/store/modules/component/actions'
import { AllBlockTypes, EBlockDirection, EBlockType, IBlock, IComponent, IProposal, IProposalPage } from '@/interfaces'
import { OffriHTTP } from '@/axiosRequestFunctions'
import { cloneDeep, isEmpty } from 'lodash'
import { Vue } from 'vue-property-decorator'
import ComponentBlock from '@/components/editor/ComponentBlock.vue'

interface necessaryStoreComponents {
	dispatch(command: 'setSavingState', content: boolean | number | 'saving', options: { root: true }): Promise<any>
	dispatch(
		command: 'proposal/changePageProperties',
		content: { proposalPageId: number; newProperties: AllBlockTypes[]; oldProperties: AllBlockTypes[] },
		options: { root: true },
	): Promise<any>
}

export abstract class BlockContainerManager {
	abstract reloadData(): void

	abstract updateStore(): Promise<void>

	abstract get blocks(): IBlock[]

	abstract get workspaceId(): number

	abstract get store(): necessaryStoreComponents

	abstract get baseUrl(): string

	abstract reloadBlockContainer(): Promise<void>

	abstract setOrders(): void

	abstract get orderProperty(): keyof IBlock

	handleError(e: Error): void {
		console.error(e)
	}

	async createBlock(
		blocks: Array<{ [p: string]: any; type?: EBlockType; order?: number }>,
		order: number = this.blocks.length + 1,
	) {
		let newBlocks: AllBlockTypes[] = []
		if (order > this.blocks.length + 1) {
			order = this.blocks.length + 1
		}

		for (const block of blocks) {
			const index = order + newBlocks.length
			const body = {
				...block,
				order: index,
			}
			await OffriHTTP.post<AllBlockTypes | AllBlockTypes[]>(`${this.baseUrl}/`, body)
				.then((res) => {
					if (Array.isArray(res.data)) {
						newBlocks.push(...res.data)
						this.blocks.splice(index, 0, ...res.data)
					} else {
						newBlocks.push(res.data)
						this.blocks.splice(index, 0, res.data)
					}
				})
				.catch((err) => {
					console.error(err.response.data)
				})
			this.setOrders()
		}
		await this.updateStore()
		return newBlocks
	}

	async deleteBlock(order: number): Promise<IBlock | undefined> {
		await this.store.dispatch('setSavingState', 'saving', { root: true })
		try {
			const res = await OffriHTTP.delete(`${this.baseUrl}/${order}`)
			const index = this.blocks.findIndex((value) => {
				return value[this.orderProperty] === order
			})
			const blockDataBackUp = this.blocks[index]
			if (index > -1) this.blocks.splice(index, 1)
			this.setOrders()
			await this.store.dispatch('setSavingState', Date.now(), { root: true })
			await this.updateStore()
			return blockDataBackUp
		} catch (e) {
			console.error(e)
		}
		return undefined
	}

	async updateOrder(
		payload: { current: number; new: number },
		refers: { [key: string]: Vue | Vue[] | Element | HTMLElement },
	) {
		await this.store.dispatch('setSavingState', 'saving', { root: true })
		await OffriHTTP.put(`${this.baseUrl}/${payload.current}`, { order: payload.new }).then(() => {
			const to = payload.new < payload.current ? payload.new : payload.new - 1
			const fr = payload.current

			let top: number
			if (to > fr) {
				const refs = refers[`content${fr + 1}`] as Vue[]
				const ref = refs[0].$el as HTMLElement

				top = window.pageYOffset + ref.offsetHeight
			} else {
				const refs = refers[`content${fr - 1}`] as Vue[]
				const ref = refs[0].$el as HTMLElement

				top = window.pageYOffset - ref.offsetHeight
			}
			window.scrollTo({
				top,
				left: 0,
				behavior: 'smooth',
			})
			this.blocks.splice(to, 0, this.blocks.splice(fr, 1)[0])
			this.setOrders()
			this.store.dispatch('setSavingState', Date.now(), { root: true })
			this.updateStore()
		})
	}

	async changeBlockPage(
		payload: { newBlockPage: IProposalPage; block: AllBlockTypes; oldBlockOrder: number; direction: EBlockDirection },
		refers: { [key: string]: Vue | Vue[] | Element | HTMLElement },
	) {
		await this.store.dispatch('setSavingState', 'saving', { root: true })
		let newPageOrder = payload.direction == 'up' ? payload.newBlockPage.blocks.length : 0
		await OffriHTTP.put(`workspaces/${this.workspaceId}/blocks/${payload.block.id}/change-page`, {
			blockId: payload.block.id,
			pageId: payload.newBlockPage.id,
			oldPageId: payload.block.pageId,
			pageOrder: newPageOrder,
			direction: payload.direction,
		})

		// Handle scroll
		const refs = refers[`content${payload.oldBlockOrder}`] as Vue[]
		const ref = refs[0].$el as HTMLElement

		// 130 is the amount of pixels between pages in a proposal
		let top =
			payload.direction == EBlockDirection.UP
				? window.pageYOffset - (ref.offsetHeight + 130)
				: window.pageYOffset + (ref.offsetHeight + 130)
		window.scrollTo({
			top,
			left: 0,
			behavior: 'smooth',
		})

		await this.store.dispatch('setSavingState', Date.now(), { root: true })
	}

	async decoupleComponentBlock(order: number) {
		await this.store.dispatch('setSavingState', 'saving', { root: true })
		let componentBlock = this.blocks[order] as ComponentBlock & IBlock
		await OffriHTTP.patch(`${this.baseUrl}/${order}`).then(async () => {
			await this.reloadBlockContainer()
			await this.store.dispatch('setSavingState', Date.now(), { root: true })
		})
		return componentBlock
	}

	async duplicate(order: number) {
		let createdBlock: IBlock | undefined = undefined
		const index = this.blocks.findIndex((value) => {
			return value[this.orderProperty] === order
		})

		if (index > -1) {
			await OffriHTTP.post(`${this.baseUrl}/${order}/duplicate`).then(async (res) => {
				this.blocks.splice(index + 1, 0, res.data)
				createdBlock = res.data
				this.setOrders()
				await this.updateStore()
				return createdBlock?.[this.orderProperty]
			})
		}
		return undefined
	}
}
