import { BaseStoreCommand } from '../BaseStoreCommand'
import { IProposal, IProposalForm } from '@/interfaces'
import { Exact } from '../../interfaces/exact'
import { ProposalActionContext } from '@/store/modules/proposal/actions'
import { OffriHTTP } from '@/axiosRequestFunctions'
import { AxiosResponse } from 'axios'

export class UpdateProposalCommand<
	NV extends Partial<IProposalForm>,
	OV extends Exact<IProposalForm, NV>,
> extends BaseStoreCommand<ProposalActionContext> {
	constructor(
		store: ProposalActionContext,
		private readonly newValue: NV,
		private readonly oldValue: OV,
		private onlyLocal: boolean = false,
	) {
		super(store)
	}

	async execute() {
		let result: AxiosResponse | undefined
		try {
			if (!this.onlyLocal) {
				result = await OffriHTTP.put(
					`/workspaces/${this.store.state.proposal?.workspaceId}/${
						this.store.state.proposal?.template ? 'templates' : 'proposals'
					}/${this.store.state.proposal?.id}`,
					this.newValue,
				)
			}
			this.store.commit('mutateProposal', { ...(this.store.state.proposal as IProposal), ...this.newValue })
			return result
		} catch (e) {
			console.error(e)
		}
	}

	async undo() {
		try {
			if (!this.onlyLocal) {
				await OffriHTTP.put(
					`/workspaces/${this.store.state.proposal?.workspaceId}/${
						this.store.state.proposal?.template ? 'templates' : 'proposals'
					}/${this.store.state.proposal?.id}`,
					this.oldValue,
				)
			}
			this.store.commit('mutateProposal', { ...(this.store.state.proposal as IProposal), ...this.oldValue })
		} catch (e) {
			console.error(e)
		}
	}
}
