import { BaseStoreCommand } from '../BaseStoreCommand'
import { ClientActionContext } from '../../store/modules/clients/actions'
import { OffriHTTP } from '@/axiosRequestFunctions'

export class DeleteContactPersonCommand extends BaseStoreCommand<ClientActionContext> {
	constructor(
		protected readonly store: ClientActionContext,
		private readonly clientId: number,
		private readonly contactPersonId: number,
	) {
		super(store)
	}

	async execute() {
		if (this.store.rootState.workspace) {
			this.store.dispatch('setSavingState', 'saving', { root: true })
			await OffriHTTP.delete(
				`workspaces/${this.store.rootState.workspace.id}/clients/${this.clientId}/contact-persons/${this.contactPersonId}`,
			).then(() => {
				this.store.commit('removeContactPerson', { clientId: this.clientId, contactPersonId: this.contactPersonId })
				this.store.dispatch('setSavingState', new Date(), { root: true })
			})
		}
	}

	async undo() {}
}
