import { BaseStoreCommand } from '../BaseStoreCommand'
import { ProposalActionContext } from '@/store/modules/proposal/actions'
import { OffriHTTP } from '@/axiosRequestFunctions'
import { ProposalsActionContext } from '../../store/modules/proposals/actions'

export class DeleteProposalCommand extends BaseStoreCommand<ProposalsActionContext> {
	constructor(
		protected readonly store: ProposalsActionContext,
		private readonly payload: string,
	) {
		super(store)
	}

	async execute() {
		if (this.store.rootState.workspace) {
			this.store.dispatch('setSavingState', 'saving', { root: true })
			await OffriHTTP.delete(`/workspaces/${this.store.rootGetters.workspace?.id}/proposals/${this.payload}`).then(
				() => {
					this.store.commit('removeProposal', this.payload)
					this.store.dispatch('setSavingState', new Date(), { root: true })
				},
			)
		}
	}

	async undo() {}
}
