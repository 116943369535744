import { DirectiveFunction } from 'vue'
import Axios from 'axios'
import { ThumbnailSizes, thumbnailSizes } from '@/interfaces'
import { memoize } from 'lodash'

const _getWebpUrl = (url: string, size?: ThumbnailSizes): string => {
	const match = /^(https:\/\/.+?blob.core.windows.net)\/(images|avatars)(?:\/(.+))?\/(.+)\/(.+)\.(.+)$/.exec(url)
	if (!match) {
		return url
	}
	const [, baseUrl, container, ownerId, uuid, fileName, fileExtension] = match
	if (fileExtension !== 'svg') {
		return `${baseUrl}/webp/${container}/${ownerId ? ownerId + '/' : ''}${uuid}/${
			size ? size + '/' : ''
		}${fileName}.webp`
	} else {
		return url
	}
}
export const getWebpUrl = memoize(_getWebpUrl, (url, size) => url + size)

const _imageExists = async (url: string): Promise<boolean> => {
	try {
		await Axios.head(getWebpUrl(url))
		return true
	} catch {
		return false
	}
}
export const imageExists = memoize(_imageExists)

export interface WebpDirectiveValue {
	url: string
	size?: ThumbnailSizes
}

/** Set src or '--imageUrl' css variable of element to the (optionally lower res) webp variant if it exists */
export const webpDirective: DirectiveFunction = async (htmlElement, binding) => {
	const { url, size } = binding.value as WebpDirectiveValue

	if (!url || (url === binding.oldValue?.url && size === binding.oldValue?.size)) {
		// No url or no changes, no action needed
		return
	}

	if (size !== undefined && !thumbnailSizes.includes(size)) {
		throw new Error(`Invalid size ${size} given to webp directive, use one of these: ${JSON.stringify(thumbnailSizes)}`)
	}

	const webpUrl = getWebpUrl(url, size)

	// Use webp source, if it doesn't seem to exist change back to normal source
	if (htmlElement.tagName?.toLowerCase() === 'img') {
		// Set src on image
		const imgElement = htmlElement as HTMLImageElement
		imgElement.src = webpUrl
		if (!(await imageExists(webpUrl))) {
			imgElement.src = url
		}
	} else {
		// Set backgroundImage on anything else
		htmlElement.style.setProperty('--imageUrl', `url(${webpUrl})`)
		if (!(await imageExists(webpUrl))) {
			htmlElement.style.setProperty('--imageUrl', `url(${url})`)
		}
	}
}
