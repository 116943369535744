import { BaseStoreCommand } from '../BaseStoreCommand'
import { ProposalActionContext } from '@/store/modules/proposal/actions'
import { IProposalPage } from '@/interfaces'
import { PageManager } from '@/commands/PageManager/PageManager'
import { Exact } from '@/interfaces/exact'

export class ChangePagePropertiesCommand<
	NV extends Partial<Omit<IProposalPage, 'blocks'>>,
	OV extends Exact<IProposalPage, NV>,
> extends BaseStoreCommand<ProposalActionContext> {
	constructor(
		context: ProposalActionContext,
		private proposalPageId: number,
		private newProperties: NV,
		private oldProperties: OV,
	) {
		super(context)
	}

	async execute(): Promise<void> {
		await new PageManager(this.store, this.proposalPageId).updatePage(this.newProperties)
	}

	async undo(): Promise<void> {
		if (this.oldProperties) {
			await new PageManager(this.store, this.proposalPageId).updatePage(this.oldProperties)
		}
	}
}
