import { BaseStoreCommand } from '../BaseStoreCommand'
import { IClient } from '@/interfaces'
import { OffriHTTP } from '../../axiosRequestFunctions'
import { Exact } from '../../interfaces/exact'
import { ClientActionContext } from '../../store/modules/clients/actions'

export class UpdateClientCommand<
	NV extends Partial<IClient>,
	OV extends Exact<IClient, NV>,
> extends BaseStoreCommand<ClientActionContext> {
	constructor(
		store: ClientActionContext,
		private readonly id: number,
		private readonly newValue: NV,
		private readonly oldValue: OV,
	) {
		super(store)
	}

	async execute() {
		if (this.store.rootState.workspace) {
			this.store.dispatch('setSavingState', 'saving', { root: true })
			await OffriHTTP.put(`/workspaces/${this.store.rootState.workspace.id}/clients/${this.id}`, this.newValue)
				.then((res) => {
					if (this.newValue?.tags && res.data?.tags) {
						this.newValue.tags = res.data.tags
					}
					this.store.commit('mutateClient', { id: this.id, ...this.newValue })
					this.store.dispatch('setSavingState', new Date(), { root: true })
				})
				.catch(console.error)
		}
	}

	async undo() {
		if (this.store.rootState.workspace) {
			await OffriHTTP.put(`/workspaces/${this.store.rootState.workspace.id}/clients/${this.id}`, this.oldValue).catch(
				console.error,
			)
			this.store.commit('mutateClient', { id: this.id, ...this.oldValue })
		}
	}
}
