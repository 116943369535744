import mutations from './mutations'
import getters from './getters'
import actions from './actions'
import { ImportLayouts, IImportProduct, IcsvErrorsObject, IImportOptions, importCompleted } from '@/interfaces'

export interface IImportStoreState {
	parsedCsvName?: string
	csvAlreadyValidated: boolean
	parsedCsv: IImportProduct[]
	options: IImportOptions
	errors?: IcsvErrorsObject
	completed?: importCompleted
}

export const initialStoreState: IImportStoreState = {
	parsedCsvName: undefined,
	csvAlreadyValidated: false,
	parsedCsv: [],
	errors: undefined,
	completed: undefined,
	options: {
		update: true,
		createCategories: true,
		overwriteContent: false,
		layout: ImportLayouts.QUARTER_SCREEN,
	},
}

const state: IImportStoreState = { ...initialStoreState }

export const importState = state
export default {
	namespaced: true,
	state,
	actions,
	getters,
	mutations,
}
