import axios from 'axios'

import jwtDecode from 'jwt-decode'
import { IJwtToken } from './interfaces'
import router from './router'

export const OffriHTTP = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	timeout: 10000,
	headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
})

export const BunnyFontHTTP = axios.create({
	baseURL: process.env.VUE_APP_BUNNY_FONT_API_URL,
	timeout: 10000,
})

OffriHTTP.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		if (error.response) {
			if (error.response.status === 401) {
				const token = localStorage.getItem('token')
				if (token) {
					const decodedToken: IJwtToken = jwtDecode(token)
					const currentTime = Date.now() / 1000

					if (decodedToken.exp < currentTime) {
						router.push({ name: 'login.nl', query: { sessionExpired: 'true' } })
					}
				}
			}
		}
		return Promise.reject(error)
	},
)

export const OffriFileStorage = async <response = any>(
	file: File,
	requestUrl: string,
	options?: { progress?: (progress: number) => void },
) => {
	const response = await OffriHTTP.post(requestUrl, {
		mimetype: file.type,
		fileName: file.name,
	})
	let headers = response.data.headers

	const azureResponse = await axios.put(response.data.url, file, {
		headers: headers,
		onUploadProgress: (progressEvent) => {
			if (options?.progress != null) {
				options.progress(progressEvent.loaded / progressEvent.total)
			}
		},
	})
	response.data.extension = file.name.split('.').pop()
	response.data.url = response.data.url.split('?')[0]
	return response.data as { extension: string; url: string }
}

export function setAuthHeader() {
	//@ts-ignore
	OffriHTTP.defaults.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`
}
