import { BaseStoreCommand } from '../BaseStoreCommand'
import { ProposalActionContext } from '@/store/modules/proposal/actions'
import { OffriHTTP } from '@/axiosRequestFunctions'
import { AllBlockTypes, EBlockType, IBlock, IComponentBlock } from '@/interfaces'
import { Exact } from '@/interfaces/exact'

export class ChangePageBlockPropertiesCommand<
	NV extends Partial<AllBlockTypes>,
	OV extends Exact<AllBlockTypes, NV>,
> extends BaseStoreCommand<ProposalActionContext> {
	private pageId: number
	private blockId: number
	private pageOrder: number
	private oldProperties: Partial<AllBlockTypes>
	private componentBlock?: IComponentBlock
	private componentBlockIndex?: number

	constructor(
		store: ProposalActionContext,
		block: IBlock,
		private newProperties: NV,
		public undoable = true,
		oldProperties?: OV,
		componentBlock?: IBlock,
	) {
		super(store)

		if (componentBlock && componentBlock.pageId != null && componentBlock.pageOrder != null) {
			const storeBlock =
				this.store.getters.proposal?.pages[this.store.getters.indexOfPages[componentBlock.pageId]].blocks[
					componentBlock.pageOrder
				]

			if (storeBlock?.type == EBlockType.COMPONENT) {
				this.componentBlockIndex = storeBlock?.blocks.findIndex((value) => value.id === block.id)
				this.componentBlock = storeBlock
			}
		}

		const newPageId = block.pageId == null ? this.componentBlock?.pageId : block.pageId
		const newPageOrder = block.pageOrder == null ? this.componentBlock?.pageOrder : block.pageOrder

		if (newPageId == null) {
			throw new Error('PageId is null or undefined')
		}
		if (newPageOrder == null) {
			throw new Error('PageId is null or undefined')
		}

		this.pageId = newPageId
		this.blockId = block.id
		this.pageOrder = newPageOrder

		const pageIndex = this.store.getters.indexOfPages[this.pageId]
		if (pageIndex !== undefined) {
			if (oldProperties !== undefined && undoable) {
				this.oldProperties = oldProperties as Partial<AllBlockTypes>
			} else {
				let oldBlockData = this.store.getters.proposal?.pages[pageIndex].blocks[this.pageOrder]

				if (this.componentBlock != null && oldBlockData?.type === EBlockType.COMPONENT) {
					oldBlockData = oldBlockData.blocks[this.componentBlock.blocks.findIndex((value) => value.id === block.id)]
				}

				this.oldProperties = (Object.keys(this.newProperties) as (keyof NV)[]).reduce<NV>(
					(previousValue, currentValue) => {
						return (oldBlockData as NV)[currentValue] != null
							? { ...previousValue, [currentValue]: (oldBlockData as NV)[currentValue] }
							: previousValue
					},
					{} as NV,
				)
			}
		} else {
			console.error(`page with pageId '${this.pageId}' doesn't  exist`)
			this.oldProperties = {}
			this.undoable = false
		}
	}

	async execute(): Promise<any> {
		await this.changeProperties(this.newProperties, this.oldProperties)
	}

	async undo(): Promise<any> {
		await this.changeProperties(this.oldProperties, this.newProperties)
	}

	changeStore(pageIndex: number, properties: Partial<AllBlockTypes>) {
		if (pageIndex !== undefined) {
			const updateData = { pageIndex, blockPageOrder: this.pageOrder, properties: properties }

			if (this.componentBlock != null && this.componentBlockIndex != null) {
				this.store.commit('mutateComponentBlockBlockProperties', {
					blockIndex: this.componentBlockIndex,
					...updateData,
				})
			} else {
				this.store.commit('mutateBlockProperties', updateData)
			}
		} else {
			console.error(`page with pageId '${this.pageId}' doesn't  exist`)
		}
	}

	async changeProperties(newProperties: Partial<AllBlockTypes>, currentProperties: Partial<AllBlockTypes>) {
		await this.store.dispatch('setSavingState', 'saving', { root: true })
		const pageIndex = this.store.getters.indexOfPages[this.pageId]
		this.changeStore(pageIndex, newProperties)
		OffriHTTP.put(`workspaces/${this.store.getters.proposal?.workspaceId}/blocks/${this.blockId}`, newProperties)
			.then(() => {
				this.store.dispatch('setSavingState', Date.now(), { root: true })
			})
			.catch(() => {
				this.changeStore(pageIndex, currentProperties)
			})
	}
}
